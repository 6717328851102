import React, {useState} from 'react'
import { AiOutlineLeft } from "react-icons/ai";
import styled from 'styled-components';
import { useHistory } from 'react-router';
import DCCLogo from "../../../assets/DCC-Logo.svg"
import { useLocation } from 'react-router';
import { numberWithCommas } from '../../../utilities/utilityFunctions';
import "./evotingStyle.scss";
import { Logout } from "./logout";

export const Electionresult = () => {
    const location = useLocation()
    const { data, result } = location.state;
    const getData = result
    const getEvotingData = data
  return (
    <div  className="evotingfontfamily" style={{ padding: '20px'}}>
    <MainWrapper className='row mt-4'>
      <div className="col-sm-12 col-md-12 col-lg-12 responsivepage" >
        <div className='card pb-5' style={{ border: '1px solid #70ace7' }}>
          <Wrapper className="card-header " style={{ borderBottom: 'none' }}>
          <div className='d-flex' style={{justifyContent:'space-between'}}>
          <AiOutlineLeft className='mt-3' size={30} style={{ color: '#677ecc', cursor: 'pointer' }} onClick={() => {
                 window.location.replace(
                    `${process.env.PUBLIC_URL}/evoting/home`
                );
            //   
            }}/>
              <Logout />
            </div>
            
            <div className="glyphicon glyphicon-chevron-left"></div>
            <div className="text-center mt-1">
              <img src={DCCLogo} alt="" width="220" height={'130px'} />
            </div>
            <div className='mt-3' style={{ color: '#447bc9', fontSize: '23px', display: 'flex', justifyContent: 'center' }}>
              {getData?.title || ''}
            </div>
            <p style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', color: '#98a1b5' }} class="card-text">{getData?.startdate} to {getData?.endDate}</p>

            <div className='mt-3' style={{ justifyContent: 'center', display: 'flex', fontSize: '20px' }}>
            Election Voting Results
            </div>
            <div className='mt-3' style={{ justifyContent: 'center', display: 'flex', fontSize: '16px', color: '#98a1b5' }}>
            Following candidates are selected
            </div>
            <div  style={{ justifyContent: 'center', display: 'flex', fontSize: '16px', color: '#98a1b5'}}>
            as Governing Directors on Board
            </div>
          </Wrapper>
          <div className="card-body mt-4" style={{ justifyContent: 'center', display: 'flex' }}>
            <div>
              {
                getEvotingData?.map((item, key) => {
                  const startDate = new Date(item?.startdate);
                  const endDate = new Date(item?.endDate);
                  return <> <div key={key} class="row no-gutters" >
                    <div class="col-md-12 mt-5">
                      <div style={{ display: 'flex', justifyContent: 'center', fontSize: '18px', color: '#5B9BD5', }}> Candidate {key + 1} <span style={{ paddingLeft: '5px' }}> {item?.Name?.toUpperCase()}</span></div>
                      <div style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>   {numberWithCommas(item?.value) || 0} <span style={{ fontSize: '14px', paddingLeft: '8px' }}>votes</span></div>

                    </div>
                  </div>
                  </>
                })

              }

              {/* <label className="col-form-label pt-0 justify-content-center d-flex mt-5" style={{ fontSize: '16px' }}>
                Enter Authentication OTP
              </label>
              <input style={{ minWidth: '260px', maxWidth: '260px' }} type='text' className="form-control mt-1"
                onChange={(event) => console.log('event')} />


              <button
                className="btn mt-3"
                type="button"
                style={{ width: '100%', color: '#ffff', background: '#5B9BD5' }}
                onClick={() => {
                //   handlePreViewVoting()
                }}
              >


                <span> CONFIRM</span>

              </button> */}
            </div>









          </div>

        </div>
      </div>
    </MainWrapper>



  </div>
    

  )
}

const MainWrapper = styled.div`
@media(min-width: 300px){
    .responsivepage{

    }
  }
  @media(min-width: 500px){
    .responsivepage{
display: flex;
justify-content: center
}
  }
`;

const Wrapper = styled.div`
@media(min-width: 300px){
    .responsive{
    width: 80%;
    }
  }
  @media(min-width: 500px){
    .responsive{
    width: 53%;
    }
  }
  @media(min-width: 660px){
    .responsive{
    width: 45%;
    }
  }
  @media(min-width: 840px){
    .responsive{
    width : 35%;
    }
  }
  @media(min-width: 1132px){
    .responsive{
    width : 26%;
    }
  }
  
  @media(min-width: 1132px){
    .responsive{
    width : 22%;
    }
  }


@media(min-width: 1300px){
    .responsive{
    width : 20%;
    }
  }
  @media(min-width: 1470px){
    .responsive{
    width : 15%;
    }
  }
`;