import axios from "axios";
import Config from "../../config";

const getShareholders = async (email, pram = "") => {
  const investor_key = sessionStorage.getItem("cnic");
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/shareholders/by-investor-id?email=${email}&investor_key=${investor_key}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
const shareHoldingBulkUpload = async (email, company_code, data) => {
  const url = `${Config.baseUrl}/shareholders/upload-physical`;

  const result = await axios.post(
    url,
    {
      email,
      company_code,
      data,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};
const getShareHoldersByCompany = async (email, company_code) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/shareholders/by-company?email=${email}&company_code=${company_code}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const getShareHolderHistoryByCompanyandDate = async (
  email,
  company_code,
  date
) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/shareholdinghistory/get-by-companycode-date?email=${email}&company_code=${company_code}&date=${date}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const getShareHoldersByShareholderID = async (email, investor_key) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/shareholders/by-investor-id?email=${email}&investor_key=${investor_key}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const getShareHolderByFolioNo = async (email, folio_no) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/shareholders/by-folioNo?email=${email}&folio_number=${folio_no}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const getShares = async (email) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/shares?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const addShare = async (
  email,
  symbol,
  type,
  offer_volume,
  offer_price,
  final_offer_price,
  bb_percent,
  bb_from,
  bb_to,
  strike_price,
  bid_volume,
  ipo_percent,
  ipo_from,
  ipo_to,
  subscribed_volume
) => {
  const url = `${Config.baseUrl}/shares/`;

  const result = await axios.post(
    url,
    {
      email,
      symbol,
      type,
      offer_volume,
      offer_price,
      final_offer_price,
      bb_percent,
      bb_from,
      bb_to,
      strike_price,
      bid_volume,
      ipo_percent,
      ipo_from,
      ipo_to,
      subscribed_volume,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const updateShare = async (
  email,
  symbol,
  offer_id,
  type,
  offer_volume,
  offer_price,
  final_offer_price,
  bb_percent,
  bb_from,
  bb_to,
  strike_price,
  bid_volume,
  ipo_percent,
  ipo_from,
  ipo_to,
  subscribed_volume
) => {
  const url = `${Config.baseUrl}/shares/update`;
  const result = await axios.post(
    url,
    {
      email,
      symbol,
      offer_id,
      type,
      offer_volume,
      offer_price,
      final_offer_price,
      bb_percent,
      bb_from,
      bb_to,
      strike_price,
      bid_volume,
      ipo_percent,
      ipo_from,
      ipo_to,
      subscribed_volume,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const addShareholder = async (
  email,
  folio_number,
  company_code,
  symbol,
  shareholder_id,
  shareholder_name,
  shareholder_percent,
  joint_holders,
  electronic_shares,
  physical_shares,
  blocked_shares,
  freeze_shares,
  pledged_shares,
  pending_in,
  pending_out,
  available_shares,
  cdc_account_no,
  cdc_participant_id,
  cdc_account_type,
  total_holding,
  shareholder_mobile,
  shareholder_email,
  shareholder_phone,
  resident_status,
  street_address,
  city,
  country,
  passport_no,
  passport_expiry,
  passport_country,
  nominee_name,
  nominee_cnic,
  nominee_relation,
  account_title,
  account_no,
  bank_name,
  baranch_address,
  baranch_city,
  filer,
  zakat_status,
  picture,
  signature_specimen,
  cnic_copy,
  nominee_cnic_copy,
  zakat_declaration,
  poc_detail,
  nationality,
  roshan_account,
  right_shares
) => {
  const url = `${Config.baseUrl}/shareholders/`;

  const result = await axios.post(
    url,
    {
      email,
      folio_number,
      company_code,
      symbol,
      shareholder_id,
      shareholder_name,
      shareholder_percent,
      joint_holders,
      electronic_shares,
      physical_shares,
      blocked_shares,
      freeze_shares,
      pledged_shares,
      pending_in,
      pending_out,
      available_shares,
      cdc_account_no,
      cdc_participant_id,
      cdc_account_type,
      total_holding,
      shareholder_mobile,
      shareholder_email,
      shareholder_phone,
      resident_status,
      street_address,
      city,
      country,
      passport_no,
      passport_expiry,
      passport_country,
      nominee_name,
      nominee_cnic,
      nominee_relation,
      account_title,
      account_no,
      bank_name,
      baranch_address,
      baranch_city,
      filer,
      zakat_status,
      picture,
      signature_specimen,
      cnic_copy,
      nominee_cnic_copy,
      zakat_declaration,
      poc_detail,
      nationality,
      roshan_account,
      right_shares,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const updateShareholder = async (
  email,
  folio_number,
  company_code,
  symbol,
  shareholder_id,
  shareholder_name,
  shareholder_percent,
  joint_holders,
  electronic_shares,
  physical_shares,
  blocked_shares,
  freeze_shares,
  pledged_shares,
  pending_in,
  pending_out,
  available_shares,
  cdc_account_no,
  cdc_participant_id,
  cdc_account_type,
  total_holding,
  cdc_key,
  shareholder_mobile,
  shareholder_email,
  shareholder_phone,
  resident_status,
  street_address,
  city,
  country,
  passport_no,
  passport_expiry,
  passport_country,
  nominee_name,
  nominee_cnic,
  nominee_relation,
  account_title,
  account_no,
  bank_name,
  baranch_address,
  baranch_city,
  filer,
  zakat_status,
  picture,
  signature_specimen,
  cnic_copy,
  nominee_cnic_copy,
  zakat_declaration,
  poc_detail,
  nationality,
  roshan_account,
  right_shares
) => {
  const url = `${Config.baseUrl}/shareholders/update`;

  const result = await axios.post(
    url,
    {
      email,
      folio_number,
      company_code,
      symbol,
      shareholder_id,
      shareholder_name,
      shareholder_percent,
      joint_holders,
      electronic_shares,
      physical_shares,
      blocked_shares,
      freeze_shares,
      pledged_shares,
      pending_in,
      pending_out,
      available_shares,
      cdc_account_no,
      cdc_participant_id,
      cdc_account_type,
      total_holding,
      cdc_key,
      shareholder_mobile,
      shareholder_email,
      shareholder_phone,
      resident_status,
      street_address,
      city,
      country,
      passport_no,
      passport_expiry,
      passport_country,
      nominee_name,
      nominee_cnic,
      nominee_relation,
      account_title,
      account_no,
      bank_name,
      baranch_address,
      baranch_city,
      filer,
      zakat_status,
      picture,
      signature_specimen,
      cnic_copy,
      nominee_cnic_copy,
      zakat_declaration,
      poc_detail,
      nationality,
      roshan_account,
      right_shares,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const uploadCDCfile = async (
  email,
  company_symbol,
  company_code,
  header,
  data,
  footer
) => {
  const url = `${Config.baseUrl}/shareholders/upload-cdc`;

  const result = await axios.post(
    url,
    { email, company_symbol, company_code, header, data, footer },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};
export {
  shareHoldingBulkUpload,
  getShareholders,
  addShareholder,
  getShares,
  addShare,
  updateShare,
  updateShareholder,
  uploadCDCfile,
  getShareHoldersByCompany,
  getShareHolderByFolioNo,
  getShareHoldersByShareholderID,
  getShareHolderHistoryByCompanyandDate,
};
