import React, { Fragment, useEffect } from "react";
import Header from "./common/header-component/header";
import Sidebar from "./common/sidebar-component/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import ThemeCustomizer from "./common/theme-customizer";
import { ToastContainer } from "react-toastify";
import Loader from "./common/loader";
import { useDispatch, useSelector } from "react-redux";
import {
  WATCH_COMPANIES,
  WATCH_COMPANIES_DROPDOWN,
  WATCH_SHAREHOLDERS,
  WATCH_SHAREHOLDERS_DROPDOWN,
  WATCH_INVESTORS,
  WATCH_INVESTORS_DROPDOWN,
  WATCH_INVESTORS_REQUEST_TYPES,
  WATCH_INVESTORS_REQUEST,
  GET_FEATURES,
  WATCH_TRANSACTION_REQUEST,
  WATCH_TRANSACTION_REQUEST_TYPES,
  WATCH_SHARE_CERTIFICATES,
  WATCH_SHARE_CERTIFICATES_DROPDOWN,
  WATCH_ENTITLEMENTS_DROPDOWN,
  WATCH_ENTITLEMENTS,
  WATCH_DIVIDEND,
  WATCH_ANNOUNCEMENTS,
  WATCH_ANNOUNCEMENTS_DROPDOWN,
  WATCH_COMPANIES_SYMBOLS,
  WATCH_INACTIVE_SHAREHOLDERS,
  GET_SHAREHOLDER_INFO,
} from "../redux/actionTypes";

const AppLayout = (props) => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  useEffect(() => {
    if (token !== null) {
      dispatch({ type: WATCH_COMPANIES });
      dispatch({ type: WATCH_COMPANIES_DROPDOWN });
      dispatch({ type: WATCH_COMPANIES_SYMBOLS });
      dispatch({ type: WATCH_SHAREHOLDERS });
      dispatch({ type: WATCH_INACTIVE_SHAREHOLDERS });
      dispatch({ type: WATCH_SHAREHOLDERS_DROPDOWN });
      dispatch({ type: WATCH_INVESTORS });
      dispatch({ type: WATCH_INVESTORS_DROPDOWN });
      dispatch({ type: WATCH_INVESTORS_REQUEST_TYPES });
      dispatch({ type: WATCH_INVESTORS_REQUEST });
      dispatch({ type: WATCH_TRANSACTION_REQUEST });
      dispatch({ type: WATCH_TRANSACTION_REQUEST_TYPES });
      dispatch({ type: WATCH_SHARE_CERTIFICATES });
      dispatch({ type: WATCH_SHARE_CERTIFICATES_DROPDOWN });
      dispatch({ type: WATCH_ENTITLEMENTS_DROPDOWN });
      dispatch({ type: WATCH_ENTITLEMENTS });
      dispatch({ type: WATCH_ANNOUNCEMENTS });
      dispatch({ type: WATCH_ANNOUNCEMENTS_DROPDOWN });
      dispatch({ type: WATCH_DIVIDEND });
      dispatch({
        type: GET_FEATURES,
        payload: JSON.parse(sessionStorage.getItem("features")),
      });
      dispatch({
        type: GET_SHAREHOLDER_INFO,
        payload: {
          cnic: sessionStorage.getItem("cnic"),
          mobile: sessionStorage.getItem("mobile"),
        },
      });
    }
  }, [token]);
  useEffect(() => {
    localStorage.setItem("layout_version", "dark-only");
      // sessionStorage.setItem("layout_version", "dark-only");
  }, []);
  return (
    <Fragment>
      <Loader />
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header />
          <Sidebar />
          <RightSidebar />
          <div className="page-body">{props.children}</div>
          <Footer />
          <ThemeCustomizer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AppLayout;
