import React from "react";
import styled from "styled-components";
import DCCLogo from "../../../assets/DCC-Logo.svg";
import logo from "../../../assets/share-registrar.svg";
import "./evotingStyle.scss";
import { useHistory } from "react-router";

export const EvotingInstruction = () => {
  const history = useHistory();

  return (
    <div className="evotingfontfamily" style={{ padding: "20px" }}>
      <MainWrapper className="row mt-4 ">
        <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
          <div className="card pb-5 " style={{ border: "1px solid #70ace7" }}>
            <Wrapper className="card-header " style={{ borderBottom: "none" }}>
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <img
                    src={DCCLogo}
                    alt=""
                    height={"130px"}
                    style={{ maxWidth: "145px" }}
                  />
                </div>
                <div>
                  <img
                    src={logo}
                    alt=""
                    height={"130px"}
                    style={{ maxWidth: "145px" }}
                  />
                </div>
              </div>
            </Wrapper>
            <div> </div>
            <div className="responsivepaddingpage">
              <div
                className="mt-4"
                style={{
                  color: "#447bc9",
                  fontSize: "23px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Instructions For eVoting
              </div>
              <div className="mt-4">
                <ol style={{ display: "block", gap: "50px", fontSize: "15px" }}>
                  <li className="mt-2">
                    Open the e-voting portal using the below link/button and
                    enter your{" "}
                    <span style={{ color: "rgb(45, 108, 202)" }}>CNIC </span> to
                    log-in.
                  </li>
                  <li className="mt-2">
                    For verification, an{" "}
                    <span style={{ color: "rgb(45, 108, 202)" }}>
                      Authentication OTP{" "}
                    </span>{" "}
                    will be sent to your registered email address / mobile
                    number. Enter the same authentication OTP in the e-voting
                    portal and click{" "}
                    <span style={{ color: "rgb(45, 108, 202)" }}>Login.</span>
                  </li>
                  <li className="mt-2">
                    Recent and upcoming{" "}
                    <span style={{ color: "rgb(45, 108, 202)" }}>
                      Special Resolutions</span> and <span style={{ color: "rgb(45, 108, 202)" }}>Election of Directors</span>
                    
                    will be listed on the home page. You can view the details of
                    agenda items.
                  </li>
                  <li className="mt-2">
                    For the <span style={{ color: "rgb(45, 108, 202)" }}>Special Resolutions</span>, you can select{" "}
                    <span style={{ color: "rgb(45, 108, 202)" }}>
                      Cast Vote{" "}
                    </span>{" "}
                    and choose{" "}
                    <span style={{ color: "rgb(45, 108, 202)" }}>Favor </span>{" "}
                    or{" "}
                    <span style={{ color: "rgb(45, 108, 202)" }}>Against </span>{" "}
                    for each agenda item and Submit.
                  </li>
                  <li className="mt-2">
                    For the <span style={{ color: "rgb(45, 108, 202)" }}>Election of Directors</span>, you can enter your
                    entitled{" "}
                    <span style={{ color: "rgb(45, 108, 202)" }}>votes</span>{" "}
                    against the candidates and Submit.
                  </li>
                  <li className="mt-2">
                    Once casted, votes can not be changed.
                  </li>
                </ol>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="btn mt-5"
                  type="button"
                  style={{
                    color: "#ffff",
                    background: "#5b9bd5",
                    minWidth: "140px",
                  }}
                  onClick={e=>history.replace("/login")}
                >
                  Evoting Portal
                </button>
              </div>
            </div>
            <div></div>
          </div>
        </PaddingWrapper>
      </MainWrapper>
    </div>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepage {
    }
  }
  @media (min-width: 500px) {
    .responsivepage {
      display: flex;
      justify-content: center;
    }
  }
`;
const PaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepaddingpage {
      ${"" /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivepaddingpage {
      ${
        "" /* display: flex;
      justify-content: center; */
      }
      padding: 0px 30px;
      min-width: 550px;
      max-width: 550px;
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;
const Wrapper = styled.div`
  @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;
