import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { JsonToTable } from "react-json-to-table";
import { saveAs } from "file-saver";
import Select from "react-select";
import {
  fitToColumn,
  getvalidDateDMMMY,
  getvalidDateDMonthY,
  getvalidDateDMY,
  getvalidDateYMD,
  s2ab,
  validateFile,
} from "../../../utilities/utilityFunctions";
import xls from "xlsx";
import { refreshTokenHandler } from "../../../store/services/auth.service";
import moment from "moment";
import { getCompanies } from "../../../store/services/company.service";
import { EMPLOYEES_FILE } from "../../../constant";
import { indexOf } from "lodash-es";
import { shareHoldingBulkUpload } from "../../../store/services/shareholder.service";
import { darkStyle, errorStyles } from "../../defaultStyles";
const csv = require("csvtojson/v2");
var _ = require("lodash");

export default function ShareHoldingBulkUpload() {
  const email = sessionStorage.getItem("email") || "";
  // Selector STARTS
  const companies = useSelector((data) => data.Companies);
  // Selector ENDS  const [showIssues, setShowIssues] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [issuesInFile, setIssuesInFile] = useState([]);
  const [fileData, setfileData] = useState([]);
  const [modFileData, setModFileData] = useState([]);
  const [fileError, setFileError] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [chkData, setChkData] = useState([]);
  const [fileDataCount, setFileDataCount] = useState(0);
  const [checkFileInput, setCheckFileInput] = useState(false);
  const [Loading, setLoading] = useState(false);
  const columns = [
    [
      "Folio No",
      "Acc Title",
      "Father/Husband",
      "Address",
      "City",
      "DOB",
      "CNIC",
      "CNIC Expiry Date",
      "NTN",
      "PassPort No",
      "PassPort Date",
      "PassPort Country",
      "Zakat Status (Y/N)",
      "Investor Type",
      "Residency Status",
      "Nationality",
      "Occupation",
      "Bank Account No",
      "Account Title",
      "Bank Name",
      "Branch Address",
      "Joint1",
      "Joint1Percent",
      "Joint1CNIC",
      "Joint1CNIC Expiry Date",
      "Joint2",
      "Joint2Percent",
      "Joint2CNIC",
      "Joint2CNIC Expiry Date",
      "Joint3",
      "Joint3Percent",
      "Joint3CNIC",
      "Joint3CNIC Expiry Date",
      "ShareHolding",
      "Contact",
      "Mobile",
      "email",
      "Registration No",
      "Roshan Account (Y/N)",
    ],
  ];
  const handleDownloadSampleFile = () => {
    const work_book = xls.utils.book_new();
    work_book.Props = {
      Title: "Physical Shareholdings File",
      Subject: "Upload Physical Sharesholdings",
      Author: "Digital Custodian",
      CreatedDate: new Date(),
    };
    work_book.SheetNames.push("Shareholdings");

    const sheet_data = xls.utils.aoa_to_sheet(columns);
    sheet_data["!cols"] = fitToColumn(columns);
    work_book.Sheets["Shareholdings"] = sheet_data;
    const work_book_export = xls.write(work_book, {
      bookType: "xlsx",
      type: "binary",
    });
    saveAs(
      new Blob([s2ab(work_book_export)], { type: "application/octet-stream" }),
      "Shareholding Sample File.xlsx"
    );
  };
  const upload = async (e) => {
    setFileError("");
    setfileData(null);
    let file = e?.target.files[0];
    let filesize = parseInt(file.size);
    if (filesize <= 2097152) {
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (type === "csv") {
        //code here
        setFile(file);
        setFileName(file.name);
        //start
        let jsonarray = [];
        csv()
          .fromFile(file.path)
          .then((jsonObj) => {
            jsonarray = jsonObj;
            jsonarray.forEach((element) => {});
            setfileData(jsonarray);
            setFileDataCount(jsonarray.length);
          });

        //end
      } else if (type === "xlsx") {
        setFile(file);
        setFileName(file.name);

        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target?.result;
          const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */

          // _.forEach(ws, (value, key) => {
          //   if (value?.v?.toString() !== value?.w?.toString()) {
          //     _.mapValues(value, () => {});
          //   }
          //   // _.forEach(value, (v, k) => {
          //   //   if(k=="w")
          //   // });
          // });
          const data = xls.utils.sheet_to_json(ws, {
            header: 2,
            raw: false,
            dateNF: "YYYY-MM-DD",
          });
          /* Update state */

          //  let jsonarray:any = [];
          setfileData(data);

          setFileDataCount(data.length);
          setCheckFileInput(true);
          const newData = data.map((item) => {
            return _.mapKeys(item, (value, key) => {
              return key
                .replaceAll(" ", "")
                .replaceAll("(Y/N)", "")
                .replaceAll("/", "");
            });
          });
          // console.log(
          //   newData.map((row) => {
          //     let updated_date = getvalidDateYMD(
          //       new Date(
          //         `${row.PassPortExpiryDate.split("/")[1]}/${
          //           row.PassPortExpiryDate.split("/")[0]
          //         }/${row.PassPortExpiryDate.split("/")[2]}`
          //       )
          //     );
          //     return {
          //       ...row,
          //       PassPortDate: ,
          //     };
          //   })
          // );
          setModFileData(
            newData.map((row) => {
              const date_array = getvalidDateYMD(row.PassPortExpiryDate).split(
                "-"
              );
              const updated_date = `${date_array[0]}-${date_array[2]}-${date_array[1]}`;
              return {
                ...row,
                PassPortDate: updated_date,
              };
            })
          );
          // Check If Company Exist
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
        // end 2
      } else {
        toast.error("Please Upload Correct Format of File");
        setFileDataCount(0);
      }
    } else {
      toast.error("File size should be less than 2MB");
      setFileDataCount(0);
    }
  };
  const validateFields = () => {
    if (!checkFileInput) {
      toast.error("Please Upload File First");
      return false;
    }
    if (selectedCompany === "") {
      toast.error("Please Select Company");
      return false;
    }
    // toast.success("File is Getting Uploaded");
    return true;
  };
  const handleBulkUpload = async () => {
    if (validateFields()) {
      const email = sessionStorage.getItem("email") || "";
      setLoading(true);
      const response = await shareHoldingBulkUpload(
        email,
        selectedCompany,
        modFileData
      );
      if (response?.status === 200) {
        toast.success(response.data.message);
        setfileData([]);
        setModFileData([]);
        setLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Physical Holding Uploader" parent="Shareholdings" />
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div className="card ">
            <div className="card-header b-t-primary">
              <h5>Bulk Upload</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="company">Company</label>
                  <Select
                    isLoading={companies.companies_dropdown.length === 0}
                    options={companies.companies_dropdown}
                    onChange={(selected) => setSelectedCompany(selected.value)}
                    id="parent"
                    placeholder="Select Company"
                    styles={darkStyle}
                  />
                </div>
                <div className="col-md-6">
                  <label>Bulk Upload</label>
                  <input
                    className="form-control"
                    name="bulkUpload"
                    type="file"
                    placeholder="Upload"
                    multiple={false}
                    onChange={(e) => {
                      upload(e);
                    }}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                  />
                </div>
              </div>
              <div className="my-3 d-flex flex-row justify-content-between">
                <div className="d-flex flex-column">
                  <button
                    type="button"
                    className="btn btn-primary mt-4"
                    onClick={handleBulkUpload}
                    disabled={Boolean(Loading)}
                  >
                    {Loading ? (
                      <>
                        <span className="fa fa-spinner fa-spin"></span>
                        <span>{"Loading..."}</span>
                      </>
                    ) : (
                      <span>{"Upload"}</span>
                    )}
                  </button>
                </div>
                <div className="d-flex flex-column">
                  <button
                    type="button"
                    className="btn btn-info mt-4"
                    onClick={(e) => handleDownloadSampleFile()}
                  >
                    {"Download Sample File"}
                  </button>
                </div>
              </div>
              {/* {showIssues && (
                <div className="my-3 d-flex flex-row justify-content-between">
                  <div
                    className="alert alert-danger fade show w-75"
                    role="alert"
                  >
                    <h5 className="alert-heading">
                      <b>Issues In File</b>
                    </h5>
                    <ul className="issue-list">
                      {issuesInFile.length > 0 &&
                        issuesInFile.map((issue, i) => (
                          <li key={i}>{issue}</li>
                        ))}
                    </ul>
                  </div>
                </div>
              )} */}
              {/* start */}
              {fileDataCount <= 0 ? (
                ""
              ) : (
                <div className="table-responsive mt-3">
                  <JsonToTable json={fileData} />
                </div>
              )}
              Total Rows: <span className="text-primary">{fileDataCount} </span>
              {/* end */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
