import * as yup from "yup";
import { IsJsonString } from "../../utilities/utilityFunctions";

export const addTransferDeedVerificationSchema = yup.object().shape({
  request_date: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  // execution_date: yup.string().test("test-date", "Enter Date", (value) => {
  //   return value;
  // }),
  company_code: yup.object().nullable().required("Select Company"),
  // Transfer Details
  transferor_folio_no: yup.object().nullable().required("Select Transferor"),
  // TRansfer Details

  no_of_certificates: yup
    .string()
    .test(
      "check-val",

      "Transfer At least One Certificate",
      (val) => parseInt(val) > 0
    )
    .required("Enter Number of Certificate"),
  attachment: yup
    .mixed()
    .test("fileSize", "Image Size Should be less than 1MB", (value) => {
      return value.length !== 0 ? value[0]?.size <= 1000000 : true;
    })
    .test("fileType", "We Only accept Image type of PNG, JPG, JPEG", (value) =>
      value.length !== 0
        ? value[0]?.type === "image/jpeg" ||
          value[0]?.type === "image/jpg" ||
          value[0]?.type === "image/png"
        : true
    ),
  remarks: yup.string(),
});

export const updateTransferDeedVerificationSchema = (form) =>
  yup.object().shape({
    request_date: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(form.request_date),
    company_code: yup
      .object()
      .required("Select Company")
      .default(form.company_code),
    // Transfer Details
    transferor_folio_no: yup
      .object()
      .required("Select Transferor")
      .default(form.folio_number),
    // Transfer Details
    no_of_certificates: yup
      .string()
      .required("Enter Number of Certificate")
      .default(JSON.parse(form.input_certificates).length),
    remarks: yup.string().default(form?.remarks),
  });
