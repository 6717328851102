import axios from "axios";
import Config from "../../config";

const getCompanies = async (email) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/companies?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const geAllActiveCompanies = async (email) => {
  const token = JSON.parse(sessionStorage.getItem("token")) || "";
  const url = `${Config.baseUrl}/companies/active?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const getFolioByCounter = async (email, company_code) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/companies/folio-counter?email=${email}&company_code=${company_code}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
const getCertificateNo = async (email, company_code) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/companies/share-counter?email=${email}&company_code=${company_code}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const getCompanyById = async (email, id) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/companies/by-id?email=${email}&code=${id}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const addCompany = async (
  email,
  code,
  company_name,
  isin,
  registered_name,
  symbol,
  company_secretary,
  parent_code,
  active,
  ntn,
  incorporation_no,
  sector_code,
  website,
  contact_person_name,
  contact_person_phone,
  contact_person_exchange_no,
  contact_person_email,
  ceo_name,
  ceo_phone,
  ceo_mobile,
  ceo_email,
  head_office_address,
  head_office_city,
  head_office_country,
  outstanding_shares,
  face_value,
  total_shares,
  free_float,
  treasury_shares,
  preference_shares,
  ordinary_shares,
  non_voting_shares,
  redeemable_shares,
  management_shares,
  company_type,
  authorized_persons,
  governance,
  allot_size
) => {
  const url = `${Config.baseUrl}/companies/`;

  const result = await axios.post(
    url,
    {
      email,
      code,
      company_name,
      isin,
      registered_name,
      symbol,
      company_secretary,
      parent_code,
      active,
      ntn,
      incorporation_no,
      sector_code,
      website,
      contact_person_name,
      contact_person_phone,
      contact_person_exchange_no,
      contact_person_email,
      ceo_name,
      ceo_phone,
      ceo_mobile,
      ceo_email,
      head_office_address,
      head_office_city,
      head_office_country,
      outstanding_shares,
      face_value,
      total_shares,
      free_float,
      treasury_shares,
      preference_shares,
      ordinary_shares,
      non_voting_shares,
      redeemable_shares,
      management_shares,
      company_type,
      authorized_persons,
      governance,
      allot_size,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const updateCompany = async (
  email,
  code,
  company_name,
  isin,
  registered_name,
  symbol,
  company_secretary,
  parent_code,
  active,
  ntn,
  incorporation_no,
  sector_code,
  website,
  contact_person_name,
  contact_person_phone,
  contact_person_exchange_no,
  contact_person_email,
  ceo_name,
  ceo_phone,
  ceo_mobile,
  ceo_email,
  head_office_address,
  head_office_city,
  head_office_country,
  outstanding_shares,
  face_value,
  total_shares,
  free_float,
  treasury_shares,
  preference_shares,
  ordinary_shares,
  non_voting_shares,
  redeemable_shares,
  management_shares,
  company_type,
  authorized_persons,
  governance,
  allot_size
) => {
  const url = `${Config.baseUrl}/companies/update`;

  const result = await axios.post(
    url,
    {
      email,
      code,
      company_name,
      isin,
      registered_name,
      symbol,
      company_secretary,
      parent_code,
      active,
      ntn,
      incorporation_no,
      sector_code,
      website,
      contact_person_name,
      contact_person_phone,
      contact_person_exchange_no,
      contact_person_email,
      ceo_name,
      ceo_phone,
      ceo_mobile,
      ceo_email,
      head_office_address,
      head_office_city,
      head_office_country,
      outstanding_shares,
      face_value,
      total_shares,
      treasury_shares,
      free_float,
      preference_shares,
      ordinary_shares,
      non_voting_shares,
      redeemable_shares,
      management_shares,
      company_type,
      authorized_persons,
      governance,
      allot_size,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

export {
  getCompanies,
  getCertificateNo,
  addCompany,
  updateCompany,
  getCompanyById,
  getFolioByCounter,
  geAllActiveCompanies,
};
