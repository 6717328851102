import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import DCCLogo from "../../../assets/DCC-Logo.svg";
import logo from "../../../assets/images/shareholder-app.svg";
import { AiOutlineLeft } from "react-icons/ai";
import moment from "moment";
import "./evotingStyle.scss";
import { Logout } from "./logout";

// import { useState } from 'react';
export const ElectionVoting = () => {
  // const getEvotingData = JSON.parse(localStorage.getItem("evotingData"));
  const getEvotingData = JSON.parse(sessionStorage.getItem("evotingData"));
  const isSmallScreen = window.matchMedia("(max-width: 500px)").matches;
  const [termCondition, setTermCondition] = useState(false);
  const history = useHistory();
  return (
    <div className="evotingfontfamily" style={{ padding: "20px" }}>
      <MainWrapper className="row mt-4 ">
        <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
          <div className="card pb-5 " style={{ border: "1px solid #70ace7" }}>
            <Wrapper className="card-header " style={{ borderBottom: "none" }}>
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <AiOutlineLeft
                  className="mt-3"
                  size={30}
                  style={{ color: "#677ecc", cursor: "pointer" }}
                  onClick={() => {
                    history.push(`${process.env.PUBLIC_URL}/evoting/home`);
                  }}
                />
                <Logout />
              </div>

              <div className="text-center mt-3">
                <img
                  src={
                    getEvotingData.logo
                      ? getEvotingData.logo
                      : require("../../../assets/images/defaultImg.png")
                  }
                  alt=""
                  maxWidth="220px"
                  height={"130px"}
                  style={{
                    maxWidth: "220px",
                    maxHeight: "130px",
                    width: "auto",
                    height: "auto",
                  }}
                />
              </div>
              <div
                className="mt-3"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "20px",
                }}
              >
                eVoting on
              </div>
            </Wrapper>
            <div> </div>
            <div
              className=" mt-4"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <div className="responsivepaddingpage">
                <div
                  className="mt-4"
                  style={{
                    color: "#447bc9",
                    fontSize: "23px",
                    textAlign: "center",
                  }}
                >
                  Election of Directors
                </div>
                <p
                  style={{
                    fontSize: "16px",
                    color: "rgb(152, 161, 181)",
                    textAlign: "center",
                  }}
                  class="card-text"
                >
                  {moment(getEvotingData?.election_from).format(
                    "DD-MMM-YYYY h:mmA"
                  )}{" "}
                  <br /> TO <br />{" "}
                  {moment(getEvotingData?.election_to).format(
                    "DD-MMM-YYYY h:mmA"
                  )}{" "}
                </p>

                <div style={{ marginTop: "110px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      style={{
                        transform: "scale(2)",
                        width: isSmallScreen ? "10px" : "11px",
                        height: isSmallScreen ? "10px" : "11px",
                      }}
                      onChange={(e) => {
                        setTermCondition(e?.target?.checked);
                      }}
                    />
                    <div style={{ fontSize: "20px" }}> I agree to the</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#677ecc",
                      fontSize: "20px",
                    }}
                  >
                    Terms and Conditions
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "20px",
                    }}
                  >
                    of the eVoting
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="btn mt-2"
                    type="button"
                    style={{
                      color: "#ffff",
                      background: "#5b9bd5",
                      minWidth: "140px",
                    }}
                    disabled={!termCondition}
                    onClick={() => {
                      history.push(`${process.env.PUBLIC_URL}/voting`);
                    }}
                  >
                    Enter
                  </button>
                </div>
              </div>

              <div></div>
            </div>

            {/* <a style={{display: 'flex', justifyContent: 'center' }} className='mt-5'> Past Voting</a> */}
          </div>
        </PaddingWrapper>
      </MainWrapper>
    </div>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepage {
    }
  }
  @media (min-width: 500px) {
    .responsivepage {
      display: flex;
      justify-content: center;
    }
  }
`;
const PaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepaddingpage {
      ${"" /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivepaddingpage {
      ${
        "" /* display: flex;
      justify-content: center; */
      }
      padding: 0px 100px;
      min-width: 550px;
      max-width: 550px;
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;
const Wrapper = styled.div`
  @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;
