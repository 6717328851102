import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import checkCircle from "../../../assets/images/circle-check.png";
import { AiOutlineLeft } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "./evotingStyle.scss";
import { Logout } from "./logout";
import { BsInfoCircleFill } from "react-icons/bs";
import {
  addPublicSubscription,
  updatePublicSubscription,
} from "../../../store/services/publicOfferings.service";

export const IpoSubscriptionPayment = () => {
  const getTempEmail = JSON.parse(sessionStorage.getItem("email"));
  const getIpoData = JSON.parse(sessionStorage.getItem("ipoData"));
  const getUserData = JSON.parse(sessionStorage.getItem("user_data"));
  const getInvestorDetails = JSON.parse(
    sessionStorage.getItem("investorDetails")
  );
  const getSubscriptionData = JSON.parse(
    sessionStorage.getItem("subscriptionData")
  );

  const [IpoSubscriptionStep, setIpoSubscriptionStep] = useState(1);
  const [numberOfSharesSubscription, setNumberOfSharesSubscription] = useState(
    getSubscriptionData.shares_subscribed
      ? getSubscriptionData.shares_subscribed
      : 0
  );
  const [totalPriceOfSubscription, setTotalPriceOfSubscription] = useState(
    getSubscriptionData.amount_payable ? getSubscriptionData.amount_payable : 0
  );
  //states for IPO listing
  const [paymentMethods, setPaymentMethods] = useState([
    {
      id: "transfer",
      value: "transfer",
      label: "Online Transfer",
      subLabel: "Transfer from your Bank's App",
    },
    {
      id: "deposit",
      value: "deposit",
      label: "Bank Deposit",
      subLabel: "Deposit into designated accounts.",
    },
  ]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState();

  const [paymentProofFile, setPaymentProofFile] = useState();
  const [disableButtons, setDisableButtons] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [addNewCDC, setAddNewCDC] = useState(false);
  const [selectedCdcId, setSelectedCDCId] = useState("");

  const handleRadioChange = (e) => {
    setSelectedPaymentMethod(e.target.value);
  };

  const handleAccountRadioChange = (e) => {
    setSelectedPaymentAccount(e.target.value);
  };

  const handleProofUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    if (!allowedTypes.includes(file.type)) {
      toast.error("Please upload a valid image file (JPEG, PNG, or WEBP)");
      return;
    }

    // Check file size
    if (file.size > 256 * 1024) {
      // 256 KB
      toast.error("Image size must be less than 256 KB");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      setPaymentProofFile(`data:${file.type};base64,${base64String}`);
    };
    reader.readAsDataURL(file);
  };

  const sendHome = () => {
    window.addEventListener("popstate", () => {
      history.replace(`${process.env.PUBLIC_URL}/evoting/home`);
    });
  };

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  useEffect(() => {
    if (getIpoData.payment_accounts && getIpoData.payment_accounts.length > 0) {
      let tempArr = [];
      let accounts = getIpoData.payment_accounts;
      for (let i = 0; i < accounts.length; i++) {
        tempArr.push({
          id: i,
          value: accounts[i].iban
            ? accounts[i].iban
            : accounts[i].accountNo
            ? accounts[i].accountNo
            : "",
          accountTitle: accounts[i].accountTitle,
          accountNo: accounts[i].accountNo,
          iban: accounts[i].iban,
          bank: accounts[i].bank,
          bankCode: accounts[i].bankCode,
          branch: accounts[i].branch,
          branchCode: accounts[i].branchCode,
        });
      }
      setPaymentAccounts(tempArr);
    } else {
      setPaymentAccounts([]);
    }
  }, []);

  useEffect(() => {
    if (IpoSubscriptionStep === 1) {
      if (!selectedPaymentMethod || selectedPaymentMethod.trim() === "") {
        setDisableNextButton(true);
      } else if (selectedPaymentMethod && selectedPaymentMethod.trim() !== "")
        setDisableNextButton(false);
    } else if (IpoSubscriptionStep === 2) {
      if (!selectedPaymentAccount || selectedPaymentAccount.trim() === "") {
        setDisableNextButton(true);
      } else if (selectedPaymentAccount && selectedPaymentAccount.trim() !== "")
        setDisableNextButton(false);
    } else if (IpoSubscriptionStep === 3) {
      if (!paymentProofFile || paymentProofFile.trim() === "") {
        setDisableNextButton(true);
      } else if (paymentProofFile && paymentProofFile.trim() !== "")
        setDisableNextButton(false);
    }
  }, [
    IpoSubscriptionStep,
    numberOfSharesSubscription,
    totalPriceOfSubscription,
    selectedPaymentMethod,
    selectedPaymentAccount,
    paymentProofFile,
  ]);

  const history = useHistory();

  const handleNext = async () => {
    if (IpoSubscriptionStep > 5) {
      handleHome();
    } else {
      if (IpoSubscriptionStep === 3) {
        try {
          let selectedAccountDetails = paymentAccounts.find(
            (item) => item.value === selectedPaymentAccount
          );

          let item = {
            email: getTempEmail,
            subscription_id: getSubscriptionData.subscription_id,

            offer_id: getSubscriptionData.offer_id,
            company_code: getSubscriptionData.company_code
              ? getSubscriptionData.company_code
              : "",
            symbol: getSubscriptionData.symbol
              ? getSubscriptionData.symbol
              : "",
            investor_id: getSubscriptionData.investor_id
              ? getSubscriptionData.investor_id
              : "",
            folio_number: getSubscriptionData.folio_number
              ? getSubscriptionData.folio_number
              : "",
            investor_name: getSubscriptionData.investor_name
              ? getSubscriptionData.investor_name
              : "",
            market: getSubscriptionData.market
              ? getSubscriptionData.market
              : "",
            subscription_date: getSubscriptionData.subscription_date
              ? getSubscriptionData.subscription_date
              : "",
            cnic: getSubscriptionData.cnic ? getSubscriptionData.cnic : "",
            ntn: getSubscriptionData.ntn ? getSubscriptionData.ntn : "",
            passport: getSubscriptionData.passport
              ? getSubscriptionData.passport
              : "",
            investor_email: getSubscriptionData.investor_email
              ? getSubscriptionData.investor_email
              : "",
            subscribed_through: getSubscriptionData.subscribed_through
              ? getSubscriptionData.subscribed_through
              : "",
            price: getSubscriptionData.price ? getSubscriptionData.price : 0,
            shares_subscribed: getSubscriptionData.shares_subscribed
              ? getSubscriptionData.shares_subscribed
              : 0,
            shares_alloted: getSubscriptionData.shares_alloted
              ? getSubscriptionData.shares_alloted
              : 0,
            balance_shares: getSubscriptionData.balance_shares
              ? getSubscriptionData.balance_shares
              : 0,
            amount_payable: getSubscriptionData.amount_payable
              ? getSubscriptionData.amount_payable
              : 0,
            amount_paid: getSubscriptionData.amount_payable
              ? getSubscriptionData.amount_payable
              : 0,
            amount_retained: getSubscriptionData.amount_retained
              ? getSubscriptionData.amount_retained
              : 0,
            amount_refund: getSubscriptionData.amount_refund
              ? getSubscriptionData.amount_refund
              : 0,
            amount_refunded: false,
            instrument_type: "",
            instrument_number: "",
            bank: "",
            bank_code: "",
            branch: "",
            branch_code: "",
            payment_date: moment().format("YYYY-MM-DD"), // payment date
            payment_status: "PENDING-VERIFICATION",
            payment_evidence: paymentProofFile,
            payment_verified: false, // payment verified

            payment_method: selectedPaymentMethod,
            payment_from: "",
            payment_bank: selectedAccountDetails.bank
              ? selectedAccountDetails.bank
              : "",
            payment_bank_code: selectedAccountDetails.bankCode
              ? selectedAccountDetails.bankCode
              : "",
            payment_branch: selectedAccountDetails.branch
              ? selectedAccountDetails.branch
              : "",
            payment_branch_code: selectedAccountDetails.branchCode
              ? selectedAccountDetails.branchCode
              : "",
            payment_account_no: selectedAccountDetails.accountNo
              ? selectedAccountDetails.accountNo
              : "",
            payment_iban: selectedAccountDetails.iban
              ? selectedAccountDetails.iban
              : "",
            payment_account_title: selectedAccountDetails.accountTitle
              ? selectedAccountDetails.accountTitle
              : "",
            verified_by: "",
            verified_date: "",
            status: true,
            created_by: "",
            last_updated_by: "",
            bank_account_no: "",
            bank_account_iban: "",
            application_no: "",
            broker_code: "",
            father_husband_name: "",
            category: "",
            cdc_account_no: "",
            cdc_account_type: "",
            cdc_participant_id: "",
            cdc_complete_investor_id: "",
            investor_type: "",
            cdc_sub_account: "",
            public_offering_number: "",
            paid: true,
            payment_no: "",
          };
          const response = await updatePublicSubscription(item);
          if (response.status === 200) {
            toast.success("Payment Details Added!");
            setIpoSubscriptionStep((prevState) => prevState + 1);
          }
        } catch (error) {
          toast.error("Error uploading payment proof!");
          handleHome();
        }
      } else {
        setIpoSubscriptionStep((prevState) => prevState + 1);
      }
    }
  };

  const handleHome = () => {
    history.push(`${process.env.PUBLIC_URL}/evoting/home`);
  };

  return (
    <>
      <ToastContainer />

      <div className="evotingfontfamily" style={{ padding: "20px" }}>
        <MainWrapper className="row mt-4">
          <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
            <div className="card pb-5" style={{ border: "1px solid #70ace7" }}>
              <Wrapper
                className="card-header "
                style={{ borderBottom: "none" }}
              >
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <AiOutlineLeft
                    className="mt-3"
                    size={30}
                    style={{ color: "#677ecc", cursor: "pointer" }}
                    // onClick={() => {
                    //   handleHome();
                    // }}

                    onClick={() => {
                      if (
                        IpoSubscriptionStep === 1 ||
                        IpoSubscriptionStep === 3 ||
                        IpoSubscriptionStep === 4
                      ) {
                        handleHome();
                      } else {
                        setIpoSubscriptionStep((prevState) => prevState - 1);
                      }
                    }}
                  />
                  <Logout />
                </div>

                <div className="glyphicon glyphicon-chevron-left"></div>
                <div className="text-center mt-1">
                  <img
                    src={
                      getIpoData.logo
                        ? getIpoData.logo
                        : require("../../../assets/images/defaultImg.png")
                    }
                    alt=""
                    maxWidth="220"
                    height={"130px"}
                    style={{
                      maxWidth: "220px",
                      maxHeight: "130px",
                      width: "auto",
                      height: "auto",
                    }}
                  />
                </div>
                <div
                  className="mt-3"
                  style={{
                    color: "#447bc9",
                    fontSize: "23px",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  IPO Subscription
                </div>
                <p
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "center",
                    color: "rgb(152, 161, 181)",
                    textAlign: "center",
                  }}
                  class="card-text"
                >
                  {moment(getIpoData?.ipo_from).format("DD-MMM-YYYY")} <br /> TO{" "}
                  <br />
                  {moment(getIpoData?.ipo_to).format("DD-MMM-YYYY")}
                </p>
                <IpoWrapper
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                  }}
                >
                  <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1rem",
                        opacity: "0.75",
                      }}
                    >
                      {"Quantity"}
                    </p>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                      }}
                    >
                      {numberOfSharesSubscription}
                    </p>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1rem",
                        opacity: "0.75",
                      }}
                    >
                      {"Shares"}
                    </p>
                  </div>
                  <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1rem",
                        opacity: "0.75",
                      }}
                    >
                      {"Amount"}
                    </p>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                      }}
                    >
                      {totalPriceOfSubscription}
                    </p>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1rem",
                        opacity: "0.75",
                      }}
                    >
                      {"Rupee"}
                    </p>
                  </div>
                </IpoWrapper>
                {IpoSubscriptionStep < 4 && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "2.5rem",
                    }}
                  >
                    {IpoSubscriptionStep === 1 && (
                      <p>Step 1/3: Payment Method</p>
                    )}
                    {IpoSubscriptionStep === 2 && (
                      <p>Step 2/3: Payment Method</p>
                    )}
                    {IpoSubscriptionStep === 3 && (
                      <p>Step 3/3: Payment Method</p>
                    )}
                  </div>
                )}
              </Wrapper>
              <div className="responsivepaddingpage">
                {IpoSubscriptionStep === 1 && (
                  <div
                    className="card-body"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="pb-3 mb-5">
                      <>
                        <div className="row no-gutters d-flex justify-content-center">
                          <>
                            <div class="row no-gutters">
                              {paymentMethods.map((it, i) => (
                                <div
                                  class={`col-md-12 ${i > 0 && "mt-3"}`}
                                  key={it.id}
                                >
                                  <div
                                    className="d-flex flex-row justify-content-start align-items-center text-start"
                                    style={{ width: "100%", marginLeft: "6%" }}
                                  >
                                    <input
                                      id={it.id}
                                      type="radio"
                                      className="mr-2"
                                      checked={
                                        selectedPaymentMethod === it.value
                                      }
                                      value={it.value}
                                      onChange={(e) => {
                                        handleRadioChange(e);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        transform: "scale(2)",
                                      }}
                                    />
                                    <div
                                      htmlFor={it.id}
                                      className="text-start mt-3"
                                    >
                                      <label
                                        htmlFor={it.id}
                                        className="m-0"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "1.25rem",
                                        }}
                                      >
                                        {it.label}
                                      </label>
                                      <br />
                                      <small htmlFor={it.id} className="m-0">
                                        {it.subLabel}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        </div>
                      </>
                    </div>
                  </div>
                )}

                {IpoSubscriptionStep === 2 && (
                  <div
                    className="card-body"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="pb-3 mb-5">
                      <>
                        <div className="row no-gutters d-flex justify-content-center">
                          <>
                            <div class="row no-gutters">
                              {paymentAccounts.map((it, i) => (
                                <>
                                  <div
                                    class={`col-md-12 ${i > 0 && "mt-3"}`}
                                    key={it.accountTitle + ` account`}
                                  >
                                    <div
                                      className="d-flex flex-row justify-content-start align-items-center text-start"
                                      style={{
                                        width: "100%",
                                        marginLeft: "6%",
                                      }}
                                    >
                                      <input
                                        id={it.id}
                                        type="radio"
                                        className="mr-3"
                                        checked={
                                          selectedPaymentAccount === it.value
                                        }
                                        value={it.value}
                                        onChange={(e) => {
                                          handleAccountRadioChange(e);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          transform: "scale(2)",
                                        }}
                                      />
                                      <div
                                        htmlFor={it.id}
                                        className="text-start mt-3"
                                      >
                                        <label
                                          htmlFor={it.id}
                                          className="m-0"
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <span
                                            style={{ fontWeight: "lighter" }}
                                          >
                                            Title:{" "}
                                          </span>
                                          {it.accountTitle}
                                        </label>
                                        <br />
                                        {it.accountNo && (
                                          <>
                                            <label
                                              htmlFor={it.id}
                                              className="m-0"
                                              style={{
                                                cursor: "pointer",
                                                fontWeight: "600",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: "lighter",
                                                }}
                                              >
                                                Acc #:{" "}
                                              </span>
                                              {it.accountNo}
                                            </label>
                                            <br />
                                          </>
                                        )}

                                        {it.iban && (
                                          <>
                                            <label
                                              htmlFor={it.id}
                                              className="m-0"
                                              style={{
                                                cursor: "pointer",
                                                fontWeight: "600",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: "lighter",
                                                }}
                                              >
                                                IBAN:{" "}
                                              </span>
                                              {it.iban}
                                            </label>
                                            <br />
                                          </>
                                        )}
                                        <label
                                          htmlFor={it.id}
                                          className="m-0"
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            Bank:{" "}
                                          </span>
                                          {it.bank}
                                        </label>
                                        <br />

                                        <label
                                          htmlFor={it.id}
                                          className="m-0"
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            Bank Code:{" "}
                                          </span>
                                          {it.bankCode}
                                        </label>
                                        <br />

                                        <label
                                          htmlFor={it.id}
                                          className="m-0"
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            Branch:{" "}
                                          </span>
                                          {it.branch}
                                        </label>
                                        <br />

                                        <label
                                          htmlFor={it.id}
                                          className="m-0"
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            BranchCode:{" "}
                                          </span>
                                          {it.branchCode}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: "lightgray",
                                      width: "100%",
                                      height: "0.2%",
                                      marginTop: "0.2rem",
                                    }}
                                  />
                                </>
                              ))}
                            </div>
                          </>
                        </div>
                      </>
                    </div>
                  </div>
                )}

                {IpoSubscriptionStep === 3 && (
                  <div
                    className="card-body"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="pb-3 mb-5">
                      <div className="row no-gutters d-flex justify-content-center">
                        <div class="row no-gutters">
                          <div class={`col-md-12`}>
                            <div
                              className="mb-3"
                              style={{
                                width: "100%",
                                whiteSpace: "pre-wrap",
                                textAlign: "center",
                              }}
                            >
                              <span>
                                Upload a screenshot of the bank transfer /
                                deposit slip made to the selected account
                              </span>
                            </div>
                            <div
                              className="d-flex flex-column justify-content-start align-items-center text-start"
                              style={{
                                width: "100%",
                              }}
                            >
                              {/* <input
                                id="paymentProof"
                                type="file"
                                className="mr-3"
                                value={paymentProof}
                                onChange={(e) => {
                                  handleProofUpload(e);
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              /> */}
                              <input
                                id="paymentProof"
                                type="file"
                                accept=".jpg, .jpeg, .png, .webp"
                                style={{ display: "none" }}
                                onChange={handleProofUpload}
                              />
                              <button
                                className="btn mr-2"
                                type="button"
                                style={{
                                  width: "100%",
                                  border: "1px solid grey",
                                  color: "#5B9BD5",
                                  background: "#ffff",
                                }}
                                onClick={() =>
                                  document
                                    .getElementById("paymentProof")
                                    .click()
                                }
                              >
                                {paymentProofFile
                                  ? "Replace Image"
                                  : "Upload Image"}
                              </button>

                              {paymentProofFile && (
                                <>
                                  <br />
                                  <div style={{ marginTop: "20px" }}>
                                    <img
                                      src={paymentProofFile}
                                      alt="Preview"
                                      style={{
                                        maxWidth: "256px",
                                        width: "256px",
                                        height: "256px",
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* BELOW THIS COMPLETED */}
                {IpoSubscriptionStep > 3 && (
                  <div
                    className="card-body mt-4"
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <div>
                      <div className="text-center mt-1">
                        <img
                          src={checkCircle}
                          alt=""
                          width="130"
                          height={"130px"}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          color: "#00b050",
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Thank you for the submission
                      </div>

                      <div
                        className="mt-4"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {" "}
                        You can see the status of the
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {" "}
                        application in due course.
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {IpoSubscriptionStep <= 3 ? (
                    <button
                      className="btn mb-3"
                      type="button"
                      style={{
                        width: "89%",
                        color: "#ffff",
                        background: "#5B9BD5",
                      }}
                      disabled={disableNextButton}
                      onClick={() => {
                        handleNext();
                      }}
                    >
                      <span>{IpoSubscriptionStep > 2 ? "SUBMIT" : "NEXT"}</span>
                    </button>
                  ) : (
                    <div></div>
                  )}

                  <button
                    className="btn"
                    type="button"
                    style={{
                      width: "89%",
                      border: "1px solid #5B9BD5",
                      color: "#5B9BD5",
                      background: "#ffff",
                    }}
                    onClick={() => {
                      if (
                        IpoSubscriptionStep === 1 ||
                        IpoSubscriptionStep === 3 ||
                        IpoSubscriptionStep === 4
                      ) {
                        handleHome();
                      } else {
                        setIpoSubscriptionStep((prevState) => prevState - 1);
                      }
                    }}
                  >
                    {IpoSubscriptionStep === 1 && <span>Cancel</span>}
                    {IpoSubscriptionStep === 2 && <span>Previous</span>}
                    {IpoSubscriptionStep === 3 && <span>Pay Later</span>}
                    {IpoSubscriptionStep === 4 && <span>Close</span>}
                  </button>
                </div>

                {IpoSubscriptionStep < 4 && (
                  <div
                    className="mt-5 pl-2"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <div className="text-center mt-1 mr-2">
                      <BsInfoCircleFill
                        size={40}
                        style={{ color: "lightblue" }}
                      />
                    </div>
                    <div
                      className="pr-2"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {IpoSubscriptionStep === 1 && (
                        <span>
                          Choose a payment method for the subscription amount.
                        </span>
                      )}
                      {IpoSubscriptionStep === 2 && (
                        <span>
                          Choose a bank account for the transfer of the amount.
                        </span>
                      )}

                      {IpoSubscriptionStep === 3 && (
                        <span>
                          Transfer or deposit the amount from your bank's app
                          and upload screenshot here.
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </PaddingWrapper>
        </MainWrapper>
      </div>
    </>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepage {
    }
  }
  @media (min-width: 500px) {
    .responsivepage {
      display: flex;
      justify-content: center;
    }
  }
`;
const PaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepaddingpage {
      ${"" /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivepaddingpage {
      ${
        "" /* display: flex;
      justify-content: center; */
      }
      padding: 0px 100px;
      min-width: 550px;
      max-width: 550px;s
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;
const Wrapper = styled.div`
  @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;

const IpoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media (max-width: 768px) {
    // Adjust breakpoint according to your requirement
    flex-direction: column;
  }
`;
