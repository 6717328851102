import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import checkCircle from "../../../assets/images/circle-check.png";
import { AiOutlineLeft } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "./evotingStyle.scss";
import { Logout } from "./logout";
import { BsInfoCircleFill } from "react-icons/bs";
import {
  addPublicSubscription,
  updatePublicSubscription,
} from "../../../store/services/publicOfferings.service";
import { updateInvestor } from "../../../store/services/investor.service";

export const IpoSubscription = () => {
  const getTempEmail = JSON.parse(sessionStorage.getItem("email"));
  const getIpoData = JSON.parse(sessionStorage.getItem("ipoData"));
  const getUserData = JSON.parse(sessionStorage.getItem("user_data"));
  const getInvestorDetails = JSON.parse(
    sessionStorage.getItem("investorDetails")
  );
  const [loading, setLoading] = useState(false);
  const [finalOfferRate, setFinalOfferRate] = useState(
    getIpoData.final_offer_price
  );
  const [lotSize, setLotSize] = useState(getIpoData.lot_size);
  const [IpoSubscriptionStep, setIpoSubscriptionStep] = useState(1);
  const [numberOfSharesSubscription, setNumberOfSharesSubscription] =
    useState(0);
  const [totalPriceOfSubscription, setTotalPriceOfSubscription] = useState(0);
  const [paymentProof, setPaymentProof] = useState("");
  const [numberOfSharesError, setNumberOfSharesError] = useState(false);
  const [numberOfSharesErrorMessage, setNumberOfSharesErrorMessage] =
    useState("");
  //states for IPO listing
  const [allExistingCdcAccounts, setAllExistingCdcAccounts] = useState(
    getInvestorDetails.cdc_participant_id
  );
  const [cdcRadioData, setCDCRadioData] = useState([]);
  const [cdcAccountNo, setCdcAccountNo] = useState("");
  const [cdcAccountType, setCdcAccountType] = useState("");
  const [cdcParticipantId, setCdcParticipantId] = useState("");
  const [completeCdcInvestorID, setCompleteCdcInvestorID] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([
    {
      id: "transfer",
      value: "transfer",
      label: "Online Transfer",
      subLabel: "Transfer from your Bank's App",
    },
    {
      id: "deposit",
      value: "deposit",
      label: "Bank Deposit",
      subLabel: "Deposit into designated accounts.",
    },
  ]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState();

  const [paymentProofFile, setPaymentProofFile] = useState();
  const [disableButtons, setDisableButtons] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [addNewCDC, setAddNewCDC] = useState(false);
  const [selectedCdcId, setSelectedCDCId] = useState("");
  const [subscriptionData, setSubscriptionData] = useState({});

  const handleRadioChange = (e) => {
    setSelectedPaymentMethod(e.target.value);
  };

  const handleCdcRadioChange = (e, type) => {
    if (type === "old") {
      let data = cdcRadioData;
      let filterData = cdcRadioData.find(
        (item) => item.completeCdcInvestorID === e.target.value
      );
      setCdcParticipantId(filterData.cdcParticipantId);
      setCdcAccountNo(filterData.cdcAccountNo);
      setCompleteCdcInvestorID(filterData.completeCdcInvestorID);
      setCdcAccountType(filterData.cdcAccountType);
      setAddNewCDC(false);
      setSelectedCDCId(e.target.value);
    } else {
      setCdcParticipantId("");
      setCdcAccountNo("");
      setCompleteCdcInvestorID("");
      setSelectedCDCId("");
      setCdcAccountType("");
      setAddNewCDC(true);
    }
  };

  const handleAccountRadioChange = (e) => {
    setSelectedPaymentAccount(e.target.value);
  };

  const handleProofUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    if (!allowedTypes.includes(file.type)) {
      toast.error("Please upload a valid image file (JPEG, PNG, or WEBP)");
      return;
    }

    // Check file size
    if (file.size > 256 * 1024) {
      // 256 KB
      toast.error("Image size must be less than 256 KB");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      setPaymentProofFile(`data:${file.type};base64,${base64String}`);
    };
    reader.readAsDataURL(file);
  };

  const sendHome = () => {
    window.addEventListener("popstate", () => {
      history.replace(`${process.env.PUBLIC_URL}/evoting/home`);
    });
  };

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  useEffect(() => {
    if (
      getInvestorDetails.cdc_participant_id &&
      getInvestorDetails.cdc_participant_id.length > 0
    ) {
      setCDCRadioData(
        getInvestorDetails.cdc_participant_id.map((item, index) => {
          return {
            id: item,
            cdcParticipantId: item.split("-")[0],
            cdcAccountNo: item.split("-")[1],
            completeCdcInvestorID: item,
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    if (getIpoData.payment_accounts && getIpoData.payment_accounts.length > 0) {
      let tempArr = [];
      let accounts = getIpoData.payment_accounts;
      for (let i = 0; i < accounts.length; i++) {
        tempArr.push({
          id: i,
          value: accounts[i].iban
            ? accounts[i].iban
            : accounts[i].accountNo
            ? accounts[i].accountNo
            : "",
          accountTitle: accounts[i].accountTitle,
          accountNo: accounts[i].accountNo,
          iban: accounts[i].iban,
          bank: accounts[i].bank,
          bankCode: accounts[i].bankCode,
          branch: accounts[i].branch,
          branchCode: accounts[i].branchCode,
        });
      }
      setPaymentAccounts(tempArr);
    } else {
      setPaymentAccounts([]);
    }
  }, []);

  useEffect(() => {
    if (IpoSubscriptionStep === 1) {
      if (!numberOfSharesSubscription || numberOfSharesSubscription === 0) {
        setDisableNextButton(true);
      } else {
        setDisableNextButton(false);
      }
    } else if (IpoSubscriptionStep === 2) {
      if (
        !cdcParticipantId ||
        cdcParticipantId.trim() === "" ||
        !cdcAccountNo ||
        cdcAccountNo.trim() === ""
      ) {
        setDisableNextButton(true);
      } else if (
        cdcParticipantId &&
        cdcParticipantId.trim() !== "" &&
        cdcAccountNo &&
        cdcAccountNo.trim() !== ""
      )
        setDisableNextButton(false);
    } else if (IpoSubscriptionStep === 3) {
      if (!selectedPaymentMethod || selectedPaymentMethod.trim() === "") {
        setDisableNextButton(true);
      } else if (selectedPaymentMethod && selectedPaymentMethod.trim() !== "")
        setDisableNextButton(false);
    } else if (IpoSubscriptionStep === 4) {
      if (!selectedPaymentAccount || selectedPaymentAccount.trim() === "") {
        setDisableNextButton(true);
      } else if (selectedPaymentAccount && selectedPaymentAccount.trim() !== "")
        setDisableNextButton(false);
    } else if (IpoSubscriptionStep === 5) {
      if (!paymentProofFile || paymentProofFile.trim() === "") {
        setDisableNextButton(true);
      } else if (paymentProofFile && paymentProofFile.trim() !== "")
        setDisableNextButton(false);
    }
  }, [
    IpoSubscriptionStep,
    numberOfSharesSubscription,
    totalPriceOfSubscription,
    selectedPaymentMethod,
    selectedPaymentAccount,
    paymentProofFile,
    cdcParticipantId,
    cdcAccountNo,
  ]);

  const history = useHistory();

  const handleSubscription = () => {
    scrollToTop();
  };

  const handleNext = async () => {
    if (IpoSubscriptionStep > 5) {
      handleHome();
    } else {
      if (IpoSubscriptionStep === 4) {
        let selectedAccountDetails = paymentAccounts.find(
          (item) => item.value === selectedPaymentAccount
        );
        try {
          const response = await addPublicSubscription(
            getTempEmail,
            "", // sub id
            getIpoData.offer_id,
            getIpoData.company_code,
            "", // symbol
            getInvestorDetails
              ? getInvestorDetails.investor_id
                ? getInvestorDetails.investor_id
                : ""
              : "",
            getInvestorDetails
              ? getInvestorDetails.folio_number
                ? getInvestorDetails.folio_number
                : ""
              : "",
            getInvestorDetails
              ? getInvestorDetails.investor_name
                ? getInvestorDetails.investor_name
                : ""
              : "",
            "IPO",
            moment().format("YYYY-MM-DD"),
            getInvestorDetails
              ? getInvestorDetails.cnic
                ? getInvestorDetails.cnic
                : ""
              : "",
            getInvestorDetails
              ? getInvestorDetails.ntn
                ? getInvestorDetails.ntn
                : ""
              : "",
            getInvestorDetails
              ? getInvestorDetails.passport_no
                ? getInvestorDetails.passport_no
                : ""
              : "",
            getInvestorDetails
              ? getInvestorDetails.email
                ? getInvestorDetails.email
                : ""
              : "",
            "APP",
            finalOfferRate,
            numberOfSharesSubscription,
            0, // alloted
            0, // balance
            totalPriceOfSubscription,
            0, // paid
            0, // retained
            0, // refund
            false, // refunded
            "", // inst type
            "", // inst no
            "", // bank
            "", // bank code
            "", // branch
            "", // branch code
            "", //payment date
            "", // payment status
            "", //evidence
            false, // verified
            selectedPaymentMethod, //method
            "", // from
            selectedAccountDetails.bank ? selectedAccountDetails.bank : "", // pay bank
            selectedAccountDetails.bankCode
              ? selectedAccountDetails.bankCode
              : "", // pay bank code
            selectedAccountDetails.branch ? selectedAccountDetails.branch : "", // pay branch
            selectedAccountDetails.branchCode
              ? selectedAccountDetails.branchCode
              : "", // pay branch code
            selectedAccountDetails.accountNo
              ? selectedAccountDetails.accountNo
              : "", // pay account no
            selectedAccountDetails.iban ? selectedAccountDetails.iban : "", // pay iban
            selectedAccountDetails.accountTitle
              ? selectedAccountDetails.accountTitle
              : "", // pay acc title
            "", // verified by
            "", //verified date
            true, // status
            "", // created by
            "", // updated by
            "", //bank acc no
            "", // bank iban
            "", // app no
            "", // broker code
            getInvestorDetails.father_name
              ? getInvestorDetails.father_name
              : "",
            getInvestorDetails.category ? getInvestorDetails.category : "",
            cdcAccountNo,
            cdcAccountType,
            cdcParticipantId,
            `${cdcParticipantId}-${cdcAccountNo}`,
            "",
            "",
            getIpoData.public_offering_number
              ? getIpoData.public_offering_number
              : "",
            false,
            ""
          );

          if (response.status === 200) {
            toast.success("Subscription Added!");
            if (addNewCDC) {
              let addCdcAccountDetails = `${cdcParticipantId}-${cdcAccountNo}`;
              let oldCdc = getInvestorDetails.cdc_participant_id
                ? getInvestorDetails.cdc_participant_id
                : [];
              let updatedCdc = [];
              if (oldCdc && oldCdc.length > 0) {
                updatedCdc = [...oldCdc, addCdcAccountDetails];
              } else {
                updatedCdc = [addCdcAccountDetails];
              }

              let investorResponse = await updateInvestor(
                getTempEmail,
                getInvestorDetails.investor_id,
                getInvestorDetails.category ? getInvestorDetails.category : "",
                getInvestorDetails.occupation
                  ? getInvestorDetails.occupation
                  : "",
                getInvestorDetails.salutation
                  ? getInvestorDetails.salutation
                  : "",
                getInvestorDetails.investor_name
                  ? getInvestorDetails.investor_name
                  : "",
                getInvestorDetails.cnic ? getInvestorDetails.cnic : "",
                getInvestorDetails.ntn ? getInvestorDetails.ntn : "",
                getInvestorDetails.birth_date
                  ? getInvestorDetails.birth_date
                  : "",
                getInvestorDetails.gender ? getInvestorDetails.gender : "",
                getInvestorDetails.religion ? getInvestorDetails.religion : "",
                getInvestorDetails.father_name
                  ? getInvestorDetails.father_name
                  : "",
                getInvestorDetails.spouse_name
                  ? getInvestorDetails.spouse_name
                  : "",
                getInvestorDetails.cnic_expiry
                  ? getInvestorDetails.cnic_expiry
                  : "",
                updatedCdc
              );
            }
            setSubscriptionData(response.data.subscription);
            setIpoSubscriptionStep((prevState) => prevState + 1);
          }
        } catch (error) {
          console.log("Error => ", error.message);
          return toast.error("Error processing request. Try Again!");
        }
      } else if (IpoSubscriptionStep === 5) {
        try {
          let selectedAccountDetails = paymentAccounts.find(
            (item) => item.value === selectedPaymentAccount
          );
          let item = {
            email: getTempEmail,
            subscription_id: subscriptionData.subscription_id,
            offer_id: subscriptionData.offer_id,
            company_code: subscriptionData.company_code
              ? subscriptionData.company_code
              : "",
            symbol: subscriptionData.symbol ? subscriptionData.symbol : "",
            investor_id: subscriptionData.investor_id
              ? subscriptionData.investor_id
              : "",
            folio_number: subscriptionData.folio_number
              ? subscriptionData.folio_number
              : "",
            investor_name: subscriptionData.investor_name
              ? subscriptionData.investor_name
              : "",
            market: subscriptionData.market ? subscriptionData.market : "",
            subscription_date: subscriptionData.subscription_date
              ? subscriptionData.subscription_date
              : "",
            cnic: subscriptionData.cnic ? subscriptionData.cnic : "",
            ntn: subscriptionData.ntn ? subscriptionData.ntn : "",
            passport: subscriptionData.passport
              ? subscriptionData.passport
              : "",
            investor_email: subscriptionData.investor_email
              ? subscriptionData.investor_email
              : "",
            subscribed_through: subscriptionData.subscribed_through
              ? subscriptionData.subscribed_through
              : "",
            price: subscriptionData.price ? subscriptionData.price : 0,
            shares_subscribed: subscriptionData.shares_subscribed
              ? subscriptionData.shares_subscribed
              : 0,
            shares_alloted: subscriptionData.shares_alloted
              ? subscriptionData.shares_alloted
              : 0,
            balance_shares: subscriptionData.balance_shares
              ? subscriptionData.balance_shares
              : 0,
            amount_payable: subscriptionData.amount_payable
              ? subscriptionData.amount_payable
              : 0,
            amount_paid: subscriptionData.amount_payable
              ? subscriptionData.amount_payable
              : 0,
            amount_retained: subscriptionData.amount_retained
              ? subscriptionData.amount_retained
              : 0,
            amount_refund: subscriptionData.amount_refund
              ? subscriptionData.amount_refund
              : 0,
            amount_refunded: false,
            instrument_type: "",
            instrument_number: "",
            bank: "",
            bank_code: "",
            branch: "",
            branch_code: "",
            payment_date: moment().format("YYYY-MM-DD"), // payment date
            payment_status: "PENDING-VERIFICATION",
            payment_evidence: paymentProofFile,
            payment_verified: false, // payment verified

            payment_method: selectedPaymentMethod,
            payment_from: "",
            payment_bank: selectedAccountDetails.bank
              ? selectedAccountDetails.bank
              : "",
            payment_bank_code: selectedAccountDetails.bankCode
              ? selectedAccountDetails.bankCode
              : "",
            payment_branch: selectedAccountDetails.branch
              ? selectedAccountDetails.branch
              : "",
            payment_branch_code: selectedAccountDetails.branchCode
              ? selectedAccountDetails.branchCode
              : "",
            payment_account_no: selectedAccountDetails.accountNo
              ? selectedAccountDetails.accountNo
              : "",
            payment_iban: selectedAccountDetails.iban
              ? selectedAccountDetails.iban
              : "",
            payment_account_title: selectedAccountDetails.accountTitle
              ? selectedAccountDetails.accountTitle
              : "",
            verified_by: "",
            verified_date: "",
            status: true,
            created_by: "",
            last_updated_by: "",
            bank_account_no: "",
            bank_account_iban: "",
            application_no: "",
            broker_code: "",
            father_husband_name: "",
            category: "",
            cdc_account_no: "",
            cdc_account_type: "",
            cdc_participant_id: "",
            cdc_complete_investor_id: "",
            investor_type: "",
            cdc_sub_account: "",
            public_offering_number: "",
            paid: true,
            payment_no: "",
          };
          const response = await updatePublicSubscription(item);
          if (response.status === 200) {
            toast.success("Payment Details Added!");
            setIpoSubscriptionStep((prevState) => prevState + 1);
          }
        } catch (error) {
          toast.error("Error uploading payment proof!");
          handleHome();
        }
      } else {
        setIpoSubscriptionStep((prevState) => prevState + 1);
      }
    }
  };

  const handleHome = () => {
    history.push(`${process.env.PUBLIC_URL}/evoting/home`);
  };

  const handleIncrement = () => {
    let oldShares = numberOfSharesSubscription;
    let oldValue = totalPriceOfSubscription;
    let newShares = oldShares + lotSize;
    let newValue = newShares * finalOfferRate;
    setTotalPriceOfSubscription(newValue);

    setNumberOfSharesSubscription(newShares);
  };

  const handleDecrement = () => {
    let oldShares = numberOfSharesSubscription;
    let oldValue = totalPriceOfSubscription;
    let newShares = oldShares - lotSize;
    if (newShares < 0) {
      newShares = 0;
    }
    let newValue = newShares * finalOfferRate;
    setTotalPriceOfSubscription(newValue);

    setNumberOfSharesSubscription(newShares);
  };

  return (
    <>
      <ToastContainer />

      <div className="evotingfontfamily" style={{ padding: "20px" }}>
        <MainWrapper className="row mt-4">
          <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
            <div className="card pb-5" style={{ border: "1px solid #70ace7" }}>
              <Wrapper
                className="card-header "
                style={{ borderBottom: "none" }}
              >
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <AiOutlineLeft
                    className="mt-3"
                    size={30}
                    style={{ color: "#677ecc", cursor: "pointer" }}
                    // onClick={() => {
                    //   handleHome();
                    // }}

                    onClick={() => {
                      if (
                        IpoSubscriptionStep === 1 ||
                        IpoSubscriptionStep === 6 ||
                        IpoSubscriptionStep === 5
                      ) {
                        handleHome();
                      } else {
                        setIpoSubscriptionStep((prevState) => prevState - 1);
                      }
                    }}
                  />
                  <Logout />
                </div>

                <div className="glyphicon glyphicon-chevron-left"></div>
                <div className="text-center mt-1">
                  <img
                    src={
                      getIpoData.logo
                        ? getIpoData.logo
                        : require("../../../assets/images/defaultImg.png")
                    }
                    alt=""
                    maxWidth="220"
                    height={"130px"}
                    style={{
                      maxWidth: "220px",
                      maxHeight: "130px",
                      width: "auto",
                      height: "auto",
                    }}
                  />
                </div>
                <div
                  className="mt-3"
                  style={{
                    color: "#447bc9",
                    fontSize: "23px",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  IPO Subscription
                </div>
                <p
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "center",
                    color: "rgb(152, 161, 181)",
                    textAlign: "center",
                  }}
                  class="card-text"
                >
                  {moment(getIpoData?.ipo_from).format("DD-MMM-YYYY")} <br /> TO{" "}
                  <br />
                  {moment(getIpoData?.ipo_to).format("DD-MMM-YYYY")}
                </p>
                <IpoWrapper
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                  }}
                >
                  <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1rem",
                        opacity: "0.75",
                      }}
                    >
                      {IpoSubscriptionStep === 1 ? "Offer Rate" : "Quantity"}
                    </p>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                      }}
                    >
                      {IpoSubscriptionStep === 1
                        ? `Rs. ${finalOfferRate}`
                        : numberOfSharesSubscription}
                    </p>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1rem",
                        opacity: "0.75",
                      }}
                    >
                      {IpoSubscriptionStep === 1 ? "Per Share" : "Shares"}
                    </p>
                  </div>
                  <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1rem",
                        opacity: "0.75",
                      }}
                    >
                      {IpoSubscriptionStep === 1 ? "Min. Lot Size" : "Amount"}
                    </p>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                      }}
                    >
                      {IpoSubscriptionStep === 1
                        ? lotSize
                        : totalPriceOfSubscription}
                    </p>
                    <p
                      style={{
                        padding: "0",
                        lineHeight: "1rem",
                        margin: "0.2rem",
                        fontSize: "1rem",
                        opacity: "0.75",
                      }}
                    >
                      {IpoSubscriptionStep === 1 ? "Shares" : "Rupee"}
                    </p>
                  </div>
                </IpoWrapper>
                {IpoSubscriptionStep < 6 && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "2.5rem",
                    }}
                  >
                    {IpoSubscriptionStep === 1 && (
                      <p>Step 1/5: Investment Amount</p>
                    )}
                    {IpoSubscriptionStep === 2 && (
                      <p>Step 2/5: Investment Account</p>
                    )}
                    {IpoSubscriptionStep === 3 && (
                      <p>Step 3/5: Payment Method</p>
                    )}
                    {IpoSubscriptionStep === 4 && (
                      <p>Step 4/5: Payment Method</p>
                    )}
                    {IpoSubscriptionStep === 5 && (
                      <p>Step 5/5: Payment Method</p>
                    )}
                  </div>
                )}
              </Wrapper>
              <div className="responsivepaddingpage">
                {IpoSubscriptionStep === 1 && (
                  <div
                    className="card-body"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="pb-3 mb-5">
                      <>
                        <div className="row no-gutters d-flex justify-content-center">
                          <>
                            <div class="row no-gutters">
                              <div class="col-md-12">
                                <div className="d-flex flex-row justify-content-center align-items-center align-content-center">
                                  <button
                                    className="mb-2"
                                    // type="button"
                                    style={{
                                      // width: "20%",
                                      // height: "100%",
                                      color: "#ffff",
                                      background: "#5B9BD5",
                                      // padding: "0.25rem",
                                      fontSize: "1.3rem",
                                      height: "100%",
                                      width: "15%",
                                      cursor: "pointer",
                                      border: "none",
                                    }}
                                    disabled={disableButtons}
                                    onClick={() => {
                                      setDisableButtons(true);
                                      handleDecrement();

                                      setDisableButtons(false);
                                    }}
                                  >
                                    -
                                  </button>
                                  <label
                                    style={{
                                      minWidth: "230px",
                                      maxWidth: "320px",
                                      textAlign: "center",
                                      cursor: "not-allowed",
                                    }}
                                    className={`form-control`}
                                  >
                                    {numberOfSharesSubscription &&
                                    numberOfSharesSubscription > 0
                                      ? `${numberOfSharesSubscription}`
                                      : `Number of Shares`}
                                  </label>
                                  {/* <input
                                    style={{
                                      minWidth: "260px",
                                      maxWidth: "360px",
                                      textAlign: "center",
                                    }}
                                    type="number"
                                    className={`form-control ${
                                      numberOfSharesError ? "b-danger" : ""
                                    }`}
                                    placeholder="Number of Shares"
                                    value={numberOfSharesSubscription}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {
                                      if (e.key === ".") {
                                        e.preventDefault();
                                        return;
                                      }
                                    }}
                                    onChange={(e) => {
                                      let value = parseInt(e.target.value);
                                      // Check if the value is a multiple of 500
                                      if (value % lotSize !== 0) {
                                        // If not, adjust it to the nearest multiple of 500
                                        value =
                                          Math.round(value / lotSize) * lotSize;
                                      }
                                      setNumberOfSharesSubscription(value);
                                      setTotalPriceOfSubscription(
                                        value * finalOfferRate
                                      );
                                    }}
                                  /> */}
                                  <button
                                    // className="btn"
                                    className="mb-2"
                                    // type="button"
                                    style={{
                                      // width: "20%",
                                      // height: "100%",
                                      color: "#ffff",
                                      background: "#5B9BD5",
                                      // padding: "0.25rem",
                                      fontSize: "1.25rem",
                                      height: "100%",
                                      width: "15%",
                                      cursor: "pointer",
                                      border: "none",
                                    }}
                                    disabled={disableButtons}
                                    onClick={() => {
                                      setDisableButtons(true);
                                      handleIncrement();
                                      setDisableButtons(false);
                                    }}
                                  >
                                    +
                                  </button>
                                  {numberOfSharesError && (
                                    <small>{numberOfSharesErrorMessage}</small>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12 mt-4">
                                <div>
                                  <label
                                    style={{
                                      minWidth: "200px",
                                      maxWidth: "360px",
                                      textAlign: "center",
                                      cursor: "not-allowed",
                                    }}
                                    // disabled={true}
                                    // type="number"
                                    className={`form-control`}
                                    // placeholder="Subscription Amount"
                                    // value=
                                    // onWheel={(e) => e.target.blur()}
                                    // onKeyDown={(e) => {
                                    //   if (e.key === ".") {
                                    //     e.preventDefault();
                                    //     return;
                                    //   }
                                    // }}
                                    // onChange={(e) => {
                                    //   setNumberOfSharesSubscription(
                                    //     e.target.value
                                    //   );
                                    // }}
                                  >
                                    {totalPriceOfSubscription &&
                                    totalPriceOfSubscription > 0
                                      ? `Rs. ${totalPriceOfSubscription} pkr`
                                      : `Subscription Amount`}
                                  </label>
                                  {numberOfSharesError && (
                                    <small>{numberOfSharesErrorMessage}</small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      </>
                    </div>
                  </div>
                )}

                {IpoSubscriptionStep === 2 && (
                  <div
                    className="card-body"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="pb-3 mb-5">
                      <>
                        <div className="row no-gutters d-flex justify-content-center">
                          <>
                            <div class="row no-gutters">
                              <div class="col-md-12">
                                {cdcRadioData.map((it, i) => (
                                  <div
                                    class={`col-md-12 ${i > 0 && "mt-3"}`}
                                    key={it.id}
                                  >
                                    <div
                                      className="d-flex flex-row justify-content-start align-items-center text-start"
                                      style={{
                                        width: "100%",
                                        marginLeft: "6%",
                                      }}
                                    >
                                      <input
                                        id={it.id}
                                        type="radio"
                                        name="cdc"
                                        className="mr-2"
                                        checked={
                                          selectedCdcId ===
                                          it.completeCdcInvestorID
                                        }
                                        value={it.completeCdcInvestorID}
                                        onChange={(e) => {
                                          handleCdcRadioChange(e, "old");
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          transform: "scale(2)",
                                        }}
                                      />
                                      <div
                                        htmlFor={it.id}
                                        className="text-start"
                                      >
                                        <label
                                          htmlFor={it.id}
                                          className="m-0"
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "1.25rem",
                                          }}
                                        >
                                          {it.completeCdcInvestorID}
                                        </label>
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div class={"col-md-12 mt-3"}>
                                  <div
                                    className="d-flex flex-row justify-content-start align-items-center text-start"
                                    style={{
                                      width: "100%",
                                      marginLeft: "6%",
                                    }}
                                  >
                                    <input
                                      id="new"
                                      type="radio"
                                      name="cdc"
                                      className="mr-2"
                                      checked={addNewCDC}
                                      value={addNewCDC}
                                      onChange={(e) => {
                                        handleCdcRadioChange(e, "new");
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        transform: "scale(2)",
                                      }}
                                    />
                                    <div className="text-start">
                                      <label
                                        htmlFor="new"
                                        className="m-0"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "1.25rem",
                                        }}
                                      >
                                        ADD NEW
                                      </label>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {addNewCDC && (
                                <>
                                  <div class="col-md-12">
                                    <div className="mt-3">
                                      <label htmlFor="cdc_participant_id">
                                        CDC Participant ID
                                      </label>
                                      <input
                                        className={`form-control`}
                                        name="cdc_participant_id"
                                        type="text"
                                        placeholder="CDC Participant ID"
                                        value={cdcParticipantId}
                                        onChange={(e) =>
                                          setCdcParticipantId(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div class="col-md-12 mt-4">
                                    <div>
                                      <label htmlFor="cdc_account_no">
                                        CDC Account Number
                                      </label>
                                      <input
                                        className={`form-control`}
                                        name="cdc_account_no"
                                        type="text"
                                        placeholder="CDC Acc. No."
                                        value={cdcAccountNo}
                                        onChange={(e) =>
                                          setCdcAccountNo(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div class="col-md-12 mt-4">
                                    <div>
                                      <label htmlFor="cdc_account_type">
                                        CDC Account Type
                                      </label>
                                      <input
                                        className={`form-control`}
                                        name="cdc_account_type"
                                        type="text"
                                        placeholder="CDC Acc. Type"
                                        value={cdcAccountType}
                                        onChange={(e) =>
                                          setCdcAccountType(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div class="col-md-12 mt-4">
                                    <div>
                                      <label htmlFor="cdc_complete">
                                        Complete CDC ID
                                      </label>
                                      <input
                                        disabled={true}
                                        className={`form-control `}
                                        name="cdc_complete"
                                        type="text"
                                        value={`${cdcParticipantId}${
                                          cdcAccountNo ? "-" + cdcAccountNo : ""
                                        }`}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        </div>
                      </>
                    </div>
                  </div>
                )}

                {IpoSubscriptionStep === 3 && (
                  <div
                    className="card-body"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="pb-3 mb-5">
                      <>
                        <div className="row no-gutters d-flex justify-content-center">
                          <>
                            <div class="row no-gutters">
                              {paymentMethods.map((it, i) => (
                                <div
                                  class={`col-md-12 ${i > 0 && "mt-3"}`}
                                  key={it.id}
                                >
                                  <div
                                    className="d-flex flex-row justify-content-start align-items-center text-start"
                                    style={{ width: "100%", marginLeft: "6%" }}
                                  >
                                    <input
                                      id={it.id}
                                      type="radio"
                                      className="mr-2"
                                      checked={
                                        selectedPaymentMethod === it.value
                                      }
                                      value={it.value}
                                      onChange={(e) => {
                                        handleRadioChange(e);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        transform: "scale(2)",
                                      }}
                                    />
                                    <div
                                      htmlFor={it.id}
                                      className="text-start mt-3"
                                    >
                                      <label
                                        htmlFor={it.id}
                                        className="m-0"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "1.25rem",
                                        }}
                                      >
                                        {it.label}
                                      </label>
                                      <br />
                                      <small htmlFor={it.id} className="m-0">
                                        {it.subLabel}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        </div>
                      </>
                    </div>
                  </div>
                )}

                {IpoSubscriptionStep === 4 && (
                  <div
                    className="card-body"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="pb-3 mb-5">
                      <>
                        <div className="row no-gutters d-flex justify-content-center">
                          <>
                            <div class="row no-gutters">
                              {paymentAccounts.map((it, i) => (
                                <>
                                  <div
                                    class={`col-md-12 ${i > 0 && "mt-3"}`}
                                    key={it.accountTitle + ` account`}
                                  >
                                    <div
                                      className="d-flex flex-row justify-content-start align-items-center text-start"
                                      style={{
                                        width: "100%",
                                        marginLeft: "6%",
                                      }}
                                    >
                                      <input
                                        id={it.id}
                                        type="radio"
                                        className="mr-3"
                                        checked={
                                          selectedPaymentAccount === it.value
                                        }
                                        value={it.value}
                                        onChange={(e) => {
                                          handleAccountRadioChange(e);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          transform: "scale(2)",
                                        }}
                                      />
                                      <div
                                        htmlFor={it.id}
                                        className="text-start mt-3"
                                      >
                                        <label
                                          htmlFor={it.id}
                                          className="m-0"
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <span
                                            style={{ fontWeight: "lighter" }}
                                          >
                                            Title:{" "}
                                          </span>
                                          {it.accountTitle}
                                        </label>
                                        <br />
                                        {it.accountNo && (
                                          <>
                                            <label
                                              htmlFor={it.id}
                                              className="m-0"
                                              style={{
                                                cursor: "pointer",
                                                fontWeight: "600",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: "lighter",
                                                }}
                                              >
                                                Acc #:{" "}
                                              </span>
                                              {it.accountNo}
                                            </label>
                                            <br />
                                          </>
                                        )}

                                        {it.iban && (
                                          <>
                                            <label
                                              htmlFor={it.id}
                                              className="m-0"
                                              style={{
                                                cursor: "pointer",
                                                fontWeight: "600",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: "lighter",
                                                }}
                                              >
                                                IBAN:{" "}
                                              </span>
                                              {it.iban}
                                            </label>
                                            <br />
                                          </>
                                        )}
                                        <label
                                          htmlFor={it.id}
                                          className="m-0"
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            Bank:{" "}
                                          </span>
                                          {it.bank}
                                        </label>
                                        <br />

                                        <label
                                          htmlFor={it.id}
                                          className="m-0"
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            Bank Code:{" "}
                                          </span>
                                          {it.bankCode}
                                        </label>
                                        <br />

                                        <label
                                          htmlFor={it.id}
                                          className="m-0"
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            Branch:{" "}
                                          </span>
                                          {it.branch}
                                        </label>
                                        <br />

                                        <label
                                          htmlFor={it.id}
                                          className="m-0"
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontWeight: "lighter",
                                            }}
                                          >
                                            Branch Code:{" "}
                                          </span>
                                          {it.branchCode}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: "lightgray",
                                      width: "100%",
                                      height: "0.2%",
                                      marginTop: "0.2rem",
                                    }}
                                  />
                                </>
                              ))}
                            </div>
                          </>
                        </div>
                      </>
                    </div>
                  </div>
                )}

                {IpoSubscriptionStep === 5 && (
                  <div
                    className="card-body"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="pb-3 mb-5">
                      <div className="row no-gutters d-flex justify-content-center">
                        <div class="row no-gutters">
                          <div class={`col-md-12`}>
                            <div
                              className="pr-2 mb-2"
                              style={{
                                width: "100%",
                                whiteSpace: "pre-wrap",
                                textAlign: "center",
                              }}
                            >
                              <span>
                                Upload a screenshot of the bank transfer /
                                deposit slip made to the selected account
                              </span>
                            </div>
                            <div
                              className="d-flex flex-column justify-content-start align-items-center text-start"
                              style={{
                                width: "100%",
                              }}
                            >
                              {/* <input
                                id="paymentProof"
                                type="file"
                                className="mr-3"
                                value={paymentProof}
                                onChange={(e) => {
                                  handleProofUpload(e);
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              /> */}
                              <input
                                id="paymentProof"
                                type="file"
                                accept=".jpg, .jpeg, .png, .webp"
                                style={{ display: "none" }}
                                onChange={handleProofUpload}
                              />
                              <button
                                className="btn mr-2"
                                type="button"
                                style={{
                                  width: "100%",
                                  border: "1px solid grey",
                                  color: "#5B9BD5",
                                  background: "#ffff",
                                }}
                                onClick={() =>
                                  document
                                    .getElementById("paymentProof")
                                    .click()
                                }
                              >
                                {paymentProofFile
                                  ? "Replace Image"
                                  : "Upload Image"}
                              </button>

                              {paymentProofFile && (
                                <>
                                  <br />
                                  <div style={{ marginTop: "20px" }}>
                                    <img
                                      src={paymentProofFile}
                                      alt="Preview"
                                      style={{
                                        maxWidth: "256px",
                                        width: "256px",
                                        height: "256px",
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* BELOW THIS COMPLETED */}
                {IpoSubscriptionStep > 5 && (
                  <div
                    className="card-body mt-4"
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <div>
                      <div className="text-center mt-1">
                        <img
                          src={checkCircle}
                          alt=""
                          width="130"
                          height={"130px"}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          color: "#00b050",
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Thank you for the submission
                      </div>

                      <div
                        className="mt-4"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {" "}
                        You can see the status of the
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {" "}
                        application in due course.
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {IpoSubscriptionStep <= 5 ? (
                    <button
                      className="btn mb-3"
                      type="button"
                      style={{
                        width: "89%",
                        color: "#ffff",
                        background: "#5B9BD5",
                      }}
                      disabled={disableNextButton}
                      onClick={() => {
                        handleNext();
                      }}
                    >
                      <span>{IpoSubscriptionStep > 5 ? "SUBMIT" : "NEXT"}</span>
                    </button>
                  ) : (
                    <div></div>
                  )}

                  <button
                    className="btn"
                    type="button"
                    style={{
                      width: "89%",
                      border: "1px solid #5B9BD5",
                      color: "#5B9BD5",
                      background: "#ffff",
                    }}
                    onClick={() => {
                      if (
                        IpoSubscriptionStep === 1 ||
                        IpoSubscriptionStep === 6 ||
                        IpoSubscriptionStep === 5
                      ) {
                        handleHome();
                      } else {
                        setIpoSubscriptionStep((prevState) => prevState - 1);
                      }
                    }}
                  >
                    {IpoSubscriptionStep === 1 && <span>Cancel</span>}
                    {(IpoSubscriptionStep === 2 ||
                      IpoSubscriptionStep === 3 ||
                      IpoSubscriptionStep === 4) && <span>Previous</span>}
                    {IpoSubscriptionStep === 5 && <span>Pay Later</span>}
                    {IpoSubscriptionStep === 6 && <span>Close</span>}
                  </button>
                </div>

                {IpoSubscriptionStep < 6 && (
                  <div
                    className="mt-5 pl-2"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <div className="text-center mt-1 mr-2">
                      <BsInfoCircleFill
                        size={40}
                        style={{ color: "lightblue" }}
                      />
                    </div>
                    <div
                      className="pr-2"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {IpoSubscriptionStep === 1 && (
                        <span>
                          The minimum number of shares can be {lotSize} or a
                          multiple of {lotSize}
                        </span>
                      )}
                      {IpoSubscriptionStep === 2 && (
                        <span>
                          Enter your CDC Account details. So that after
                          balloting if shares are alloted they will be added to
                          your CDC account
                        </span>
                      )}
                      {IpoSubscriptionStep === 3 && (
                        <span>
                          Choose a payment method for the subscription amount.
                        </span>
                      )}
                      {IpoSubscriptionStep === 4 && (
                        <span>
                          Choose a bank account for the transfer of the amount.
                        </span>
                      )}

                      {IpoSubscriptionStep === 5 && (
                        <span>
                          Transfer or deposit the amount from your bank's app
                          and upload screenshot here.
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </PaddingWrapper>
        </MainWrapper>
      </div>
    </>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepage {
    }
  }
  @media (min-width: 500px) {
    .responsivepage {
      display: flex;
      justify-content: center;
    }
  }
`;
const PaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepaddingpage {
      ${"" /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivepaddingpage {
      ${
        "" /* display: flex;
      justify-content: center; */
      }
      padding: 0px 100px;
      min-width: 550px;
      max-width: 550px;s
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;
const Wrapper = styled.div`
  @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;

const IpoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media (max-width: 768px) {
    // Adjust breakpoint according to your requirement
    flex-direction: column;
  }
`;
