import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AiOutlineLeft } from "react-icons/ai";
import { numberWithCommas } from "../../../utilities/utilityFunctions";
import { useHistory } from "react-router";
import "./evotingStyle.scss";
import { Logout } from "./logout";
export const ViewVotesCasted = () => {
  // const getEvotingData = JSON.parse(localStorage.getItem("evotingData"));
  const getEvotingData = JSON.parse(sessionStorage.getItem("evotingData"));
  const [totalShares, setTotalShares] = useState(0);
  const history = useHistory();
  let totalvotes = 400;
  let preViewTotalVotes = 0;
  const [inputValues, setInputValues] = useState([
    { Name: "User 1", value: "200" },
    { Name: "User 2", value: "200" },
  ]);
  return (
    <div className="evotingfontfamily" style={{ padding: "20px" }}>
      <MainWrapper className="row mt-4">
        <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage ">
          <div className="card pb-5" style={{ border: "1px solid #70ace7" }}>
            <Wrapper className="card-header " style={{ borderBottom: "none" }}>
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <AiOutlineLeft
                  className="mt-3"
                  size={30}
                  style={{ color: "#677ecc", cursor: "pointer" }}
                  onClick={() => {
                    history.push(`${process.env.PUBLIC_URL}/evoting/home`);
                  }}
                />
                <Logout />
              </div>

              <div className="responsivepaddingpage">
                <div className="text-center mt-1">
                  <img
                    src={
                      getEvotingData.logo
                        ? getEvotingData.logo
                        : require("../../../assets/images/defaultImg.png")
                    }
                    alt=""
                    width="220"
                    height={"130px"}
                  />
                </div>
                <div
                  className="mt-3"
                  style={{
                    color: "#447bc9",
                    fontSize: "23px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Election of Directors
                </div>
                <p
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "center",
                    color: "rgb(152, 161, 181)",
                  }}
                  class="card-text"
                >
                  {getEvotingData?.election_from} to{" "}
                  {getEvotingData?.election_to}
                </p>

                <div
                  className="mt-3"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    fontSize: "20px",
                  }}
                >
                  Your Votes
                </div>
              </div>
            </Wrapper>
            <div
              className="card-body"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <div>
                {inputValues?.map((item, key) => {
                  const startDate = new Date(item?.startdate);
                  const endDate = new Date(item?.endDate);
                  preViewTotalVotes =
                    preViewTotalVotes + (Number(item?.value) || 0);
                  return (
                    <>
                      {" "}
                      <div key={key} class="row no-gutters">
                        <div class="col-md-12 mt-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "18px",
                              color: "#5B9BD5",
                            }}
                          >
                            {" "}
                            {/* Candidate */}
                            {key + 1}{" "}
                            <span style={{ paddingLeft: "5px" }}>
                              {" "}
                              {item?.Name?.toUpperCase()}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            {numberWithCommas(item?.value) || 0}{" "}
                            <span
                              style={{
                                fontSize: "14px",
                                paddingLeft: "8px",
                                color: "rgb(152, 161, 181)",
                              }}
                            >
                              votes
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                border: "1px solid rgb(152, 161, 181)",
                // width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "8px 60px",
                borderRadius: "5px",
                margin: "auto",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "18px",
                  }}
                >
                  Total Votes:{" "}
                  <span style={{ color: "#5B9BD5" }}>
                    {" "}
                    {numberWithCommas(400)}
                  </span>
                </div>
                <div style={{ alignItems: "center", fontSize: "18px" }}>
                  Votes Casts:{" "}
                  <span style={{ color: "#5B9BD5" }}>
                    {numberWithCommas(preViewTotalVotes)}
                  </span>
                </div>
                <div style={{ alignItems: "center", fontSize: "18px" }}>
                  Remaining Votes:
                  <span style={{ color: "#5B9BD5" }}>
                    {numberWithCommas(400 - preViewTotalVotes)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </PaddingWrapper>
      </MainWrapper>
    </div>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepage {
    }
  }
  @media (min-width: 500px) {
    .responsivepage {
      display: flex;
      justify-content: center;
    }
  }
`;
const PaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepaddingpage {
      ${"" /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivepaddingpage {
      ${
        "" /* display: flex;
      justify-content: center; */
      }
      padding: 0px 100px;
      min-width: 550px;
      max-width: 550px;
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;
const Wrapper = styled.div`
  @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;
