import * as yup from "yup";
import { IsJsonString } from "../../utilities/utilityFunctions";

export const addCompanySchema = yup.object().shape({
  // Company Details
  company_name: yup.string().required("Enter Company Name"),
  isin: yup.string(),
  registered_name: yup.string(),
  code: yup.string().required("Enter Company Code"),
  symbol: yup.string().required("Enter Company Symbol"),
  company_secretary: yup.string().required("Enter Company Secretary"),
  ntn: yup.string(),
  parent: yup.object().nullable(),
  incorporation_no: yup.string(),
  sector_code: yup.string(),
  website: yup.string(),
  // Contact Person Details
  contact_person_name: yup.string(),
  contact_person_phone: yup.string(),
  exchange_no: yup.string(),
  contact_person_email: yup.string().email(),
  // Head Office Address
  ho_address: yup.string(),
  ho_city: yup.string(),
  ho_country: yup.string(),
  // CEO Details
  ceo_name: yup.string(),
  ceo_phone: yup.string(),
  ceo_mobile: yup.string(),
  ceo_email: yup.string(),
  // Shareholding Details
  outstanding_shares: yup.string(),
  face_value: yup.string(),
  total_shares: yup.string(),
  allot_size: yup.string().required("Enter Allot Size"),
  // New Added
  shares_counter: yup.string(),
  treasury_shares: yup.string(),
  free_float: yup.string(),
  preference_shares: yup.string(),
  ordinary_shares: yup.string(),
  non_voting_shares: yup.string(),
  redeemable_shares: yup.string(),
  management_shares: yup.string(),
  company_type: yup.string().required("Select Company Type"),
  no_authorized_persons: yup.string(),
  no_governance: yup.string(),
});

export const editCompanySchema = (company) =>
  yup.object().shape({
    // Company Details
    company_name: yup
      .string()
      .required("Enter Company Name")
      .default(company.company_name),
    isin: yup.string().default(company.isin),
    registered_name: yup.string().default(company.registered_name),
    code: yup.string().required("Enter Company Code").default(company.code),
    symbol: yup
      .string()
      .required("Enter Company Symbol")
      .default(company.symbol),
    company_secretary: yup
      .string()
      .required("Enter Company Secretary")
      .default(company.company_secretary),
    ntn: yup.string().default(company.ntn),
    parent: yup.object().nullable().default(company.parent_code),
    incorporation_no: yup.string().default(company.incorporation_no),
    sector_code: yup.string().default(company.sector_code),
    website: yup.string().default(company.website),
    // Contact Person Details
    contact_person_name: yup.string().default(company.contact_person_name),
    contact_person_phone: yup.string().default(company.contact_person_phone),
    exchange_no: yup.string().default(company.contact_person_exchange_no),
    contact_person_email: yup
      .string()
      .email()
      .default(company.contact_person_email),
    // Head Office Address
    ho_address: yup.string().default(company.head_office_address),
    ho_city: yup.string().default(company.head_office_city),
    ho_country: yup.string().default(company.head_office_country),
    // CEO Details
    ceo_name: yup.string().default(company.ceo_name),
    ceo_phone: yup.string().default(company.ceo_phone),
    ceo_mobile: yup.string().default(company.ceo_mobile),
    ceo_email: yup.string().default(company.ceo_email),
    // Shareholding Details
    outstanding_shares: yup.string().default(company.outstanding_shares),
    face_value: yup.string().default(company.face_value),
    total_shares: yup.string().default(company.total_shares),
    allot_size: yup
      .string()
      .required("Enter Allot Size")
      .default(company.allot_size),

    // New Added
    treasury_shares: yup.string().default(company.treasury_shares),
    free_float: yup.string().default(company.free_float),
    preference_shares: yup.string().default(company.preference_shares),
    ordinary_shares: yup.string().default(company.ordinary_shares),
    non_voting_shares: yup.string().default(company.non_voting_shares),
    redeemable_shares: yup.string().default(company.redeemable_shares),
    management_shares: yup.string().default(company.management_shares),
    company_type: yup
      .string()
      .required("Select Company Type")
      .default(company.company_type),
    no_authorized_persons: yup
      .string()
      .default(
        IsJsonString(company.authorized_persons)
          ? JSON.parse(company.authorized_persons).length.toString()
          : 0
      ),
    no_governance: yup
      .string()
      .default(
        IsJsonString(company.governance)
          ? JSON.parse(company.governance).length.toString()
          : 0
      ),
  });
