import * as yup from "yup";
import { IsJsonString } from "../../utilities/utilityFunctions";

export const addConsolidateSharesSchema = yup.object().shape({
  request_date: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  execution_date: yup.string(),
  requester_folio: yup.object().nullable().required("Select Folio"),
  company_code: yup.object().nullable().required("Select Company"),
  folio_number: yup.object().nullable().required("Select Folio Number"),
  no_of_certificates: yup
    .string()
    .test(
      "check-val",
      "Enter At least two certificates to consolidate",
      (val) => parseInt(val) >= 2
    )
    .required("Enter No of Certificates"),
  // new_certificate_no: yup.string().required("Enter Certificate Number"),
  remarks: yup.string(),
});

export const updateConsolidateSharesSchema = (form) =>
  yup.object().shape({
    request_date: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(form.request_date),
    company_code: yup
      .object()
      .nullable()
      .required("Select Company")
      .default(form.company_code),
    folio_number: yup
      .object()
      .nullable()
      .required("Select Folio Number")
      .default(form.folio_number),
    no_of_certificates: yup
      .string()
      .test(
        "check-val",
        "Enter At least two certificates to consolidate",
        (val) => parseInt(val) >= 2
      )
      .required("Enter No of Certificates")
      .default(
        IsJsonString(form.input_certificates)
          ? JSON.parse(form.input_certificates).length
          : 0
      ),
    new_certificate_no: yup
      .string()
      .required("Enter Certificate Number")
      .default(
        IsJsonString(form.output_certificates) &&
          JSON.parse(form.output_certificates)[0].certificate_no
      ),
    remarks: yup.string().default(form?.remarks),
  });
