import * as yup from "yup";
import { IsJsonString } from "../../utilities/utilityFunctions";
export const addDuplicateCertificatesSchema = yup.object().shape({
  request_date: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  execution_date: yup.string(),
  requester_folio: yup.object().nullable().required("Select Folio"),
  company_code: yup.object().nullable().required("Select Company"),
  // folio_number: yup.object().nullable().required("Select Folio Number"),
  no_of_certificates: yup.string().required("Enter Certificate Number"),
  remarks: yup.string(),
});

export const updateDuplicateCertificatesSchema = (form) =>
  yup.object().shape({
    request_date: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(form.request_date),
    execution_date: yup.string().test("test-date", "Enter Date", (value) => {
      return value;
    }),
    requester_folio: yup
      .object()
      .required("Select Folio")
      .default(form.folio_number),
    company_code: yup
      .object()
      .required("Select Company")
      .default(form.company_code),
    // folio_number: yup.object().required("Select Folio Number"),
    no_of_certificates: yup
      .string()
      .required("Enter Certificate Number")
      .default(
        IsJsonString(form.input_certificates)
          ? JSON.parse(form.input_certificates).length
          : 0
      ),
    remarks: yup.string().default(form?.remarks),
  });
