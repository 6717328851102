import axios from "axios";
import Config from "../../config";

const getTransactionTypes = async (email) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/transactiontypes/config?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const updateTransactionStatus = async (
  email,
  txn_id,
  processing_status,
  check_list
) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/transactions/update-status?email=${email}`;
  const result = await axios.post(
    url,
    {
      email,
      txn_id,
      processing_status,
      check_list,
    },
    {
      headers: { Authorization: token },
    }
  );
  return result;
};

const getTransactionRequestByStatus = async (email, status) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/transactions/by-status?email=${email}&processing_status=${status}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const getTransactionRequestByType = async (email, type) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/transactions/by-type?email=${email}&txn_type=${type}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const addNewTransactionType = async (
  email,
  txn_type,
  transaction_type,
  active
) => {
  const url = `${Config.baseUrl}/transactiontypes/`;
  const result = await axios.post(
    url,
    {
      email,
      txn_type,
      transaction_type,
      active,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const updateNewTransactionType = async (
  email,
  txn_type_id,
  txn_type,
  transaction_type,
  active
) => {
  const url = `${Config.baseUrl}/transactiontypes/update`;

  const result = await axios.post(
    url,
    {
      email,
      txn_type_id,
      txn_type,
      transaction_type,
      active,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const getTransactions = async (email) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/transactions?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const addNewTransaction = async (
  email,
  request_id,
  announcement_id,
  entitlement_id,
  folio_number,
  txn_type,
  symbol,
  quantity,
  from_folio,
  to_folio,
  txn_date,
  settlement_date,
  company_code,
  price,
  amount,
  reference
) => {
  const url = `${Config.baseUrl}/transactions/`;

  const result = await axios.post(
    url,
    {
      email,
      request_id,
      announcement_id,
      entitlement_id,
      folio_number,
      txn_type,
      symbol,
      quantity,
      from_folio,
      to_folio,
      txn_date,
      settlement_date,
      company_code,
      price,
      amount,
      reference,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const updateNewTransaction = async (
  email,
  txn_id,
  request_id,
  announcement_id,
  entitlement_id,
  folio_number,
  txn_type,
  symbol,
  quantity,
  from_folio,
  to_folio,
  txn_date,
  settlement_date,
  company_code,
  price,
  amount,
  reference
) => {
  const url = `${Config.baseUrl}/transactions/update`;

  const result = await axios.post(
    url,
    {
      email,
      txn_id,
      request_id,
      announcement_id,
      entitlement_id,
      folio_number,
      txn_type,
      symbol,
      quantity,
      from_folio,
      to_folio,
      txn_date,
      settlement_date,
      company_code,
      price,
      amount,
      reference,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

export {
  getTransactionTypes,
  addNewTransactionType,
  getTransactions,
  addNewTransaction,
  updateNewTransactionType,
  updateNewTransaction,
  getTransactionRequestByStatus,
  updateTransactionStatus,
  getTransactionRequestByType,
};
