import React, { useState, useEffect } from "react";
import logo from "../assets/images/shareholder-app.svg";
import macslogo from "../assets/images/macs-core-logo.png";
import man from "../assets/images/dashboard/user.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, withRouter } from "react-router";
import {
  firebase_app,
  googleProvider,
  facebookProvider,
  twitterProvider,
  githubProvider,
  Jwt_token,
} from "../data/config";
import { handleResponse } from "../services/fack.backend";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Login,
  LOGIN,
  YourName,
  Password,
  RememberMe,
  LoginWithAuth0,
  LoginWithJWT,
} from "../constant";
import {
  getUserConfirmation,
  loginService,
  sendVerificationCode,
  verifyVerificationCode,
} from "../store/services/auth.service";
import { NavLink } from "react-router-dom";

const config = require("../data/app_config.json");
const SignUp = ({ history }) => {
  const { loginWithRedirect } = useAuth0();
  const [cnic, setCNIC] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [verified, setVerified] = useState(false);
  const [userExist, setUserExist] = useState(false);

  const [Loading, setLoading] = useState(false);

  // const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [value, setValue] = useState(sessionStorage.getItem("profileURL" || man));

  // errors
  const [cnicError, setcnicError] = useState(false);
  const [phonenumberError, setphonenumberError] = useState(false);
  const [codeError, setcodeError] = useState(false);

  useEffect(() => {
    // if (value !== null) localStorage.setItem("profileURL", value);
    if (value !== null) sessionStorage.setItem("profileURL", value);
    // else localStorage.setItem("profileURL", man);
    else sessionStorage.setItem("profileURL", man);
  }, [value]);

  const getConfirmation = async (e) => {
    if (cnic == "") {
      setcnicError(true);
    } else {
      setcnicError(false);
    }
    if (phoneNumber == "") {
      setphonenumberError(true);
    } else {
      setphonenumberError(false);
    }
    if (code == "") {
      setcodeError(true);
    } else {
      setcodeError(false);
    }
    if (cnic !== "" && phoneNumber === "" && code === "") {
      try {
        setLoading(true);
        const response = await getUserConfirmation(cnic);
        if (response.status === 200) {
          setUserExist(response.data.exists === "true");
          if (response.data.exists === "true") {
            toast.success("Enter Phone Number to get Verification Code");
          }
        } else {
          toast.error("Oppss.. The cnic number is invalid .");
        }
        setLoading(false);
      } catch (error) {
        setTimeout(() => {
          toast.error("Oppss.. The cnic number is invalid.");
        }, 200);
        setLoading(false);
      }
    }
    if (cnic !== "" && phoneNumber !== "" && code === "") {
      try {
        setLoading(true);
        const response = await sendVerificationCode(cnic, phoneNumber);
        if (response.status === 200) {
          if (response.data.message === "pending") {
            toast.success(
              "Verification code has been sent to your phone number"
            );
            setVerified(true);
          } else {
            toast.error("Error While Sending Verification Code");
          }
        } else {
          toast.error("Oppss.. The cnic or phone number  is invalid .");
        }
        setLoading(false);
      } catch (error) {
        setTimeout(() => {
          toast.error("Oppss.. The cnic or phone number is invalid.");
        }, 200);
        setLoading(false);
      }
    }
    if (cnic !== "" && phoneNumber !== "" && code !== "") {
      try {
        setLoading(true);
        const response = await verifyVerificationCode(phoneNumber, code);
        if (response.status === 200) {
          if (response.data.message === "approved") {
            toast.success(
              "Verification code verified! Setup your account password"
            );
            history.push({
              pathname: `${process.env.PUBLIC_URL}/password-confirmation`,
              state: {
                cnic: cnic,
                phone: phoneNumber,
              },
            });
          } else if (response.data.message === "disapproved") {
            toast.error("Incorrect verification code enter again");
          } else {
            toast.error("Error While Verifying Verification Code");
          }
        } else {
          toast.error("Oppss.. The code or phone number  is invalid .");
        }
        setLoading(false);
      } catch (error) {
        setTimeout(() => {
          toast.error("Oppss.. The code or phone number is invalid.");
        }, 200);
        setLoading(false);
      }
    }
  };

  const loginWithJwt = (email, password) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { email, password },
    };

    return fetch("/users/authenticate", requestOptions)
      .then(handleResponse)
      .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        setValue(man);
        // localStorage.setItem("token", user);
        sessionStorage.setItem("token", user);
        window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`;
        return user;
      });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={macslogo} width="130" alt="" />
                      <div className="text-center"></div>
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <img src={logo} alt="" className="py-3" width="250" />
                          {/* <h4>Share Registrar</h4> */}
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label pt-0">
                              Enter CNIC/NTN
                            </label>
                            <input
                              className="form-control"
                              type="cnic"
                              name="cnic"
                              value={cnic}
                              onChange={(e) => setCNIC(e.target.value)}
                              placeholder="Enter CNIC/NTN"
                            />
                            {cnicError && (
                              <p className="error-color">
                                * CNIC/NTN is required
                              </p>
                            )}
                          </div>
                          {userExist && (
                            <div className="form-group">
                              <label className="col-form-label">
                                Phone Number
                              </label>
                              <input
                                className="form-control"
                                type="phonenumber"
                                name="phonenumber"
                                placeholder="Phone number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                              {phonenumberError && (
                                <p className="error-color">
                                  * Phone number is required
                                </p>
                              )}
                            </div>
                          )}
                          {verified && (
                            <div className="form-group">
                              <label className="col-form-label">
                                Verification Code
                              </label>
                              <input
                                className="form-control"
                                type="verificationcode"
                                name="verificationcode"
                                placeholder="Enter Code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                              />
                              {codeError && (
                                <p className="error-color">
                                  * Verification Code is required
                                </p>
                              )}
                            </div>
                          )}

                          <div className="form-group form-row mt-3 mb-0">
                            {/* <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={() => loginAuth()}
                            >
                              {Login}
                            </button> */}
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={(e) => getConfirmation(e)}
                              disabled={Boolean(Loading)}
                            >
                              {Loading ? (
                                <>
                                  <span className="fa fa-spinner fa-spin"></span>
                                  <span> Loading...</span>
                                </>
                              ) : (
                                <span> Next</span>
                              )}
                            </button>
                          </div>

                          <hr style={{ width: "60%" }} />
                          {/* <p
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push(
                                `${process.env.PUBLIC_URL}/forget-password`
                              );
                            }}
                          >
                            Forget Password
                          </p> */}
                          {/* <div
                            className="text-center opacity-50"
                            style={{ color: "grey" }}
                          >
                            <h6>Version 0.3</h6>
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignUp);
