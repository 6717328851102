import * as yup from "yup";
import { IsJsonString } from "../../utilities/utilityFunctions";

export const addTransmissionOfSharesSchema = yup.object().shape({
  request_date: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  execution_date: yup.string(),
  company_code: yup.object().nullable().required("Select Company"),
  name: yup.string(),
  folio_no: yup.object().nullable().required("Select Folio Number"),
  certificates: yup
    .string()
    .test(
      "check-val",
      "Certificates should be at least two",
      (val) => parseInt(val) >= 1
    )
    .required("Enter Certificates "),
  remarks: yup.string(),
});

export const updateTransmissionOfSharesSchema = (form) =>
  yup.object().shape({
    request_date: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(form?.request_date),
    execution_date: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(form?.execution_date),
    company_code: yup
      .object()
      .required("Select Company")
      .default(form?.company_code),
    name: yup.string(),
    folio_no: yup
      .object()
      .required("Select Folio Number")
      .default(form?.folio_number),
    certificates: yup
      .string()
      .test("check-val", "Split into at least two", (val) => parseInt(val) >= 1)
      .required("Enter Split Parts")
      .default(JSON.parse(form?.input_certificates).length),
    remarks: yup.string().default(form?.remarks),
  });
