import axios from "axios";
import Config from "../../config";
import RefreshTokenHandler from "./refresh-token";

export const getCurrentOfferingsAndSubscriptions = async (email, cnic) => {
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/publicoffering/active-public-offerings-currently?email=${email}&cnic=${cnic}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getCurrentOfferingsAndSubscriptions(email, cnic);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

export const getInvestorDetails = async (email, cnic, type) => {
  const token = JSON.parse(sessionStorage.getItem("token") || "");
  try {
    const url = `${Config.baseUrl}/investors/investorDetails?email=${email}&cnic=${cnic}&type=${type}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getInvestorDetails(email, cnic, type);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

export const addPublicSubscription = async (
  email,
  subscription_id,
  offer_id,
  company_code,
  symbol,
  investor_id,
  folio_number,
  investor_name,
  market,
  subscription_date,
  cnic,
  ntn,
  passport,
  investor_email,
  subscribed_through,
  price,
  shares_subscribed,
  shares_alloted,
  balance_shares,
  amount_payable,
  amount_paid,
  amount_retained,
  amount_refund,
  amount_refunded,
  instrument_type,
  instrument_number,
  bank,
  bank_code,
  branch,
  branch_code,
  payment_date,
  payment_status,
  payment_evidence,
  payment_verified,
  payment_method,
  payment_from,
  payment_bank,
  payment_bank_code,
  payment_branch,
  payment_branch_code,
  payment_account_no,
  payment_iban,
  payment_account_title,
  verified_by,
  verified_date,
  status,
  created_by,
  last_updated_by,
  bank_account_no,
  bank_account_iban,
  application_no,
  broker_code,
  father_husband_name,
  category,
  cdc_account_no,
  cdc_account_type,
  cdc_participant_id,
  cdc_complete_investor_id,
  investor_type,
  cdc_sub_account,
  public_offering_number,
  paid,
  payment_no
) => {
  try {
    const url = `${Config.baseUrl}/publicsubscription/`;

    const result = await axios.post(
      url,
      {
        email,
        subscription_id,
        offer_id,
        company_code,
        symbol,
        investor_id,
        folio_number,
        investor_name,
        market,
        subscription_date,
        cnic,
        ntn,
        passport,
        investor_email,
        subscribed_through,
        price,
        shares_subscribed,
        shares_alloted,
        balance_shares,
        amount_payable,
        amount_paid,
        amount_retained,
        amount_refund,
        amount_refunded,
        instrument_type,
        instrument_number,
        bank,
        bank_code,
        branch,
        branch_code,
        payment_date,
        payment_status,
        payment_evidence,
        payment_verified,
        payment_method,
        payment_from,
        payment_bank,
        payment_bank_code,
        payment_branch,
        payment_branch_code,
        payment_account_no,
        payment_iban,
        payment_account_title,
        verified_by,
        verified_date,
        status,
        created_by,
        last_updated_by,
        bank_account_no,
        bank_account_iban,
        application_no,
        broker_code,
        father_husband_name,
        category,
        cdc_account_no,
        cdc_account_type,
        cdc_participant_id,
        cdc_complete_investor_id,
        investor_type,
        cdc_sub_account,
        public_offering_number,
        paid,
        payment_no,
      },
      {
        headers: {
          Authorization: JSON.parse(sessionStorage.getItem("token")) || "",
        },
      }
    );

    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await addPublicSubscription(
          email,
          subscription_id,
          offer_id,
          company_code,
          symbol,
          investor_id,
          folio_number,
          investor_name,
          market,
          subscription_date,
          cnic,
          ntn,
          passport,
          investor_email,
          subscribed_through,
          price,
          shares_subscribed,
          shares_alloted,
          balance_shares,
          amount_payable,
          amount_paid,
          amount_retained,
          amount_refund,
          amount_refunded,
          instrument_type,
          instrument_number,
          bank,
          bank_code,
          branch,
          branch_code,
          payment_date,
          payment_status,
          payment_evidence,
          payment_verified,
          payment_method,
          payment_from,
          payment_bank,
          payment_bank_code,
          payment_branch,
          payment_branch_code,
          payment_account_no,
          payment_iban,
          payment_account_title,
          verified_by,
          verified_date,
          status,
          created_by,
          last_updated_by,
          bank_account_no,
          bank_account_iban,
          application_no,
          broker_code,
          father_husband_name,
          category,
          cdc_account_no,
          cdc_account_type,
          cdc_participant_id,
          cdc_complete_investor_id,
          investor_type,
          cdc_sub_account,
          public_offering_number,
          paid,
          payment_no
        );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

export const updatePublicSubscription = async (
  // email,
  // subscription_id,
  // offer_id,
  // company_code,
  // symbol,
  // investor_id,
  // folio_number,
  // investor_name,
  // market,
  // subscription_date,
  // cnic,
  // ntn,
  // passport,
  // investor_email,
  // subscribed_through,
  // price,
  // shares_subscribed,
  // shares_alloted,
  // balance_shares,
  // amount_payable,
  // amount_paid,
  // amount_retained,
  // amount_refund,
  // amount_refunded,
  // instrument_type,
  // instrument_number,
  // bank,
  // bank_code,
  // branch,
  // branch_code,
  // payment_date,
  // payment_status,
  // payment_evidence,
  // payment_verified,
  // payment_method,
  // payment_from,
  // payment_bank,
  // payment_bank_code,
  // payment_branch,
  // payment_branch_code,
  // payment_account_no,
  // payment_iban,
  // payment_account_title,
  // verified_by,
  // verified_date,
  // status,
  // created_by,
  // last_updated_by,
  // bank_account_no,
  // bank_account_iban,
  // application_no,
  // broker_code,
  // father_husband_name,
  // category,
  // cdc_account_no,
  // cdc_account_type,
  // cdc_participant_id,
  // cdc_complete_investor_id,
  // investor_type,
  // cdc_sub_account,
  // public_offering_number,
  // paid,
  // payment_no
  item
) => {
  try {
    const url = `${Config.baseUrl}/publicsubscription/update`;

    const result = await axios.post(
      url,
      // {
      //   email,
      //   subscription_id,
      //   offer_id,
      //   company_code,
      //   symbol,
      //   investor_id,
      //   folio_number,
      //   investor_name,
      //   market,
      //   subscription_date,
      //   cnic,
      //   ntn,
      //   passport,
      //   investor_email,
      //   subscribed_through,
      //   price,
      //   shares_subscribed,
      //   shares_alloted,
      //   balance_shares,
      //   amount_payable,
      //   amount_paid,
      //   amount_retained,
      //   amount_refund,
      //   amount_refunded,
      //   instrument_type,
      //   instrument_number,
      //   bank,
      //   bank_code,
      //   branch,
      //   branch_code,
      //   payment_date,
      //   payment_status,
      //   payment_evidence,
      //   payment_verified,
      //   payment_method,
      //   payment_from,
      //   payment_bank,
      //   payment_bank_code,
      //   payment_branch,
      //   payment_branch_code,
      //   payment_account_no,
      //   payment_iban,
      //   payment_account_title,
      //   verified_by,
      //   verified_date,
      //   status,
      //   created_by,
      //   last_updated_by,
      //   bank_account_no,
      //   bank_account_iban,
      //   application_no,
      //   broker_code,
      //   father_husband_name,
      //   category,
      //   cdc_account_no,
      //   cdc_account_type,
      //   cdc_participant_id,
      //   cdc_complete_investor_id,
      //   investor_type,
      //   cdc_sub_account,
      //   public_offering_number,
      //   paid,
      //   payment_no,
      // },
      item,
      {
        headers: {
          Authorization: JSON.parse(sessionStorage.getItem("token")) || "",
        },
      }
    );
    return result;
  } catch (err) {
    if (err.response.data.status == 401 || err.response.status == 403) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updatePublicSubscription(item);
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};
