import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import DCCLogo from "../../../assets/DCC-Logo.svg";
import logo from "../../../assets/images/shareholder-app.svg";
import { AiOutlineLeft } from "react-icons/ai";
import moment from "moment"
import "./evotingStyle.scss";


import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Logout } from "./logout";
// import { useState } from 'react';
export const SpecialResolutionResultPage = () => {
  // const getEvotingData = JSON.parse(localStorage.getItem("evotingData"));
  const getEvotingData = JSON.parse(sessionStorage.getItem("evotingData"));
  // const getTempEmail = JSON.parse(localStorage.getItem("email"));
  const getTempEmail = JSON.parse(sessionStorage.getItem("email"));
  const [termCondition, setTermCondition] = useState(false);
  const [viewFlag,setViewFlag]=useState(false)
  const [viewAgendaData,setViewAgendaData]=useState({})
  const history = useHistory();
  return (
    <div className="evotingfontfamily"  style={{ padding: "20px" }}>
      <MainWrapper className="row mt-4 ">
        <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
          <div className="card pb-5 " style={{ border: "1px solid #70ace7" }}>
            <Wrapper className="card-header " style={{ borderBottom: "none" }}>
            <div className='d-flex' style={{justifyContent:'space-between'}}>
            <AiOutlineLeft className='mt-3' size={30} style={{ color: '#677ecc', cursor: 'pointer' }} 
            onClick={() => {
                  history.push(`${process.env.PUBLIC_URL}/evoting/home`);
                }}/>
              <Logout />
            </div>
              
              <div className="responsivepaddingpage">
              <div className="text-center mt-3">
                <img
                  src={getEvotingData.logo ? getEvotingData.logo : require("../../../assets/images/defaultImg.png")}
                  alt=""
                  
                 maxWidth={"220px"}
                  height={"130px"}
                />
              </div>
              <div
                className="mt-3"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "20px",
                }}
              >
                eVoting on
              </div>
              </div>
            </Wrapper>
            <Modal isOpen={viewFlag} show={viewFlag.toString()} size="xs">
        <ModalHeader
          toggle={() => {
            setViewFlag(false);
          }}
        >
         Agenda Details
        </ModalHeader>
        <ModalBody>
          <p style={{
                   
                    color: "rgb(152, 161, 181)",
                    textAlign: "center",
                  }}>Agenda Title</p>
                  <div style={{
                          border: "1px solid rgb(152, 161, 181)",
                          margin: "auto",
                          margin: "3px",
                        }}>
                    <p style={{
                   
                   color: "#000000",
                   textAlign: "center",
                 }}>{viewAgendaData.agenda_title}</p>
                  </div>
                  <p style={{
                   
                   color: "rgb(152, 161, 181)",
                   textAlign: "center",
                 }}>Agenda Item</p>
                 <div style={{
                         border: "1px solid rgb(152, 161, 181)",
                         margin: "auto",
                         margin: "3px",
                       }}>
                   <p style={{
                  
                  color: "#000000",
                  textAlign: "center",
                }}>{viewAgendaData.agenda_item}</p>
                 </div>
                 {viewAgendaData.attachments && JSON.parse(viewAgendaData.attachments) && JSON.parse(viewAgendaData.attachments)?.data && <div>
                  <p style={{
                   
                   color: "rgb(152, 161, 181)",
                   textAlign: "center",
                 }}>Attachments</p>
                 <div style={{
                         border: "1px solid rgb(152, 161, 181)",
                         margin: "auto",
                         margin: "3px",
                       }}>
                   <p style={{
                  
                  color: "#000000",
                  textAlign: "center",
                }}>{JSON.parse(viewAgendaData.attachments).file_name}</p>
                 </div>
                 </div>}
        </ModalBody>
      </Modal>
      

            <div
              className=" mt-4"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <div>
                <div
                  style={{
                    color: "#447bc9",
                    fontSize: "23px",
                    textAlign: "center",
                  }}
                >
                  Special Resolution
                </div>
                <p
                  style={{
                    fontSize: "16px",
                    color: "rgb(152, 161, 181)",
                    textAlign: "center",
                  }}
                  class="card-text"
                >
                  {moment(getEvotingData?.data1[0]?.agenda_from).format("DD-MMM-YYYY h:mmA")} <br/> TO<br/>
                  {moment(getEvotingData?.data1[0]?.agenda_to).format("DD-MMM-YYYY h:mmA")}
                </p>

                {getEvotingData?.data1.map((item, index) => {
                  return (
                    <div style={{ textAlign: "center", marginBottom: "5px" }}>
                      <div style={{ color: "rgb(152, 161, 181)" }}>
                        Special Resolution {index + 1}{" "}
                      </div>
                      <div
                        style={{
                          border: "1px solid rgb(152, 161, 181)",
                          margin: "auto",
                          margin: "3px",
                          padding: '5px 40px'
                        }}
                      >
                        <div style={{ color: "#000000" }}>
                          {item.agenda_title}
                        </div>
                        <div style={{color:"#5b9bd5", cursor: 'pointer'} } onClick={()=>{
                            //  window.location.replace(
                            //     `${process.env.PUBLIC_URL}/special-voting-result`
                            // );
                             history.push(`${process.env.PUBLIC_URL}/special-voting-result`, {data: item})
                        //   setViewAgendaData(item)
                        //   setViewFlag(true)
                        }}>View</div>
                      </div>
                    </div>
                  );
                })}

                {/* <div style={{ marginTop: "110px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      style={{ transform: "scale(2)" }}
                      onChange={(e) => {
                        setTermCondition(e?.target?.checked);
                      }}
                    />
                    <div style={{ fontSize: "20px" }}> I agree to the</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#677ecc",
                      fontSize: "20px",
                    }}
                  >
                    Terms and Conditions
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "20px",
                    }}
                  >
                    of the eVoting
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="btn mt-2"
                    type="button"
                    style={{
                      color: "#ffff",
                      background: "#5b9bd5",
                      minWidth: "140px",
                    }}
                    disabled={!termCondition}
                    onClick={() => {
                      history.push(`${process.env.PUBLIC_URL}/resolution-voting`);
                    }}
                  >
                    Enter
                  </button>
                </div> */}
              </div>

              <div></div>
            </div>
            {/* <a style={{display: 'flex', justifyContent: 'center' }} className='mt-5'> Past Voting</a> */}
          </div>
        </PaddingWrapper>
      </MainWrapper>
    </div>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepage {
      ${'' /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivepage {
      display: flex;
      justify-content: center;
      ${'' /* width: 50%;
      margin:auto; */}
    }
  }
`;

const PaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepaddingpage {
      ${'' /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivepaddingpage {
      ${'' /* display: flex;
      justify-content: center; */}
      padding: 0px 100px;
      min-width: 550px;
      max-width: 550px;
      ${'' /* width: 50%;
      margin:auto; */}
    }
  }
`;


const Wrapper = styled.div`
  @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;
