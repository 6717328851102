// change with your own variables

// // Migration DEV
// export const env = {
//   REACT_APP_TITLE: "D-Shares Registry DEV",
//   REACT_APP_VERSION: "v2.6.2",
//   REACT_APP_BASE_URL: "https://sr-dev.dccl.com.pk:3000/api",
//   REACT_APP_SOCKET_URL: "https://sr-dev.dccl.com.pk:3000/",
//   REACT_APP_IMAGE_URL: "https://sr-dev.dccl.com.pk:3000/",
//   REACT_APP_AUTH_URL: "https://dccl.pk:3011/",
//   REACT_APP_BACKEND_URL: "https://sr-dev.dccl.com.pk:3000/",
// };

// TEST
export const env = {
  REACT_APP_TITLE: JSON.parse(localStorage.getItem("env")).REACT_APP_TITLE,
  REACT_APP_VERSION: JSON.parse(localStorage.getItem("env")).REACT_APP_VERSION,
  REACT_APP_BASE_URL: JSON.parse(localStorage.getItem("env"))
    .REACT_APP_BASE_URL,
  REACT_APP_SOCKET_URL: JSON.parse(localStorage.getItem("env"))
    .REACT_APP_SOCKET_URL,
  REACT_APP_IMAGE_URL: JSON.parse(localStorage.getItem("env"))
    .REACT_APP_IMAGE_URL,
  REACT_APP_AUTH_URL: JSON.parse(localStorage.getItem("env"))
    .REACT_APP_AUTH_URL,
  REACT_APP_BACKEND_URL: JSON.parse(localStorage.getItem("env"))
    .APP_BACKEND_URL,
  REACT_APP_IPO_OFFERED: JSON.parse(localStorage.getItem("env"))
    .REACT_APP_IPO_OFFERED,
};

// New UAT
// export const env = {
//   REACT_APP_TITLE: "D-Shares Registry UAT",
//   REACT_APP_VERSION: "v2.6.2",
//   REACT_APP_BASE_URL: "http://203.128.8.2:3224/api",
//   REACT_APP_SOCKET_URL: "http://203.128.8.2:3224/",
//   REACT_APP_IMAGE_URL: "http://203.128.8.2:3224/",
//   REACT_APP_AUTH_URL: "http://203.128.8.2:4011/",
//   REACT_APP_BACKEND_URL: "http://203.128.8.2:3224/",
// };

//Eth Production USE
// export const env = {
//   REACT_APP_TITLE: "D-Shares Registry PROD",
//   REACT_APP_VERSION: "v2.6.0",
//   REACT_APP_BASE_URL: "https://dr.dccl.com.pk:3012/api",
//   REACT_APP_SOCKET_URL: "https://dr.dccl.com.pk:3012/",
//   REACT_APP_IMAGE_URL: "https://dr.dccl.com.pk:3012/",
//   REACT_APP_AUTH_URL: "https://digitalcustodian.co:3011/",
// };

// Eth Production Backup

// export const env = {
//   REACT_APP_TITLE: "D-Shares Registry PROD",
//   REACT_APP_VERSION: "v2.6.0",
//   REACT_APP_BASE_URL: "https://dr-prod.dccl.tech:3004/api",
//   REACT_APP_SOCKET_URL: "https://dr-prod.dccl.tech:3004/",
//   REACT_APP_IMAGE_URL: "https://dr-prod.dccl.tech:3004/",
//   REACT_APP_AUTH_URL: "https://digitalcustodian.co:3011/",
// };
