import * as yup from "yup";

export const addShareCertificateSchema = yup.object().shape({
  certificate_from: yup.string().required("Enter From"),
  certificate_to: yup.string().required("Enter To"),
  type: yup.string().required("Select Type"),
  issue_date: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  allotted_to: yup.object().required("Select Folio Number"),
  company_code: yup.object().required("Select Company"),
});
