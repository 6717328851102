import React, { useState, useEffect } from "react";
import logo from "../assets/essos/logo.png";
import man from "../assets/images/dashboard/user.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import {
  firebase_app,
  googleProvider,
  facebookProvider,
  twitterProvider,
  githubProvider,
  Jwt_token,
} from "../data/config";
import { handleResponse } from "../services/fack.backend";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Login,
  LOGIN,
  YourName,
  Password,
  RememberMe,
  LoginWithAuth0,
  LoginWithJWT,
} from "../constant";
import { loginService } from "../store/services/auth.service";
const config = require("../data/app_config.json");

const ForgetPassword = ({ history }) => {
  const { loginWithRedirect } = useAuth0();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toggle, setToggle] = useState(true);

  // const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [value, setValue] = useState(
    sessionStorage.getItem("profileURL" || man)
  );

  useEffect(() => {
    // if (value !== null) localStorage.setItem("profileURL", value);
    if (value !== null) sessionStorage.setItem("profileURL", value);
    // else localStorage.setItem("profileURL", man);
    else sessionStorage.setItem("profileURL", man);
  }, [value]);

  const handleSendEmail = () => {
    setToggle(false);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          {toggle ? (
            <div className="authentication-main">
              <div className="row">
                <div className="col-md-12">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <div className="text-center">
                        {/* <img src={logo} alt="" /> */}
                      </div>
                      <div className="card mt-4">
                        <div className="card-body">
                          <div className="text-center">
                            {/* <img src={logo} alt="" width="100" /> */}
                            <h4>Share Registrar</h4>
                            <h6>{"Forget Password"} </h6>
                          </div>
                          <form className="theme-form">
                            <div className="form-group">
                              <label className="col-form-label pt-0">
                                Enter Email
                              </label>
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email address"
                              />
                            </div>

                            <div className="form-group form-row mt-3 mb-0">
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                                onClick={handleSendEmail}
                              >
                                Recover Password
                              </button>
                              <button
                                className="btn btn-dark btn-block"
                                type="button"
                                onClick={() => {
                                  history.push(`/login`);
                                }}
                              >
                                Cancel
                              </button>
                            </div>

                            <div
                              className="login-divider"
                              style={{ visibility: "hidden" }}
                            ></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="authentication-main">
              <div className="row">
                <div className="col-md-12">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <div className="text-center">
                        {/* <img src={logo} alt="" /> */}
                      </div>
                      <div className="card mt-4">
                        <div className="card-body">
                          <div className="text-center">
                            <h4>Share Registrar</h4>
                            <h6>{"OTP Varification"} </h6>
                          </div>
                          <form className="theme-form">
                            <div className="form-group">
                              <label className="col-form-label pt-0">
                                OTP Code
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="otpCode"
                                placeholder="OTP Code"
                              />
                            </div>
                            <div className="form-group">
                              <label className="col-form-label pt-0">
                                Password
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Enter Password"
                              />
                            </div>
                            <div className="form-group">
                              <label className="col-form-label pt-0">
                                Confirm Password
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Enter Confirm Password"
                              />
                            </div>

                            <div className="form-group form-row mt-3 mb-0">
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                              >
                                Confirm
                              </button>
                              <button
                                className="btn btn-dark btn-block"
                                type="button"
                                onClick={() => {
                                  setToggle(true);
                                }}
                              >
                                Cancel
                              </button>
                            </div>

                            <div
                              className="login-divider"
                              style={{ visibility: "hidden" }}
                            ></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ForgetPassword);
