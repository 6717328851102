import axios from "axios";
import Config from "../../config";

const getShareCounter = async (email, companyCode) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/companies/share-counter?email=${email}&company_code=${companyCode}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
const uploadShareCertificate = async (email, company_code, data) => {
  const url = `${Config.baseUrl}/sharecertificates/upload-certificates`;

  const result = await axios.post(
    url,
    {
      email,
      company_code,
      data,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};
const getShareCertificates = async (email) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/sharecertificates?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
const getShareCertificatesByNumber = async (email, certificate_no) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/sharecertificates/by-certificate-no?email=${email}&certificate_no=${certificate_no}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
const getShareCertificatesByFolio = async (email, folio_number) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/sharecertificates/by-folio?email=${email}&folio_number=${folio_number}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
const getShareCertificatesByCompany = async (email, company_code) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/sharecertificates/by-company?email=${email}&company_code=${company_code}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
const sendIssuanceCertificate = async (
  email,
  certificate_no_from,
  certificate_no_to,
  type,
  issue_date,
  total_shares_count,
  certificates,
  allotted_to,
  company_code
) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/sharecertificates/issue?email=${email}`;
  const result = await axios.post(
    url,
    {
      email,
      certificate_no_from,
      certificate_no_to,
      type,
      issue_date,
      total_shares_count,
      certificates,
      allotted_to,
      company_code,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

export {
  getShareCounter,
  getShareCertificates,
  sendIssuanceCertificate,
  getShareCertificatesByFolio,
  getShareCertificatesByNumber,
  uploadShareCertificate,
  getShareCertificatesByCompany,
};
