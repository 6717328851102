import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import DCCLogo from "../../../assets/DCC-Logo.svg";
import logo from "../../../assets/images/shareholder-app.svg";
import { AiOutlineLeft } from "react-icons/ai";
import moment from "moment";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./evotingStyle.scss";
import { Logout } from "./logout";
import { saveAs } from "file-saver";
import DOMPurify from "dompurify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import { useState } from 'react';
export const SpecialResolutionElectionVoting = () => {
  const isSmallScreen = window.matchMedia("(max-width: 500px)").matches;
  const getEvotingData = JSON.parse(sessionStorage.getItem("evotingData"));
  // const getEvotingData = JSON.parse(localStorage.getItem("evotingData"));
  const getTempEmail = JSON.parse(sessionStorage.getItem("email"));
  // const getTempEmail = JSON.parse(localStorage.getItem("email"));
  const [termCondition, setTermCondition] = useState(false);
  let parseData;
  try {
    parseData = JSON.parse(getEvotingData?.data?.attachments || "");
  } catch (error) {
    // Handle the error here
    console.error("Error parsing JSON data:", error);
  }
  const [file, setFile] = useState(parseData === "_" ? "" : parseData || "");
  const [fileName, setFileName] = useState(
    parseData?.name === "_" ? "" : parseData?.name || ""
  );
  const [file1, setFile1] = useState(
    parseData?.content === "_" ? "" : parseData?.content || ""
  );
  const [file64, setFile64] = useState(
    parseData?.content === "_" ? "" : parseData?.content || ""
  );
  const [fileData, setFileData] = useState(
    parseData?.content === "_" ? "" : parseData?.content || ""
  );
  const [viewModalPicture, setViewModalPicture] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [viewFlag, setViewFlag] = useState(false);
  const [viewAgendaData, setViewAgendaData] = useState({});
  const history = useHistory();
  const handleViewImage = (pathVal) => {
    setImagePath(pathVal);
    setViewModalPicture(true);
  };

  function removeLinks(htmlString) {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html");

    // Select all <a> elements
    const links = html.querySelectorAll("a");

    // Loop over all <a> elements and replace them with their text content
    for (let link of links) {
      link.outerHTML = link.textContent;
    }

    return html.body.innerHTML;
  }

  return (
    <div className="evotingfontfamily" style={{ padding: "20px" }}>
      <Modal
        isOpen={viewModalPicture}
        show={viewModalPicture.toString()}
        size="md"
      >
        <ModalHeader
          toggle={() => {
            setViewModalPicture(false);
            setImagePath("");
          }}
        >
          View
        </ModalHeader>
        <ModalBody>
          <img
            style={{ maxWidth: "100%", height: "auto" }}
            src={imagePath}
            alt="image_of_shareholder"
          />
        </ModalBody>
      </Modal>
      <MainWrapper className="row mt-4 ">
        <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
          <div className="card pb-5 " style={{ border: "1px solid #70ace7" }}>
            <Wrapper className="card-header " style={{ borderBottom: "none" }}>
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <AiOutlineLeft
                  className="mt-3"
                  size={30}
                  style={{ color: "#677ecc", cursor: "pointer" }}
                  onClick={() => {
                    history.replace(`${process.env.PUBLIC_URL}/evoting/home`);
                  }}
                />
                <Logout />
              </div>

              <div className="responsivepaddingpage">
                <div className="text-center mt-3">
                  <img
                    src={
                      getEvotingData.logo
                        ? getEvotingData.logo
                        : require("../../../assets/images/defaultImg.png")
                    }
                    alt=""
                    width={"auto"}
                    maxWidth={"220px"}
                    height={"130px"}
                    style={{
                      maxWidth: "220px",
                      maxHeight: "130px",
                      width: "auto",
                      height: "auto",
                    }}
                  />
                </div>
                <div
                  className="mt-3"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    fontSize: "20px",
                  }}
                >
                  eVoting on
                </div>
              </div>
            </Wrapper>
            <Modal isOpen={viewFlag} show={viewFlag.toString()} size="xs">
              <ModalHeader
                toggle={() => {
                  setViewFlag(false);
                }}
              >
                Agenda Details
              </ModalHeader>
              <ModalBody>
                <p
                  style={{
                    color: "rgb(152, 161, 181)",
                    textAlign: "center",
                  }}
                >
                  Agenda Title
                </p>
                <div
                  style={{
                    border: "1px solid rgb(152, 161, 181)",
                    margin: "auto",
                    margin: "3px",
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  {/* <p style={{
                   
                   color: "#000000",
                   textAlign: "center",
                 }}> */}
                  {viewAgendaData.agenda_title}
                  {/* </p> */}
                </div>
                <p
                  style={{
                    color: "rgb(152, 161, 181)",
                    textAlign: "center",
                  }}
                >
                  Agenda Item
                </p>
                <div
                  style={{
                    border: "1px solid rgb(152, 161, 181)",
                    margin: "3px",
                    // width: '450px',
                    overflowX: "auto",
                    // overflow-y: hidden;
                    whiteSpace: "nowrap",
                  }}
                >
                  <div style={{ width: "600px" }}>
                    <ReactQuill
                      disabled={true}
                      placeholder="Enter Agenda Details"
                      value={removeLinks(
                        viewAgendaData.agenda_item &&
                          viewAgendaData.agenda_item
                            .replaceAll("<u>", "")
                            .replaceAll("</u>", "")
                      )}
                      readOnly={true}
                      modules={{
                        toolbar: false,
                      }}
                    />
                  </div>
                </div>
                {/* {viewAgendaData?.data?.attachments && JSON.parse(viewAgendaData?.data?.attachments) && JSON.parse(viewAgendaData.attachments)?.data && <div> */}
                {file !== "" &&
                  file !== "_" &&
                  file !== undefined &&
                  file !== null &&
                  file !== "null" && (
                    <div>
                      <p
                        style={{
                          color: "rgb(152, 161, 181)",
                          textAlign: "center",
                        }}
                      >
                        Attachment
                      </p>
                      {/* <div style={{
                    border: "1px solid rgb(152, 161, 181)",
                    margin: "auto",
                    margin: "3px",
                  }}> */}
                      <p
                        style={{
                          color: "#000000",
                          textAlign: "center",
                        }}
                      >
                        <div>
                          {file !== "" &&
                            file !== "_" &&
                            file !== undefined &&
                            file !== null &&
                            file !== "null" && (
                              <div>
                                <p
                                  style={{
                                    color: "rgb(152, 161, 181)",
                                    textAlign: "center",
                                  }}
                                >
                                  Attachment
                                </p>
                                <div
                                  className="pdf-container"
                                  style={{
                                    width: "210mm", // A4 width in millimeters
                                    height: "297mm", // A4 height in millimeters
                                    margin: "auto",
                                    padding: "10px",
                                    overflow: "auto",
                                  }}
                                >
                                  {file !== "" &&
                                    file !== "_" &&
                                    file !== undefined &&
                                    file !== null &&
                                    file !== "null" && (
                                      <div className="viewImage">
                                        {file?.type?.includes("image") ||
                                        (file1 && file1?.includes("image")) ? (
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                            }}
                                          >
                                            <img
                                              width="200"
                                              src={
                                                !file64?.content
                                                  ? file64
                                                  : file64?.content
                                              }
                                              onClick={() =>
                                                handleViewImage(
                                                  !file64?.content
                                                    ? file64
                                                    : file64?.content
                                                )
                                              }
                                              alt="Attachment"
                                            />
                                          </div>
                                        ) : file?.type?.includes(
                                            "application/pdf"
                                          ) ||
                                          (file1 &&
                                            file1?.includes(
                                              "application/pdf"
                                            )) ? (
                                          <div
                                            style={{
                                              position: "relative",
                                              top: "-20px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                position: "relative",
                                                display: "inline-block",
                                                fontSize: "24px",
                                                color: "#E9ECEF",
                                              }}
                                            >
                                              <a
                                                href={`data:application/pdf;base64,${fileData}`}
                                                download={fileName}
                                              >
                                                <i
                                                  className="fa fa-download"
                                                  style={{ color: "#808080" }}
                                                  size="4x"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    const cleanedFileData =
                                                      fileData.replace(
                                                        /^data:application\/pdf;base64,/,
                                                        ""
                                                      );
                                                    const byteCharacters =
                                                      atob(cleanedFileData);
                                                    const byteNumbers =
                                                      new Array(
                                                        byteCharacters.length
                                                      );
                                                    for (
                                                      let i = 0;
                                                      i < byteCharacters.length;
                                                      i++
                                                    ) {
                                                      byteNumbers[i] =
                                                        byteCharacters.charCodeAt(
                                                          i
                                                        );
                                                    }
                                                    const byteArray =
                                                      new Uint8Array(
                                                        byteNumbers
                                                      );
                                                    const blob = new Blob(
                                                      [byteArray],
                                                      {
                                                        type: "application/pdf",
                                                      }
                                                    );
                                                    saveAs(blob, fileName);
                                                  }}
                                                ></i>
                                              </a>
                                            </div>
                                            {`  `}{" "}
                                            <p
                                              style={{
                                                color: "rgb(152, 161, 181)",
                                                textAlign: "center",
                                              }}
                                            >
                                              Download Resolution Details
                                            </p>
                                          </div>
                                        ) : (
                                          <p>Unsupported file type</p>
                                        )}
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                      </p>
                      {/* </div> */}
                    </div>
                  )}
              </ModalBody>
            </Modal>

            <div
              className=" mt-4"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <div>
                <div
                  style={{
                    color: "#447bc9",
                    fontSize: "23px",
                    textAlign: "center",
                  }}
                >
                  Special Resolution
                </div>
                <p
                  style={{
                    fontSize: "16px",
                    color: "rgb(152, 161, 181)",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: "300px",
                  }}
                  class="card-text"
                >
                  {moment(getEvotingData?.data?.agenda_from).format(
                    "DD-MMM-YYYY h:mmA"
                  )}{" "}
                  <br /> TO
                  <br />
                  {moment(getEvotingData?.data?.agenda_to).format(
                    "DD-MMM-YYYY h:mmA"
                  )}
                </p>
                <div style={{ maxWidth: "300px", textAlign: "center" }}>
                  <div>
                    {getEvotingData?.data.agendas &&
                      JSON.parse(getEvotingData?.data.agendas).map(
                        (item, index) => {
                          return (
                            <div
                              style={{
                                textAlign: "center",
                                marginBottom: "5px",
                              }}
                            >
                              <div style={{ color: "rgb(152, 161, 181)" }}>
                                Special Resolution {index + 1}{" "}
                              </div>
                              <div
                                style={{
                                  border: "1px solid rgb(152, 161, 181)",
                                  margin: "auto",
                                  margin: "3px",
                                  padding: "5px 40px",
                                }}
                              >
                                <div style={{ color: "#000000" }}>
                                  {item.agenda_title}
                                </div>
                                <div
                                  style={{
                                    color: "#5b9bd5",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setViewAgendaData(item);
                                    setViewFlag(true);
                                  }}
                                >
                                  View
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
                <div style={{ marginTop: "110px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      justifyContent: "center",

                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      style={{
                        transform: "scale(2)",
                        width: isSmallScreen ? "10px" : "11px",
                        height: isSmallScreen ? "10px" : "11px",
                      }}
                      onChange={(e) => {
                        setTermCondition(e?.target?.checked);
                      }}
                    />
                    <div style={{ fontSize: "20px" }}> I agree to the</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#677ecc",
                      fontSize: "20px",
                    }}
                  >
                    Terms and Conditions
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "20px",
                    }}
                  >
                    of the eVoting
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="btn mt-2"
                    type="button"
                    style={{
                      color: "#ffff",
                      background: "#5b9bd5",
                      minWidth: "140px",
                    }}
                    disabled={!termCondition}
                    onClick={() => {
                      history.push(
                        `${process.env.PUBLIC_URL}/resolution-voting`
                      );
                    }}
                  >
                    Enter
                  </button>
                </div>
              </div>

              <div></div>
            </div>
            {/* <a style={{display: 'flex', justifyContent: 'center' }} className='mt-5'> Past Voting</a> */}
          </div>
        </PaddingWrapper>
      </MainWrapper>
    </div>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepage {
      ${"" /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivepage {
      display: flex;
      justify-content: center;
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;

const PaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepaddingpage {
      ${"" /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivepaddingpage {
      ${
        "" /* display: flex;
      justify-content: center; */
      }
      padding: 0px 100px;
      min-width: 550px;
      max-width: 550px;
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;
