import axios from "axios";
import Config from "../../config";

const getDisburse = async (email) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/dividenddisbursements?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const addDisburse = async (
  email,

  disburse_date,
  folio_no,
  amount_disbursed,
  status
) => {
  const url = `${Config.baseUrl}/dividenddisbursements/`;

  const result = await axios.post(
    url,
    {
      email,
      // disburse_id,
      disburse_date,
      folio_no,
      amount_disbursed,
      status,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );

  return result;
};

const updateDisburse = async (
  email,
  disburse_id,
  disburse_date,
  folio_no,
  amount_disbursed,
  status
) => {
  const url = `${Config.baseUrl}/dividenddisbursements/update`;

  const result = await axios.post(
    url,
    {
      email,
      disburse_id,
      disburse_date,
      folio_no,
      amount_disbursed,
      status,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

export { getDisburse, addDisburse, updateDisburse };
