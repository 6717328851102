import * as yup from "yup";
import { IsJsonString } from "../../utilities/utilityFunctions";
export const addPhysicalToElectronicSchema = yup.object().shape({
  request_date: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  // execution_date: yup.string().test("test-date", "Enter Date", (value) => {
  //   return value;
  // }),
  requester_folio: yup.object().nullable().required("Select Folio"),
  company_code: yup.object().nullable().required("Select Company"),
  // Transfer Details
  no_of_certificates: yup.string().required("Enter Number of Certificate"),
  remarks: yup.string(),

  // price: yup.string(),
  // amount: yup.string(),
});
export const updatePhysicalToElectronicSchema = (form) =>
  yup.object().shape({
    request_date: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(form.request_date),
    requester_folio: yup
      .object()
      .required("Select Folio")
      .default(form.folio_number),
    company_code: yup
      .object()
      .required("Select Company")
      .default(form.company_code),
    // Transfer Details
    no_of_certificates: yup
      .string()
      .required("Enter Number of Certificate")
      .default(
        IsJsonString(form.input_certificates)
          ? JSON.parse(form.input_certificates).length
          : 0
      ),
    remarks: yup.string().default(form?.remarks),
    reference: yup.string().default(form?.reference),

    // price: yup.string(),
    // amount: yup.string(),
  });
