import axios from "axios";
import Config from "../../config";

export const loginService = async (investor_id, password) => {
  const url = `${Config.baseUrl}/user/login-shareholder`;
  const result = await axios.post(url, {
    investor_id,
    password,
  });
  return result;
};

export const getUserConfirmation = async (investor_id) => {
  const url = `${Config.baseUrl}/investors/do-exists?investor_id=${investor_id}`;
  const result = await axios.get(url);
  return result;
};

export const sendVerificationCode = async (investor_id, phone) => {
  const url = `${Config.baseUrl}/twilio/send-verification-code`;
  const result = await axios.post(url, {
    mobile: phone,
    cnic: investor_id,
  });
  return result;
};

export const verifyVerificationCode = async (phone, code) => {
  const url = `${Config.baseUrl}/twilio/verify-verification-code`;
  const result = await axios.post(url, {
    mobile: phone,
    code: code,
  });
  return result;
};

export const activateAccount = async (phone, cnic, password) => {
  const url = `${Config.baseUrl}/shareholderapp/activate`;
  const result = await axios.post(url, {
    mobile: phone,
    password: password,
    cnic: cnic,
  });
  return result;
};
