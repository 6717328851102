import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import {
  addNewTransaction,
  getTransactionTypes,
  updateTransactionStatus,
} from "../../../store/services/transaction.service";
import {
  WATCH_SHARE_CERTIFICATES_DROPDOWN,
  WATCH_SHARE_CERTIFICATES,
  WATCH_SHAREHOLDERS,
  WATCH_SHAREHOLDERS_DROPDOWN,
} from "../../../redux/actionTypes";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { darkStyle } from "../../defaultStyles";
import Select from "react-select";
import LoadableButton from "../../common/loadables";
import {
  txn_type_setter,
  request_id_setter,
  folio_setter,
  symbol_setter,
  announcement_id_setter,
  entitlement_id_setter,
  company_code_setter,
} from "../../../services/dropdown.service";
import { WATCH_TRANSACTION_REQUEST } from "../../../redux/actionTypes";

export default function ViewTransactionRequest({ setViewFlag }) {
  const baseEmail = sessionStorage.getItem("email") || "";
  const dispatch = useDispatch();
  const transactionRequest = JSON.parse(
    sessionStorage.getItem("selectedTransactionRequest")
  );
  const [checkList, setCheckList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCheckList, setShowCheckList] = useState(false);
  useEffect(() => {
    return () => {
      sessionStorage.setItem("selectedTransactionRequest", JSON.stringify({}));
    };
  }, []);

  useEffect(() => {
    if (showCheckList === true) {
      const getAllTransactionRequest = async () => {
        try {
          const response = await getTransactionTypes(baseEmail);
          if (response.status === 200) {
            setCheckList(
              response.data.data.find(
                (type) =>
                  type.transactionCode === transactionRequest.txn_type.value
              ).checklist
            );
          }
        } catch (error) {
          !!error?.response?.data?.message
            ? toast.error(error?.response?.data?.message)
            : toast.error("Transaction Types Not Found");
        }
      };
      getAllTransactionRequest();
    }
  }, [showCheckList]);
  const updateAcceptStatusAccepted = async () => {
    setLoading(true);
    try {
      const response = await updateTransactionStatus(
        baseEmail,
        transactionRequest.txn_id,
        "ACCEPTED",
        checkList
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        dispatch({ type: WATCH_SHARE_CERTIFICATES });
        dispatch({ type: WATCH_SHARE_CERTIFICATES_DROPDOWN });
        dispatch({ type: WATCH_SHAREHOLDERS });
        dispatch({ type: WATCH_SHAREHOLDERS_DROPDOWN });
        dispatch({ type: WATCH_TRANSACTION_REQUEST });
        setShowCheckList(false);
        setViewFlag(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      !!error?.response?.data?.message
        ? toast.error(error?.response?.data?.message)
        : toast.error("Transaction Status Not Submitted");
    }
  };
  const updateAcceptStatusRejected = async () => {
    setLoading(true);
    try {
      const response = await updateTransactionStatus(
        baseEmail,
        transactionRequest.txn_id,
        "REJECTED",
        checkList
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        dispatch({ type: WATCH_TRANSACTION_REQUEST });
        setShowCheckList(false);
        setViewFlag(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      !!error?.response?.data?.message
        ? toast.error(error?.response?.data?.message)
        : toast.error("Transaction Status Not Submitted");
    }
  };
  return (
    <div>
      {/* Add Modal */}
      <Modal isOpen={showCheckList} show={showCheckList.toString()} size="xl">
        <ModalHeader
          toggle={() => {
            setShowCheckList(false);
          }}
        >
          Transaction Check List
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="table-responsive">
              <table className="table  ">
                <thead>
                  <tr>
                    <th>Check List Item No.</th>
                    <th>Check List Item Description</th>
                    <th>Checked</th>
                  </tr>
                </thead>

                <tbody>
                  {checkList.length !== 0 &&
                    checkList.map((item, i) => (
                      <tr key={i}>
                        <td>{item.checklistItemNo}</td>
                        <td>
                          <label className="d-block" htmlFor="chk-ani">
                            <input
                              className="checkbox_animated"
                              id="chk-ani"
                              type="checkbox"
                              defaultChecked={item.checked}
                              onChange={(e) =>
                                (item.checked = e.target.checked)
                              }
                            />
                            Approve
                          </label>
                        </td>
                        <td>{item.checklistItemDescription}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row px-2">
            {JSON.parse(transactionRequest?.txn_flow)
              .find((item) => item.pointedTo === true)
              ?.labels?.map((tem, i) => (
                <button
                  className={`btn btn-${i === 0 ? "primary" : "danger"} mx-2`}
                  disabled={Boolean(loading)}
                  onClick={(e) =>
                    i === 0
                      ? updateAcceptStatusAccepted()
                      : updateAcceptStatusRejected()
                  }
                >
                  {loading ? (
                    <>
                      <span className="fa fa-spinner fa-spin"></span>
                      <span>{"Loading..."}</span>
                    </>
                  ) : (
                    <span>{tem}</span>
                  )}
                </button>
              ))}
            {/* <button
              className="btn btn-primary mx-2"
              disabled={Boolean(loading)}
              onClick={(e) => updateAcceptStatusAccepted()}
            >
              {loading ? (
                <>
                  <span className="fa fa-spinner fa-spin"></span>
                  <span>{"Loading..."}</span>
                </>
              ) : (
                <span>{"Accept"}</span>
              )}
            </button> */}
            {/* <button
              className="btn btn-danger mx-2"
              disabled={Boolean(loading)}
              onClick={(e) => updateAcceptStatusRejected()}
            >
              {loading ? (
                <>
                  <span className="fa fa-spinner fa-spin"></span>
                  <span>{"Loading..."}</span>
                </>
              ) : (
                <span>{"Reject"}</span>
              )}
            </button> */}
          </div>
        </ModalBody>
      </Modal>
      <Fragment>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4 ">
            <div className="card ">
              <div className="card-header b-t-primary">
                <h5>Add Transaction</h5>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="email">Transaction ID </label>
                  <input
                    type="text"
                    className="form-control"
                    value={transactionRequest.txn_id}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Request ID </label>
                  <input
                    type="text"
                    className="form-control"
                    value={transactionRequest.request_id}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Execution Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={transactionRequest.execution_date}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Announcement ID </label>
                  <Select
                    defaultValue={transactionRequest.announcement_id}
                    styles={darkStyle}
                    isDisabled={true}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Entitlement ID </label>
                  <Select
                    defaultValue={transactionRequest.entitlement_id}
                    styles={darkStyle}
                    isDisabled={true}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Txn Type </label>
                  <Select
                    defaultValue={transactionRequest.txn_type}
                    styles={darkStyle}
                    isDisabled={true}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Symbol</label>
                  <Select
                    defaultValue={transactionRequest.symbol}
                    styles={darkStyle}
                    isDisabled={true}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Company Code</label>
                  <Select
                    defaultValue={transactionRequest.company_code}
                    styles={darkStyle}
                    isDisabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 ">
            <div className="card ">
              <div className="card-header b-t-primary">
                {/* <h5>Add Transaction</h5> */}
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="email">Quantity</label>
                  <input
                    className="form-control"
                    id="companyEmail"
                    type="text"
                    placeholder="Enter Quantity"
                    value={transactionRequest.quantity}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Folio Number </label>
                  <Select
                    defaultValue={transactionRequest.folio_number}
                    styles={darkStyle}
                    isDisabled={true}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">From Folio</label>
                  <Select
                    defaultValue={transactionRequest.from_folio}
                    styles={darkStyle}
                    isDisabled={true}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Txn Date</label>
                  <input
                    className="form-control"
                    id="companyEmail"
                    type="date"
                    placeholder="Enter Txn Date"
                    value={transactionRequest.txn_date}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Settlement Date </label>
                  <input
                    className="form-control"
                    id="companyEmail"
                    type="date"
                    placeholder="Enter Settlement Date "
                    value={transactionRequest.settlement_date}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-header b-t-primary"></div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="price">Price</label>
                  <input
                    className="form-control"
                    id="price"
                    type="number"
                    min="0"
                    placeholder="Enter Price"
                    value={transactionRequest.price}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="amount">Amount</label>
                  <input
                    className="form-control"
                    min="0"
                    id="amount"
                    type="number"
                    placeholder="Enter Amount"
                    value={transactionRequest.amount}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="processing_status">
                    Associated Transaction
                  </label>
                  <input
                    className="form-control"
                    id="processing_status"
                    type="text"
                    placeholder="Status"
                    value={transactionRequest.associated_txn}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="processing_status">Processing Status</label>
                  <input
                    className="form-control"
                    id="processing_status"
                    type="text"
                    placeholder="Status"
                    value={transactionRequest.processing_status}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="reference">Reference</label>
                  <input
                    className="form-control"
                    id="reference"
                    type="text"
                    placeholder="Enter Reference"
                    value={transactionRequest?.reference}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="reference">Remarks</label>
                  <input
                    className="form-control"
                    id="reference"
                    type="text"
                    placeholder="Enter Reference"
                    value={transactionRequest?.remarks}
                    readOnly
                  />
                </div>

                {/* <div className="form-group">
                  <label htmlFor="associative">Associative Transaction</label>
                  <input
                    className="form-control"
                    id="associative"
                    type="text"
                    placeholder="Enter Associative Transaction" 
                    onChange={(e) => setAssociative_transaction(e.target.value)}
                  />
                  {associative_transactionError && (
                    <p className="error-color">* Associative Transaction is required</p>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-3">
          <div className="form-group">
            {transactionRequest.processing_status === "DISAPPROVED" ? (
              <button
                disabled
                style={{ cursor: "not-allowed" }}
                className="btn btn-danger"
              >
                Disapproved
              </button>
            ) : transactionRequest.processing_status === "APPROVED" ? (
              <button
                className="btn btn-success"
                style={{ cursor: "not-allowed" }}
              >
                Approved
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={(e) => setShowCheckList(true)}
              >
                Action
              </button>
            )}
          </div>
        </div>
      </Fragment>
    </div>
  );
}
