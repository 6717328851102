import React, { useState, useEffect } from "react";
import logos from "../assets/share-registrar.svg";
import DCCLogo from "../assets/DCC-Logo.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import { AiOutlineLeft } from "react-icons/ai";
import {
  investorLogin,
  investorRegister,
  verifyLogin,
} from "../store/services/evoting.service";
import InputMask from "react-input-mask";
import Config from "../config/index";
import Nav from "react-bootstrap/Nav";

const Signin = ({ history, location }) => {
  const [cnic, setCnic] = useState("");
  const [otp, setOTP] = useState("");
  const [isLoginPage, setIsLoginPage] = useState(true);
  const [Loading, setLoading] = useState(false);
  const [cnicError, setCnicError] = useState(false);
  const [evotinLoading, setEvotingLoading] = useState(false);
  const [otpLoading, setotpLoading] = useState(false);
  const [otpError, setOTPError] = useState(false);
  const [EvotingMethod, setEvotingMethd] = useState(true);
  const [otpMethod, setOtpMethod] = useState(false);
  const [userData, setUserData] = useState({});
  const [placeholder, setPlaceholder] = useState("cnic");
  const [maskType, setMaskType] = useState("cnic");
  const [selectedIdentification, setSelectedIdentification] = useState("cnic");
  //test
  const [inputValue, setInputValue] = useState("");
  const [tabDisabled, setTabDisabled] = useState(false);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [cdcParticipantID, setCdcParticipantId] = useState("");
  const [cdcParticipantIDArr, setCdcParticipantIDArr] = useState([]);
  const [emailError, setEmailError] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRadioChange = (e) => {
    setCnicError(false);
    setSelectedIdentification(e.target.value);
    setMaskType(e.target.value);
    setInputValue("");
  };
  const getMaskingType = () => {
    if (maskType === "cnic") {
      return "99999-9999999-9";
    } else if (maskType === "ntn") {
      return "999999999";
    } else if (maskType === "passport") {
      return "***********";
    }
    return;
  };
  const getPlaceholder = (e) => {
    if (selectedIdentification === "cnic") {
      return "Enter Your CNIC";
    } else if (selectedIdentification === "ntn") {
      return "Enter Your NTN";
    } else if (selectedIdentification === "passport") {
      return "Enter Your Passport#";
    }

    return;
  };

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const EvotingmethodCheck = async () => {
    setTabDisabled(true);
    if (!cnic) {
      setCnicError(true);
      setInputValue("");
      setTabDisabled(false);
      return;
    } else {
      setCnicError(false);
      setEvotingLoading(true);
      setOTP("");
      const trimmedCnic = inputValue.replaceAll("_", "").replaceAll("-", "");
      if (maskType === "cnic" && trimmedCnic.length != 13) {
        toast.error("CNIC should be 15 digits");
        setInputValue("");
        setEvotingLoading(false);
        setTabDisabled(false);

        return;
      }
      if (
        (maskType === "ntn" && trimmedCnic.length < 7) ||
        (maskType === "ntn" && trimmedCnic.length > 9)
      ) {
        toast.error("NTN should be between 7 to 9 digits");
        setInputValue("");
        setEvotingLoading(false);
        setTabDisabled(false);

        return;
      }
      if (
        (maskType === "passport" && trimmedCnic.length < 9) ||
        (maskType === "ntn" && trimmedCnic.length > 11)
      ) {
        toast.error("Passport # should be between 9 to 11 digits");
        setInputValue("");
        setEvotingLoading(false);
        setTabDisabled(false);

        return;
      }
      try {
        const response = await investorLogin(trimmedCnic.trim(), maskType);
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setUserData({
            email: response.data.email,
            mobile_no: response.data.mobile,
            cnic: trimmedCnic,
            type: maskType,
          });
          // localStorage.setItem(
          //   "user_data",
          //   JSON.stringify({
          //     email: response.data.email,
          //     mobile_no: response.data.mobile,
          //     cnic: trimmedCnic,
          //     type: maskType,
          //   })
          // );
          sessionStorage.setItem(
            "user_data",
            JSON.stringify({
              email: response.data.email,
              mobile_no: response.data.mobile,
              cnic: trimmedCnic,
              type: maskType,
            })
          );
          setCnic("");
          setOTP("");
          setCnicError(false);
          setotpLoading(false);
          // setUserData({});
          setPlaceholder("cnic");
          setMaskType("cnic");
          setSelectedIdentification("cnic");
          setInputValue("");
          setName("");
          setMobileNumber("");
          setUserEmail("");
          setCdcParticipantId("");
          setCdcParticipantIDArr([]);

          setIsLoginPage(true);
          setEvotingLoading(false);
          setEvotingMethd(false);
          setOtpMethod(true);
          setTabDisabled(false);
        } else {
          setEvotingLoading(false);
          setTabDisabled(false);

          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(
          "Network Error. Check your internet connection and try again!"
        );
        setEvotingLoading(false);
        setTabDisabled(false);
      }
    }
  };

  const RegistermethodCheck = async () => {
    setTabDisabled(true);
    //email validation to be added here
    if (
      (!userEmail || userEmail.trim() === "") &&
      (!cnic || cnic.trim() === "")
    ) {
      setCnicError(true);
      setTabDisabled(false);
      setEmailError(true);
      setTabDisabled(false);
      return;
    }

    if (!userEmail || userEmail.trim() === "") {
      setEmailError(true);
      // setInputValue("");
      setTabDisabled(false);
      return;
    }
    if (!cnic || cnic.trim() === "") {
      setCnicError(true);
      // setInputValue("");
      setTabDisabled(false);
      return;
    } else {
      setCnicError(false);
      setEvotingLoading(true);
      setOTP("");
      const trimmedCnic = inputValue.replaceAll("_", "").replaceAll("-", "");
      if (maskType === "cnic" && trimmedCnic.length != 13) {
        toast.error("CNIC should be 15 digits");
        setInputValue("");
        setEvotingLoading(false);
        setTabDisabled(false);

        return;
      }
      if (
        (maskType === "ntn" && trimmedCnic.length < 7) ||
        (maskType === "ntn" && trimmedCnic.length > 9)
      ) {
        toast.error("NTN should be between 7 to 9 digits");
        setInputValue("");
        setEvotingLoading(false);
        setTabDisabled(false);

        return;
      }
      if (
        (maskType === "passport" && trimmedCnic.length < 9) ||
        (maskType === "ntn" && trimmedCnic.length > 11)
      ) {
        toast.error("Passport # should be between 9 to 11 digits");
        setInputValue("");
        setEvotingLoading(false);
        setTabDisabled(false);

        return;
      }
      try {
        //register here params missing (email and cnic/ntn/passport are required fields)
        let paramObject = {
          cnic: "",
          ntn: "",
          passport_no: "",
          email: "",
          name: "",
          mobile: "",
          cdc_participant_id: [],
          portal: "App",
        };
        if (maskType === "cnic") {
          paramObject.cnic = cnic;
        }
        if (maskType === "ntn") {
          paramObject.ntn = cnic;
        }
        if (maskType === "passport") {
          paramObject.passport_no = cnic;
        }
        if (name && name.trim() !== "") {
          paramObject.name = name;
        }
        if (mobileNumber && mobileNumber.trim() !== "") {
          paramObject.mobile = mobileNumber;
        }
        if (userEmail && userEmail.trim() !== "") {
          paramObject.email = userEmail;
        }
        // if (cdcParticipantID && cdcParticipantID.trim() !== "") {
        //   paramObject.cdc_participant_id = [cdcParticipantID];
        // }

        const response = await investorRegister(paramObject);
        if (response.data.status == 200) {
          toast.success(response.data.message);
          EvotingmethodCheck();
          // setIsLoginPage(true);
          // setEvotingLoading(false);
          // setEvotingMethd(false);
          // setOtpMethod(true);
          // setTabDisabled(false);
          // setCnic("");
          // setOTP("");
          // setLoading(false);
          // setCnicError(false);
          // setEvotingLoading(false);
          // setotpLoading(false);
          // setOTPError(false);
          // setEvotingMethd(true);
          // setOtpMethod(false);
          // setUserData({});
          // setPlaceholder("cnic");
          // setMaskType("cnic");
          // setSelectedIdentification("cnic");
          // setInputValue("");
          // setName("");
          // setMobileNumber("");
          // setUserEmail("");
          // setCdcParticipantId("");
          // setCdcParticipantIDArr([]);
        } else {
          setEvotingLoading(false);
          setTabDisabled(false);

          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error.message);
        toast.error(
          "Network Error. Check your internet connection and try again!"
        );
        setEvotingLoading(false);
        setTabDisabled(false);
      }
    }
  };

  const LoginMEthod = async () => {
    if (!otp) {
      setOTPError(true);
      return;
    } else {
      setotpLoading(true);
      setOTPError(false);
      try {
        console.log("USER DATA => ", userData);
        const response = await verifyLogin(
          userData.email,
          otp.trim(),
          userData.mobile_no
        );
        if (response.data.status == 200) {
          // localStorage.setItem("email", JSON.stringify(response.data.email));
          sessionStorage.setItem("email", JSON.stringify(response.data.email));
          // localStorage.setItem("token", JSON.stringify(response.data.token));
          sessionStorage.setItem("token", JSON.stringify(response.data.token));
          // localStorage.setItem(
          //   "user_company",
          //   JSON.stringify(response.data.company_codes)
          // );
          sessionStorage.setItem(
            "user_company",
            JSON.stringify(response.data.company_codes)
          );
          // localStorage.setItem(
          //   "user_last_shareholder",
          //   JSON.stringify(response.data.sharehodlerDetails)
          // );
          // sessionStorage.setItem(
          //   "user_last_shareholder",
          //   JSON.stringify(response.data.sharehodlerDetails)
          // );
          // sessionStorage.setItem("email", JSON.stringify(response.data.email));
          // sessionStorage.setItem("token", JSON.stringify(response.data.token));
          // localStorage.setItem("login_date", response.data.date);
          sessionStorage.setItem("login_date", response.data.date);
          setotpLoading(false);
          setOtpMethod(false);
          setEvotingMethd(false);
          window.location.replace(`${process.env.PUBLIC_URL}/evoting/home`);
        } else {
          toast.error(response.data.message);
          setotpLoading(false);
        }
      } catch (error) {
        console.log(error.message);
        toast.error(
          "Network Error. Check your internet connection and try again!"
        );
        setotpLoading(false);
      }
    }

    // history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
  };

  return (
    <div>
      <ToastContainer />
      <div className="page-wrapper">
        <div className="authentication-main" style={{ padding: "5px 15px" }}>
          <div>
            <div className="auth-innerright">
              <div className="authentication-box">
                <div
                  className="card mt-4"
                  style={{ width: "100%", border: "1px solid #5B9BD5" }}
                >
                  {otpMethod && (
                    <AiOutlineLeft
                      className="mt-3 ml-3"
                      size={30}
                      style={{ color: "#677ecc", cursor: "pointer" }}
                      onClick={() => {
                        setEvotingMethd(true);
                        setOTPError(false);
                        setOtpMethod(false);
                      }}
                    />
                  )}
                  <div className="card-header">
                    <div className="text-center">
                      <img src={logos} alt="" className="py-3" width="220" />
                    </div>
                    {isLoginPage ? (
                      <div
                        className="text-center mt-4"
                        style={{ color: "silver" }}
                      >
                        <h5>Shareholder Login</h5>
                      </div>
                    ) : (
                      <div
                        className="text-center mt-4"
                        style={{ color: "silver" }}
                      >
                        <h5>New Investor</h5>
                      </div>
                    )}
                  </div>

                  {/* {!otpMethod && (
                    <Nav
                      fill
                      variant="tabs"
                      defaultActiveKey={isLoginPage ? "login" : "register"}
                      style={{ border: "none", display: "flex" }}
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="login"
                          style={{
                            color: isLoginPage ? "#fff" : "#000",
                            backgroundColor: isLoginPage
                              ? "#5B9BD5"
                              : "#ffffff",
                            borderColor: "#5B9BD5",
                            borderTop: "1px solid #5B9BD5",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px",
                            marginLeft: "15%",
                            padding: windowWidth <= "768" && "5% 0%",
                            marginBottom: "0",
                            height: "100%",
                          }}
                          onClick={() => {
                            if (!isLoginPage) {
                              setIsLoginPage(true);
                              setCnic("");
                              setOTP("");
                              setLoading(false);
                              setEmailError(false);
                              setCnicError(false);
                              setEvotingLoading(false);
                              setotpLoading(false);
                              setOTPError(false);
                              setEvotingMethd(true);
                              setOtpMethod(false);
                              setUserData({});
                              setPlaceholder("cnic");
                              setMaskType("cnic");
                              setSelectedIdentification("cnic");
                              setInputValue("");
                              setName("");
                              setMobileNumber("");
                              setUserEmail("");
                              setCdcParticipantId("");
                              setCdcParticipantIDArr([]);
                            }
                          }}
                          disabled={evotinLoading}
                        >
                          Existing Investor
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="register"
                          style={{
                            color: !isLoginPage ? "#fff" : "#000",
                            backgroundColor: !isLoginPage
                              ? "#5B9BD5"
                              : "#ffffff",
                            borderColor: "#5B9BD5",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px",
                            marginRight: "15%",
                            padding: windowWidth <= "768" && "5% 0%",
                            marginBottom: "0",
                            borderTop: "1px solid #5B9BD5",
                            height: "100%",
                          }}
                          onClick={() => {
                            if (isLoginPage) {
                              setIsLoginPage(false);
                              setCnic("");
                              setOTP("");
                              setLoading(false);
                              setEmailError(false);
                              setCnicError(false);
                              setEvotingLoading(false);
                              setotpLoading(false);
                              setOTPError(false);
                              setEvotingMethd(true);
                              setOtpMethod(false);
                              setUserData({});
                              setPlaceholder("cnic");
                              setMaskType("cnic");
                              setSelectedIdentification("cnic");
                              setInputValue("");
                              setName("");
                              setMobileNumber("");
                              setUserEmail("");
                              setCdcParticipantId("");
                              setCdcParticipantIDArr([]);
                            }
                          }}
                          disabled={evotinLoading}
                        >
                          New Investor
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  )} */}

                  <div
                    className="card-body"
                    style={
                      {
                        // border: "1px solid #5B9BD5",
                        // borderRadius: "5%",
                        // marginLeft: "3%",
                        // marginRight: "3%",
                      }
                    }
                  >
                    {isLoginPage && EvotingMethod ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          margin: "auto",
                          width: "100%",
                        }}
                      >
                        <form
                          className="theme-form pb-5"
                          style={{ width: "100%" }}
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <div className="form-group">
                            <label className="col-form-label pt-0 justify-content-center d-flex mb-2">
                              {getPlaceholder()}
                            </label>

                            <div className="d-flex justify-content-center">
                              <div style={{ display: "inline-block" }}>
                                <input
                                  style={{ margin: "3px" }}
                                  type="radio"
                                  id="cnic"
                                  name="identification"
                                  value="cnic"
                                  checked={selectedIdentification === "cnic"}
                                  onChange={handleRadioChange}
                                />
                                <label htmlFor="cnic">CNIC</label>
                              </div>
                              <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                <input
                                  style={{ margin: "3px" }}
                                  type="radio"
                                  id="ntn"
                                  name="identification"
                                  value="ntn"
                                  checked={selectedIdentification === "ntn"}
                                  onChange={handleRadioChange}
                                />
                                <label htmlFor="ntn">NTN</label>
                              </div>
                              {/* <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                <input
                                  style={{ margin: "3px" }}
                                  type="radio"
                                  id="passport"
                                  name="identification"
                                  value="passport"
                                  checked={
                                    selectedIdentification === "passport"
                                  }
                                  onChange={handleRadioChange}
                                />
                                <label htmlFor="passport">Passport #</label>
                              </div> */}
                            </div>
                            <div>
                              <InputMask
                                className="form-control"
                                type="text"
                                name="cnic"
                                value={inputValue}
                                mask={getMaskingType()}
                                disabled={Boolean(evotinLoading)}
                                formatChars={{
                                  9: "[0-9-]",
                                  A: "[a-zA-Z]",
                                  "*": "[a-zA-Z0-9]",
                                }}
                                onKeyPress={(e) => {
                                  let key_board_keycode = e.which || e.keyCode;
                                  if (
                                    key_board_keycode === 13 &&
                                    evotinLoading === false
                                  ) {
                                    e.preventDefault();
                                    setEvotingLoading(true);
                                    if (isLoginPage) {
                                      EvotingmethodCheck();
                                    } else {
                                      RegistermethodCheck();
                                    }
                                  }
                                }}
                                style={{
                                  width: "90%",
                                  margin: "auto",
                                  border: `${
                                    cnicError
                                      ? "1px solid red"
                                      : "1px solid #70ace7"
                                  }`,
                                  textAlign: "center",
                                }}
                                onChange={(e) => {
                                  setCnicError(false);
                                  setCnic(e.target.value);
                                  setInputValue(e.target.value);
                                }}
                                placeholder={getPlaceholder()}
                              />
                            </div>
                            {cnicError && (
                              <p
                                className="error-color"
                                style={{ width: "90%", margin: "auto" }}
                              >
                                *{" "}
                                {maskType === "passport"
                                  ? "Passport #"
                                  : maskType.toUpperCase()}{" "}
                                is required
                              </p>
                            )}
                          </div>

                          <div className="form-group form-row mt-4 mb-0">
                            <button
                              className="btn"
                              type="button"
                              style={{
                                width: "90%",
                                margin: "auto",
                                color: "#ffff",
                                background: "#5B9BD5",
                              }}
                              onClick={() => EvotingmethodCheck()}
                              disabled={Boolean(evotinLoading)}
                            >
                              {evotinLoading ? (
                                <>
                                  <span className="fa fa-spinner fa-spin"></span>
                                  <span> Loading...</span>
                                </>
                              ) : (
                                <span> NEXT</span>
                              )}
                            </button>

                            {JSON.parse(localStorage.getItem("env"))
                              .REACT_APP_IPO_OFFERED && (
                              <button
                                className="btn text-primary"
                                type="button"
                                style={{
                                  width: "90%",
                                  margin: "auto",
                                  // color: "#000",
                                  background: "#ffffff",
                                  marginTop: "0.5rem",
                                }}
                                onClick={() => setIsLoginPage(!isLoginPage)}
                              >
                                <small>
                                  {" "}
                                  {isLoginPage
                                    ? "Want to Invest in IPO? Register Here!"
                                    : "Existing Shareholder? Login Here!"}
                                </small>{" "}
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    ) : null}

                    {isLoginPage && otpMethod ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          margin: "auto",
                        }}
                      >
                        <form
                          className="theme-form pb-5"
                          style={{ width: "100%" }}
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <div className="form-group">
                            <label className="col-form-label pt-0 justify-content-center d-flex mb-">
                              Enter OTP Authentication
                            </label>

                            <input
                              className="form-control"
                              value={otp ? otp : ""}
                              type="number"
                              onKeyPress={(e) => {
                                let key_board_keycode = e.which || e.keyCode;
                                if (
                                  key_board_keycode == 13 &&
                                  isCheckboxChecked
                                ) {
                                  e.preventDefault();

                                  LoginMEthod();
                                }
                              }}
                              style={{
                                width: "90%",
                                margin: "auto",
                                // border: "1px solid #70ace7",
                                textAlign: "center",
                                border: `${
                                  otpError
                                    ? "1px solid red"
                                    : "1px solid #70ace7"
                                }`,
                              }}
                              onChange={(e) => {
                                let text = e.target.value;
                                if (
                                  text.length == 0 ||
                                  (Number(text) >= 0 &&
                                    isNaN(text) == false &&
                                    Number.isInteger(Number(text)))
                                ) {
                                  setOTP(text?.replaceAll("-", ""));
                                }
                              }}
                              placeholder="Enter OTP"
                            />
                            {otpError && (
                              <p
                                className="error-color"
                                style={{ width: "90%", margin: "auto" }}
                              >
                                * OTP is required
                              </p>
                            )}
                          </div>

                          <div className="form-group form-row mt-3 mb-0">
                            <div className="container">
                              <div className="form-check m-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isCheckboxChecked}
                                  onChange={handleCheckboxChange}
                                  id="flexCheckDefault"
                                  style={{ cursor: "pointer" }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                  style={{ cursor: "pointer" }}
                                >
                                  I agree to the{" "}
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Terms and Conditions
                                  </a>{" "}
                                  of the eVoting
                                </label>
                              </div>
                            </div>

                            <button
                              className="btn"
                              type="button"
                              style={{
                                width: "90%",
                                margin: "auto",
                                color: "#fff",
                                background: "#5B9BD5",
                              }}
                              onClick={LoginMEthod}
                              disabled={
                                !isCheckboxChecked || !otp || otp.trim() === ""
                              }
                            >
                              LOGIN
                            </button>
                          </div>
                        </form>
                      </div>
                    ) : null}

                    {!isLoginPage ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          margin: "auto",
                          width: "100%",
                        }}
                      >
                        <form
                          className="theme-form pb-5"
                          style={{ width: "100%" }}
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <div className="form-group">
                            {/* <div className="d-flex justify-content-center">
                              <div style={{ display: "inline-block" }}>
                                <input
                                  style={{ margin: "3px" }}
                                  type="radio"
                                  id="cnic"
                                  name="identification"
                                  value="cnic"
                                  checked={selectedIdentification === "cnic"}
                                  onChange={handleRadioChange}
                                />
                                <label htmlFor="cnic">CNIC</label>
                              </div>
                              <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                <input
                                  style={{ margin: "3px" }}
                                  type="radio"
                                  id="ntn"
                                  name="identification"
                                  value="ntn"
                                  checked={selectedIdentification === "ntn"}
                                  onChange={handleRadioChange}
                                />
                                <label htmlFor="ntn">NTN</label>
                              </div>
                              <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                <input
                                  style={{ margin: "3px" }}
                                  type="radio"
                                  id="passport"
                                  name="identification"
                                  value="passport"
                                  checked={
                                    selectedIdentification === "passport"
                                  }
                                  onChange={handleRadioChange}
                                />
                                <label htmlFor="passport">Passport #</label>
                              </div>
                            </div> */}
                            <div>
                              <InputMask
                                className="form-control"
                                type="text"
                                name="cnic"
                                value={inputValue}
                                mask={getMaskingType()}
                                disabled={Boolean(evotinLoading)}
                                formatChars={{
                                  9: "[0-9-]",
                                  A: "[a-zA-Z]",
                                  "*": "[a-zA-Z0-9]",
                                }}
                                onKeyPress={(e) => {
                                  let key_board_keycode = e.which || e.keyCode;
                                  if (
                                    key_board_keycode === 13 &&
                                    evotinLoading === false
                                  ) {
                                    e.preventDefault();
                                    setEvotingLoading(true);
                                    if (isLoginPage) {
                                      EvotingmethodCheck();
                                    } else {
                                      RegistermethodCheck();
                                    }
                                  }
                                }}
                                style={{
                                  width: "90%",
                                  margin: "auto",
                                  border: `${
                                    cnicError
                                      ? "1px solid red"
                                      : "1px solid #70ace7"
                                  }`,
                                  textAlign: "center",
                                }}
                                onChange={(e) => {
                                  setCnicError(false);
                                  setCnic(e.target.value);
                                  setInputValue(e.target.value);
                                }}
                                placeholder={getPlaceholder()}
                              />
                            </div>
                            {cnicError && (
                              <p
                                className="error-color"
                                style={{ width: "90%", margin: "auto" }}
                              >
                                *{" "}
                                {maskType === "passport"
                                  ? "Passport #"
                                  : maskType.toUpperCase()}{" "}
                                is required
                              </p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="d-flex justify-content-center">
                              <div style={{ width: "90%" }}>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="name"
                                  name="name"
                                  value={name}
                                  style={{
                                    margin: "auto",
                                    border: "1px solid #70ace7",
                                    textAlign: "center",
                                  }}
                                  placeholder="Name"
                                  onChange={(e) => {
                                    setName(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="d-flex justify-content-center">
                              <div style={{ width: "90%" }}>
                                <input
                                  className="form-control"
                                  type="email"
                                  id="userEmail"
                                  name="userEmail"
                                  value={userEmail}
                                  style={{
                                    margin: "auto",
                                    marginBottom: "0px",
                                    border: `${
                                      emailError
                                        ? "1px solid red"
                                        : "1px solid #70ace7"
                                    }`,
                                    textAlign: "center",
                                  }}
                                  placeholder="Email Address"
                                  onFocus={() => {
                                    setEmailError(false);
                                  }}
                                  onChange={(e) => {
                                    setEmailError(false);

                                    setUserEmail(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            {emailError && (
                              <p
                                className="error-color"
                                style={{
                                  width: "90%",
                                  marginTop: "0px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  marginBottom: "0px",
                                }}
                              >
                                *Email is required
                              </p>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="d-flex justify-content-center">
                              <div style={{ width: "90%" }}>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="mobile"
                                  name="mobile"
                                  value={mobileNumber}
                                  style={{
                                    margin: "auto",
                                    border: "1px solid #70ace7",
                                    textAlign: "center",
                                  }}
                                  placeholder="Mobile Number"
                                  onKeyDown={(e) => {
                                    if (
                                      !(
                                        e.key === "+" &&
                                        e.target.selectionStart === 0
                                      ) &&
                                      !/^\d$/.test(e.key) &&
                                      e.key !== "Backspace" &&
                                      e.key !== "Delete" &&
                                      e.key !== "ArrowLeft" &&
                                      e.key !== "ArrowRight" &&
                                      e.key !== "ArrowUp" &&
                                      e.key !== "ArrowDown"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    let { value } = e.target;
                                    const formattedValue = value.replace(
                                      /[^+\d]/g,
                                      ""
                                    );
                                    setMobileNumber(formattedValue);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div className="form-group">
                            <div className="d-flex justify-content-center">
                              <div style={{ width: "90%" }}>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="cdcParticipantID"
                                  name="cdcParticipantID"
                                  value={cdcParticipantID}
                                  style={{
                                    margin: "auto",
                                    border: "1px solid #70ace7",
                                    textAlign: "center",
                                  }}
                                  placeholder="CDC Participant ID"
                                  onChange={(e) => {
                                    if (/^[0-9-]*$/.test(e.target.value)) {
                                      setCdcParticipantId(e.target.value);
                                    } else {
                                      return;
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div> */}

                          <div className="form-group form-row mt-4 mb-0">
                            <button
                              className="btn"
                              type="button"
                              style={{
                                width: "90%",
                                margin: "auto",
                                color: "#ffff",
                                background: "#5B9BD5",
                              }}
                              onClick={() => RegistermethodCheck()}
                              disabled={Boolean(evotinLoading)}
                            >
                              {evotinLoading ? (
                                <>
                                  <span className="fa fa-spinner fa-spin"></span>
                                  <span> Loading...</span>
                                </>
                              ) : (
                                <span> Register</span>
                              )}
                            </button>

                            {JSON.parse(localStorage.getItem("env"))
                              ?.REACT_APP_IPO_OFFERED && (
                              <button
                                className="btn text-primary"
                                type="button"
                                style={{
                                  width: "90%",
                                  margin: "auto",
                                  // color: "#000",
                                  background: "#ffffff",
                                  marginTop: "0.2rem",
                                }}
                                onClick={() => setIsLoginPage(!isLoginPage)}
                              >
                                <small>
                                  {" "}
                                  {isLoginPage
                                    ? "Want to Invest in IPO? Register Here!"
                                    : "Existing Shareholder? Login Here!"}
                                </small>{" "}
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    ) : null}
                  </div>
                  <div className="card-footer">
                    <div className="text-center opacity-50 mt-3">
                      <span>Powered By</span>
                    </div>
                    <div className="text-center">
                      <img src={DCCLogo} width="100" alt="" />
                      <div className="text-center"></div>
                    </div>

                    <hr style={{ width: "60%" }} />
                    <div
                      className="text-center opacity-50"
                      style={{ color: "grey" }}
                    >
                      <h6>
                        {Config.appTitle} {Config.appVersion}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default withRouter(Signin);
