import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import DCCLogo from "../../../assets/DCC-Logo.svg";
import logo from "../../../assets/images/shareholder-app.svg";
import checkCircle from "../../../assets/images/circle-check.png";
import { AiOutlineLeft } from "react-icons/ai";
import { numberWithCommas } from "../../../utilities/utilityFunctions";
import NumberFormat from "react-number-format";
import {
  addDirectorElectionVoting,
  generateOTPByMobileAndEmail,
  getCandidateDatabyElectionID,
  getSharesByCNIC,
  verifyOTP,
} from "../../../store/services/evoting.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "./evotingStyle.scss";
import { Logout } from "./logout";
import { Slugger } from "marked";
import { id } from "date-fns/locale";
import { redo } from "easymde";

// import { useState } from 'react';
export const CanidateVoting = () => {
  // const getTempEmail = JSON.parse(localStorage.getItem("email"));
  const getTempEmail = JSON.parse(sessionStorage.getItem("email"));
  // const getEvotingData = JSON.parse(localStorage.getItem("evotingData"));
  const getEvotingData = JSON.parse(sessionStorage.getItem("evotingData"));
  // const getUserData = JSON.parse(localStorage.getItem("user_data"));
  const getUserData = JSON.parse(sessionStorage.getItem("user_data"));
  const [termCondition, setTermCondition] = useState(false);
  const [reviewVoting, setReviewVoting] = useState(false);
  const [confirmvoting, setConfirmVoting] = useState(false);
  const [totalShares, setTotalShares] = useState(0);
  const [shareHolderData, setShareHolderData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingCandidate, setLoadingCandidate] = useState(false);
  const [otp, setOTP] = useState("");
  const [femaleCandidate, setFemaleCandidate] = useState([]);
  const [independentCandidate, setIndependentCandidate] = useState([]);
  const [nonIndependentCandidate, setNonIndependentCandidate] = useState([]);

  const [remainingVoteForFemale, setRemainingVoteForFemale] = useState(0);
  const [remainingVoteForIndependent, setRemainingVoteForIndependent] =
    useState(0);
  const [remainingNonIndependent, setRemainingNonIndependent] = useState(0);

  let totalvotes = 0;
  let totalVotesForIndDir = 0;
  let totalVotesForNonIndDir = 0;
  let totalVotesForFemaleDir = 0;
  let preViewTotalVotes = 0;
  let preViewTotalVotesForFemaleDir = 0;
  let preViewTotalVotesForIndDir = 0;
  let preViewTotalVotesForNonIndDir = 0;
  let vote = 0;
  let numOfIndependentDirector = getEvotingData.no_of_independent_director;
  let numOfNonIndependentDirector =
    getEvotingData.no_of_non_independent_director;
  let numOfFemaleDirector = getEvotingData.no_of_female_director;
  let numforFemale = 0;
  let non_independent_director = [];
  let independent_director = [];
  let female_director = [];
  const femaleCandidates = [];
  //let [numforFemale, setNumforFemale] = useState(0)

  let [flag, setFlag] = useState({});
  let [flagFemale, setFemalFlag] = useState({});
  let [flagIndependent, setIndependentFlag] = useState({});
  let [flagNonIndependent, setNonIndependentflag] = useState({});
  let [dummyArr, setDummyArr] = useState([]);
  let [femaleDummyArr, seFemaletDummyArr] = useState([]);
  let [independentDummyArr, setIndependentDummyArr] = useState([]);
  let [nonIndependentDummyArr, setNonIndependentDummyArr] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  let reviewFemaleSection = false;
  let reviewIndependentSection = false;
  let reviewNonIndependentSection = false;

  const [candidateData, setCandidateData] = useState([]);
  const [generateOTPFlag, setGenerateOTPFlag] = useState(true);
  let backspacePress = false;
  const getShareHolder = JSON.parse(
    // localStorage.getItem("shareholdervotecast")
    sessionStorage.getItem("shareholdervotecast")
  );
  window.addEventListener("popstate", () => {
    confirmvoting && history.replace(`${process.env.PUBLIC_URL}/evoting/home`);
  });

  let votableShareFemaleCadidate =
    parseFloat(totalShares || "0") * parseFloat(numOfFemaleDirector || "0");
  let votableShareIndependentCadidate =
    parseFloat(totalShares || "0") *
    parseFloat(numOfIndependentDirector || "0");
  let votableShareNonIndependentCadidate =
    parseFloat(totalShares || "0") *
    parseFloat(numOfNonIndependentDirector || "0");
  let totalVotableShare =
    parseFloat(totalShares || "0") *
    parseFloat(getEvotingData?.number_of_directors || "0");

  let votesForAllCandidates = numberWithCommas(
    totalShares *
      parseFloat(
        numOfNonIndependentDirector +
          numOfFemaleDirector +
          numOfIndependentDirector || "0"
      )
  );
  let assignedVotesFemale = 0;
  let assignedVotesIndependent = 0;
  let assignedVotesNonIndependent = 0;

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  // useState(() => {
  //   // window.scrollTo({ top: 0, behavior: "smooth" });
  //   scrollToTop()
  // });

  useEffect(() => {
    const getCandidate = async () => {
      setLoadingCandidate(true);
      const response = await getCandidateDatabyElectionID(
        getTempEmail,
        getEvotingData?.election_id,
        getEvotingData?.company_code
      );
      if (response.data.status == 200) {
        response.data.data
          .filter((item) => item.revoked.toLowerCase() === "no")
          .map((item, key) => {
            inputValues.push({
              id: key,
              Name: item.candidate_name,
              value: "",
              category: item.category,
              candidate_id: item.candidate_id,
            });
          });
        inputValues
          .filter((item) => item.category === "non_independent_director")
          .map((item, key) => {
            non_independent_director.push({
              id: key,
              Name: item.Name,
              value: "",
              category: item.category,
              candidate_id: item.candidate_id,
            });
          });
        inputValues
          .filter((item) => item.category === "independent_director")
          .map((item, key) => {
            independent_director.push({
              id: key,
              Name: item.Name,
              value: "",
              category: item.category,
              candidate_id: item.candidate_id,
            });
          });
        inputValues
          .filter((item) => item.category === "female_director")
          .map((item, key) => {
            female_director.push({
              id: key,
              Name: item.Name,
              value: "",
              category: item.category,
              candidate_id: item.candidate_id,
            });
            // if (female_director.length === numOfFemaleDirector) {
            //   female_director.forEach((candidate) => {
            //     let votes = totalVotesForFemaleDir / female_director.length;
            //     console.log("votes", votes);
            //     candidate.value = "1200";
            //     // candidate.value = votes;
            //   });
            // }
          });
        setNonIndependentCandidate(non_independent_director);
        setIndependentCandidate(independent_director);
        setFemaleCandidate(female_director);
        setInputValues(inputValues);
        setCandidateData(response.data.data);
      }
    };
    const getShares = async () => {
      const response = await getSharesByCNIC(
        getTempEmail,
        getEvotingData?.company_code,
        getUserData?.cnic
      );
      if (response.data.status == 200) {
        let filter = response.data.data.filter((data) => {
          if (data.folio_number == getShareHolder) {
            return data;
          }
        });
        setShareHolderData(filter[0]);
        setTotalShares(
          parseFloat(filter[0].electronic_shares) +
            parseFloat(filter[0].physical_shares)
        );
        setLoadingCandidate(false);
      }
    };
    getCandidate();
    getShares();
  }, []);
  const handleInputFemaleDirectorChange = (id, event) => {
    const newInputValues = [...femaleCandidate];
    const index = newInputValues?.findIndex((item) => item?.id === id);
    newInputValues[index].value = event.target.value.startsWith("0")
      ? event.target.value.replaceAll("0", "")
      : event.target.value;
    setFemaleCandidate(newInputValues);
  };
  const handleInputIndependentDirectorChange = (id, event) => {
    const newInputValues = [...independentCandidate];
    const index = newInputValues?.findIndex((item) => item?.id === id);
    newInputValues[index].value = event.target.value.startsWith("0")
      ? event.target.value.replaceAll("0", "")
      : event.target.value;
    setIndependentCandidate(newInputValues);
  };
  const handleInputNonIndependentDirectorChange = (id, event) => {
    const newInputValues = [...nonIndependentCandidate];
    const index = newInputValues?.findIndex((item) => item?.id === id);
    newInputValues[index].value = event.target.value.startsWith("0")
      ? event.target.value.replaceAll("0", "")
      : event.target.value;
    setNonIndependentCandidate(newInputValues);
  };
  const history = useHistory();

  const handleVoting = () => {
    scrollToTop();
    // window.scrollTo({ top: 0, behavior: "smooth" });
    let castAbleVoteFemaleDirector = parseFloat(
      femaleCandidate?.reduce((a, b) => a + parseFloat(b.value || "0"), 0) ||
        "0"
    );
    let remainingFemaleVotes =
      totalShares * parseFloat(numOfFemaleDirector || "0") -
      castAbleVoteFemaleDirector;
    if (remainingFemaleVotes < 0) {
      // toast.error(
      //   "You cannot cast vote for Female Candidate greater than Total Votes"
      // );
      setRemainingVoteForFemale(true);
    } else if (remainingFemaleVotes > 0) {
      setFlag((flag = true));
      // toast.error(
      //   "You cannot cast vote for Female Candidate less than Total Votes"
      // );
      setRemainingVoteForFemale(true);
    }

    let castableVoteIndependent = parseFloat(
      independentCandidate?.reduce(
        (a, b) => a + parseFloat(b.value || "0"),
        0
      ) || "0"
    );
    let remainingIndependentVotes =
      totalShares * parseFloat(numOfIndependentDirector || "0") -
      castableVoteIndependent;
    if (remainingIndependentVotes < 0) {
      // toast.error(
      //   "You cannot cast vote for Independent Candidate greater than Total Votes"
      // );
      setRemainingVoteForIndependent(true);
    } else if (remainingIndependentVotes > 0) {
      // toast.error(
      //   "You cannot cast vote for Independent Candidate less than Total Votes"
      // );
      setIndependentFlag((flag = true));
      setRemainingVoteForIndependent(true);
    }

    let castableVoteNonIndependent = parseFloat(
      nonIndependentCandidate?.reduce(
        (a, b) => a + parseFloat(b.value || "0"),
        0
      ) || "0"
    );
    let remainingNonIndependentVotes =
      totalShares * parseFloat(numOfIndependentDirector || "0") -
      castableVoteNonIndependent;
    if (remainingNonIndependentVotes < 0) {
      // toast.error(
      //   "You cannot cast vote for Non Independent Candidate greater than Total Votes"
      // );
      setRemainingNonIndependent(true);
    } else if (remainingNonIndependentVotes > 0) {
      // toast.error(
      //   "You cannot cast vote for Non Independent Candidate less than Total Votes"
      // );
      setNonIndependentflag((flag = true));
      setRemainingNonIndependent(true);
    }
    if (
      totalVotesForNonIndDir ==
        totalShares * parseFloat(numOfNonIndependentDirector || "0") &&
      totalVotesForIndDir ==
        totalShares * parseFloat(numOfIndependentDirector || "0") &&
      totalVotesForFemaleDir ==
        totalShares * parseFloat(numOfFemaleDirector || "0")
    ) {
      setReviewVoting(true);
      setGenerateOTPFlag(true);
    } else {
      toast.error("Remaining votes should be 0");
    }
  };

  const handleHome = () => {
    history.push(`${process.env.PUBLIC_URL}/evoting/home`);
  };
  const handlePreViewVoting = async () => {
    let castAbleVoteFemaleDirector = parseFloat(
      femaleCandidate?.reduce((a, b) => a + parseFloat(b.value || "0"), 0) ||
        "0"
    );
    let castAbleVoteIndependentDirector = parseFloat(
      independentCandidate?.reduce(
        (a, b) => a + parseFloat(b.value || "0"),
        0
      ) || "0"
    );
    let castAbleVoteNonIndepenedentDirector = parseFloat(
      nonIndependentCandidate?.reduce(
        (a, b) => a + parseFloat(b.value || "0"),
        0
      ) || "0"
    );
    let castAbleVote =
      castAbleVoteFemaleDirector +
      castAbleVoteIndependentDirector +
      castAbleVoteNonIndepenedentDirector;

    setLoading(true);
    // const response = await verifyOTP(
    //   getUserData?.email,
    //   getUserData?.mobile_no,
    //   otp.trim()
    // );
    let totalCastVotes = [
      ...femaleCandidate,
      ...independentCandidate,
      ...nonIndependentCandidate,
    ];
    const votecasting = totalCastVotes?.map((item) => {
      let percent = 0;
      if (item.category === "female_director") {
        percent = item.value
          ? parseFloat(
              (item.value /
                (totalShares * parseFloat(numOfFemaleDirector || "0"))) *
                100
            ).toFixed(2)
          : parseFloat(0).toFixed(2);
      } else if (item.category === "independent_director") {
        percent = item.value
          ? parseFloat(
              (item.value /
                (totalShares * parseFloat(numOfIndependentDirector || "0"))) *
                100
            ).toFixed(2)
          : parseFloat(0).toFixed(2);
      } else {
        percent = item.value
          ? parseFloat(
              (item.value /
                (totalShares *
                  parseFloat(numOfNonIndependentDirector || "0"))) *
                100
            ).toFixed(2)
          : parseFloat(0).toFixed(2);
      }
      let conidate = "";
      if (item?.category === "female_director") {
        conidate = "female_candidate";
      } else if (item?.category === "independent_director") {
        conidate = "independent_candidate";
      } else {
        conidate = "non_independent_candidate";
      }
      return {
        candidate_id: item?.candidate_id,
        category: conidate,
        candidate_name: item?.Name,
        votes_casted: item?.value,
        votes_accepted:
          !item.value || item.value == 0
            ? 0
            : item.votes_rejected
            ? parseFloat(item?.value) - parseFloat(item?.votes_rejected || "0")
            : item?.value,
        votes_rejected:
          !item.value || item.value == 0
            ? 0
            : item.votes_rejected
            ? item.votes_rejected
            : "0",
        comments: item?.comments,
        voting_percentage: percent ? percent : "0",
      };
    });

    // let votecasting = [];
    // candidateData.map((item, key) => {
    //   let percent = inputValues[key].value
    //     ? parseFloat(
    //         (inputValues[key].value /
    //           (totalShares *
    //             parseFloat(getEvotingData?.number_of_directors || "0"))) *
    //           100
    //       ).toFixed(2)
    //     : parseFloat(0).toFixed(2);
    //   votecasting.push({
    //     candidate_id: item.candidate_id,
    //     category: item.category,
    //     candidate_name: item.candidate_name,
    //     votes_casted: inputValues[key].value ? inputValues[key].value : "0",
    //     voting_percentage: percent,
    //     votes_accepted: "",
    //     votes_rejected: "",
    //     comments: "",
    //   });
    // });

    const vote_response = await addDirectorElectionVoting(
      getTempEmail,
      "",
      "Electronic",
      "Mobile App",
      shareHolderData.folio_number,
      shareHolderData.folio_number,
      "",
      totalVotableShare.toString(),
      castAbleVote.toString(),
      "",
      votecasting,
      getEvotingData?.election_id,
      votableShareFemaleCadidate.toString(),
      votableShareIndependentCadidate.toString(),
      votableShareNonIndependentCadidate.toString(),
      castAbleVoteFemaleDirector.toString(),
      castAbleVoteIndependentDirector.toString(),
      castAbleVoteNonIndepenedentDirector.toString()
    );
    if (vote_response.data.status == 200) {
      setLoading(false);
      toast.success(vote_response.data.message);
      setReviewVoting(false);
      setConfirmVoting(true);
    } else {
      setLoading(false);
      toast.error(vote_response.data.message);
    }
  };
  const generateOTP = async () => {
    const response = await generateOTPByMobileAndEmail(
      getUserData?.email,
      getUserData?.mobile_no
    );
    if (response.data.status == 200) {
      toast.success(response.data.message);
      setGenerateOTPFlag(false);
    } else {
      toast.error(response.data.message);
    }
  };

  return (
    <>
      <ToastContainer />
      {confirmvoting ? (
        <div className="evotingfontfamily" style={{ padding: "20px" }}>
          <MainWrapper className="row mt-4">
            <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
              <div
                className="card pb-5"
                style={{ border: "1px solid #70ace7" }}
              >
                <Wrapper
                  className="card-header "
                  style={{ borderBottom: "none" }}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <AiOutlineLeft
                      className="mt-3"
                      size={30}
                      style={{ color: "#677ecc", cursor: "pointer" }}
                      onClick={() => {
                        handleHome();
                      }}
                    />
                    <Logout />
                  </div>

                  <div className="glyphicon glyphicon-chevron-left"></div>
                  <div className="text-center mt-1">
                    <img
                      src={
                        getEvotingData.logo
                          ? getEvotingData.logo
                          : require("../../../assets/images/defaultImg.png")
                      }
                      alt=""
                      maxWidth="220"
                      height={"130px"}
                      style={{
                        maxWidth: "220px",
                        maxHeight: "130px",
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      color: "#447bc9",
                      fontSize: "23px",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    Election of Directors
                  </div>
                  <p
                    style={{
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      color: "rgb(152, 161, 181)",
                      textAlign: "center",
                    }}
                    class="card-text"
                  >
                    {moment(getEvotingData?.election_from).format(
                      "DD-MMM-YYYY h:mmA"
                    )}{" "}
                    <br /> TO <br />{" "}
                    {moment(getEvotingData?.election_to).format(
                      "DD-MMM-YYYY h:mmA"
                    )}
                  </p>
                </Wrapper>
                <div className="responsivepaddingpage">
                  <div
                    className="card-body mt-4"
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <div>
                      <div className="text-center mt-1">
                        <img
                          src={checkCircle}
                          alt=""
                          width="130"
                          height={"130px"}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          color: "#00b050",
                          fontSize: "20px",
                        }}
                      >
                        {" "}
                        Vote Cast Successful
                      </div>

                      <div
                        className="mt-4"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {" "}
                        Your votes have been casted
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {" "}
                        successfully.
                        {/* Results will */}
                      </div>
                      {/* <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        appear in the app at the
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        specified time.
                      </div> */}

                      <button
                        className="btn mt-5"
                        type="button"
                        style={{
                          width: "100%",
                          color: "#ffff",
                          background: "#5B9BD5",
                        }}
                        onClick={() => {
                          handleHome();
                        }}
                      >
                        <span> HOME</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </PaddingWrapper>
          </MainWrapper>
        </div>
      ) : reviewVoting ? (
        <div className="evotingfontfamily" style={{ padding: "20px" }}>
          <MainWrapper className="row mt-4">
            <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage ">
              <div
                className="card pb-5"
                style={{ border: "1px solid #70ace7" }}
              >
                <Wrapper
                  className="card-header "
                  style={{ borderBottom: "none" }}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <AiOutlineLeft
                      className="mt-3"
                      size={30}
                      style={{ color: "#677ecc", cursor: "pointer" }}
                      onClick={() => {
                        setConfirmVoting(false);
                        setReviewVoting(false);
                      }}
                    />
                    <Logout />
                  </div>

                  <div className="text-center mt-1">
                    <img
                      src={
                        getEvotingData.logo
                          ? getEvotingData.logo
                          : require("../../../assets/images/defaultImg.png")
                      }
                      alt=""
                      maxWidth="220"
                      height={"130px"}
                      style={{
                        maxWidth: "220px",
                        maxHeight: "130px",
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      color: "#447bc9",
                      fontSize: "23px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Election of Directors
                  </div>
                  <p
                    style={{
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      color: "rgb(152, 161, 181)",
                      textAlign: "center",
                    }}
                    class="card-text"
                  >
                    {moment(getEvotingData?.election_from).format(
                      "DD-MMM-YYYY h:mmA"
                    )}{" "}
                    <br /> TO <br />{" "}
                    {moment(getEvotingData?.election_to).format(
                      "DD-MMM-YYYY h:mmA"
                    )}
                  </p>

                  <div
                    className="mt-3"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "20px",
                    }}
                  >
                    Review Your Votes
                  </div>
                </Wrapper>
                <div className="responsivepaddingpage">
                  <div
                    className="card-body"
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <div>
                      {femaleCandidate.length > numOfFemaleDirector && (
                        <div
                          className="mt-2 b-t-primary b-b-primary"
                          style={{ borderRadius: "10px" }}
                        >
                          <h6 className="mt-3 d-flex justify-content-center">
                            Female Candidates{" "}
                          </h6>

                          {femaleCandidate?.map((item, key) => {
                            const startDate = new Date(item?.startdate);
                            const endDate = new Date(item?.endDate);
                            preViewTotalVotesForFemaleDir =
                              preViewTotalVotesForFemaleDir +
                              (Number(item?.value) || 0);
                            preViewTotalVotes =
                              preViewTotalVotes + (Number(item?.value) || 0);
                            return (
                              <>
                                <div key={key} class="row no-gutters">
                                  <div class="col-md-12 mt-3">
                                    <div
                                      style={{
                                        maxWidth: "260px",
                                        textAlign: "center",
                                        fontSize: "18px",
                                        color: "#5B9BD5",
                                      }}
                                    >
                                      {/* <table>
                                  <tr>
                                    <td>{key +1 + " . "}</td>
                                    <td></td>
                                    <td>{item?.Name?.toUpperCase()}</td>
                                  </tr>
                                 </table> */}
                                      {key + 1}
                                      {". "}
                                      <span style={{ paddingLeft: "5px" }}>
                                        {" "}
                                        {item?.Name?.toUpperCase()}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        maxWidth: "260px",
                                        textAlign: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {numberWithCommas(item?.value) || 0}{" "}
                                      <span
                                        style={{
                                          fontSize: "14px",

                                          color: "rgb(152, 161, 181)",
                                        }}
                                      >
                                        votes
                                      </span>
                                    </div>
                                  </div>
                                  <hr style={{ width: "60%" }} />
                                </div>
                              </>
                            );
                          })}
                          <div
                            style={{
                              border: "1px solid #398a1b5",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              borderRadius: "5px",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  alignItems: "center",
                                  fontSize: "18px",
                                }}
                              >
                                Total Votes:{" "}
                                <span style={{ color: "#5B9BD5" }}>
                                  {" "}
                                  {numberWithCommas(
                                    totalShares *
                                      parseFloat(numOfFemaleDirector || "0")
                                  )}
                                </span>
                              </div>
                              <div
                                style={{
                                  alignItems: "center",
                                  fontSize: "18px",
                                }}
                              >
                                Votes Casted:{" "}
                                <span style={{ color: "#5B9BD5" }}>
                                  {numberWithCommas(
                                    preViewTotalVotesForFemaleDir
                                  )}
                                </span>
                              </div>
                              {/* <div
                                style={{
                                  alignItems: "center",
                                  fontSize: "18px",
                                }}
                              >
                                Remaining Votes:{" "}
                                <span style={{ color: "#5B9BD5" }}>
                                  {" "}
                                  {numberWithCommas(
                                    totalShares *
                                      parseFloat(numOfFemaleDirector || "0") -
                                      preViewTotalVotesForFemaleDir
                                  )}
                                </span>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                      {femaleCandidate.length === numOfFemaleDirector &&
                        femaleCandidate.forEach((candidate) => {
                          totalVotesForFemaleDir =
                            totalShares *
                            parseFloat(numOfFemaleDirector || "0");
                          assignedVotesFemale =
                            totalShares *
                            parseFloat(numOfFemaleDirector || "0");
                          candidate.value = totalShares;
                        })}

                      {independentCandidate.length >
                        numOfIndependentDirector && (
                        <div
                          className="mt-2 b-t-primary b-b-primary"
                          style={{ borderRadius: "10px" }}
                        >
                          <h6 className="mt-3 d-flex justify-content-center">
                            Independent Candidates{" "}
                          </h6>

                          {independentCandidate?.map((item, key) => {
                            const startDate = new Date(item?.startdate);
                            const endDate = new Date(item?.endDate);
                            preViewTotalVotesForIndDir =
                              preViewTotalVotesForIndDir +
                              (Number(item?.value) || 0);
                            preViewTotalVotes =
                              preViewTotalVotes + (Number(item?.value) || 0);
                            return (
                              <>
                                <div key={key} class="row no-gutters">
                                  <div class="col-md-12 mt-3">
                                    <div
                                      style={{
                                        maxWidth: "260px",
                                        textAlign: "center",
                                        fontSize: "18px",
                                        color: "#5B9BD5",
                                      }}
                                    >
                                      {/* <table>
                                  <tr>
                                    <td>{key +1 + " . "}</td>
                                    <td></td>
                                    <td>{item?.Name?.toUpperCase()}</td>
                                  </tr>
                                 </table> */}
                                      {key + 1}
                                      {". "}
                                      <span style={{ paddingLeft: "5px" }}>
                                        {" "}
                                        {item?.Name?.toUpperCase()}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        maxWidth: "260px",
                                        textAlign: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {numberWithCommas(item?.value) || 0}{" "}
                                      <span
                                        style={{
                                          fontSize: "14px",

                                          color: "rgb(152, 161, 181)",
                                        }}
                                      >
                                        votes
                                      </span>
                                    </div>
                                  </div>
                                  <hr style={{ width: "60%" }} />
                                </div>
                              </>
                            );
                          })}
                          <div
                            style={{
                              border: "1px solid #398a1b5",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              borderRadius: "5px",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  alignItems: "center",
                                  fontSize: "18px",
                                }}
                              >
                                Total Votes:{" "}
                                <span style={{ color: "#5B9BD5" }}>
                                  {" "}
                                  {numberWithCommas(
                                    totalShares *
                                      parseFloat(
                                        numOfIndependentDirector || "0"
                                      )
                                  )}
                                </span>
                              </div>
                              <div
                                style={{
                                  alignItems: "center",
                                  fontSize: "18px",
                                }}
                              >
                                Votes Casted:{" "}
                                <span style={{ color: "#5B9BD5" }}>
                                  {numberWithCommas(preViewTotalVotesForIndDir)}
                                </span>
                              </div>
                              {/* <div
                                style={{
                                  alignItems: "center",
                                  fontSize: "18px",
                                }}
                              >
                                Remaining Votes:{" "}
                                <span style={{ color: "#5B9BD5" }}>
                                  {" "}
                                  {numberWithCommas(
                                    totalShares *
                                      parseFloat(
                                        numOfIndependentDirector || "0"
                                      ) -
                                      preViewTotalVotesForIndDir
                                  )}
                                </span>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                      {independentCandidate.length ===
                        numOfIndependentDirector &&
                        independentCandidate.forEach((candidate) => {
                          candidate.value = totalShares;
                          totalVotesForIndDir =
                            totalShares *
                            parseFloat(numOfIndependentDirector || "0");
                          assignedVotesIndependent =
                            totalShares *
                            parseFloat(numOfIndependentDirector || "0");
                        })}

                      {nonIndependentCandidate.length >
                        numOfNonIndependentDirector && (
                        <div
                          className="mt-2 b-t-primary b-b-primary"
                          style={{ borderRadius: "10px" }}
                        >
                          <h6 className="mt-3 d-flex justify-content-center">
                            {/* Non Independent Candidates{" "} */}
                            Other Candidates{" "}
                          </h6>

                          {nonIndependentCandidate?.map((item, key) => {
                            const startDate = new Date(item?.startdate);
                            const endDate = new Date(item?.endDate);
                            preViewTotalVotesForNonIndDir =
                              preViewTotalVotesForNonIndDir +
                              (Number(item?.value) || 0);
                            preViewTotalVotes =
                              preViewTotalVotes + (Number(item?.value) || 0);
                            return (
                              <>
                                <div key={key} class="row no-gutters">
                                  <div class="col-md-12 mt-3">
                                    <div
                                      style={{
                                        maxWidth: "260px",
                                        textAlign: "center",
                                        fontSize: "18px",
                                        color: "#5B9BD5",
                                      }}
                                    >
                                      {/* <table>
                                  <tr>
                                    <td>{key +1 + " . "}</td>
                                    <td></td>
                                    <td>{item?.Name?.toUpperCase()}</td>
                                  </tr>
                                 </table> */}
                                      {key + 1}
                                      {". "}
                                      <span style={{ paddingLeft: "5px" }}>
                                        {" "}
                                        {item?.Name?.toUpperCase()}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        maxWidth: "260px",
                                        textAlign: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {numberWithCommas(item?.value) || 0}{" "}
                                      <span
                                        style={{
                                          fontSize: "14px",

                                          color: "rgb(152, 161, 181)",
                                        }}
                                      >
                                        votes
                                      </span>
                                    </div>
                                  </div>
                                  <hr style={{ width: "60%" }} />
                                </div>
                              </>
                            );
                          })}
                          <div
                            style={{
                              border: "1px solid #398a1b5",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              borderRadius: "5px",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  alignItems: "center",
                                  fontSize: "18px",
                                }}
                              >
                                Total Votes:{" "}
                                <span style={{ color: "#5B9BD5" }}>
                                  {" "}
                                  {numberWithCommas(
                                    totalShares *
                                      parseFloat(
                                        numOfNonIndependentDirector || "0"
                                      )
                                  )}
                                </span>
                              </div>
                              <div
                                style={{
                                  alignItems: "center",
                                  fontSize: "18px",
                                }}
                              >
                                Votes Casted:{" "}
                                <span style={{ color: "#5B9BD5" }}>
                                  {numberWithCommas(
                                    preViewTotalVotesForNonIndDir
                                  )}
                                </span>
                              </div>
                              {/* <div
                                style={{
                                  alignItems: "center",
                                  fontSize: "18px",
                                }}
                              >
                                Remaining Votes:{" "}
                                <span style={{ color: "#5B9BD5" }}>
                                  {" "}
                                  {numberWithCommas(
                                    totalShares *
                                      parseFloat(
                                        numOfNonIndependentDirector || "0"
                                      ) -
                                      preViewTotalVotesForNonIndDir
                                  )}
                                </span>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                      {nonIndependentCandidate.length ===
                        numOfNonIndependentDirector &&
                        nonIndependentCandidate.forEach((candidate) => {
                          totalVotesForNonIndDir =
                            totalShares *
                            parseFloat(numOfNonIndependentDirector || "0");
                          assignedVotesNonIndependent =
                            totalShares *
                            parseFloat(numOfNonIndependentDirector || "0");
                          candidate.value = totalShares;
                        })}

                      <div
                        className="mt-5 b-t-primary b-b-primary"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="mt-3 mb-3">
                          <div
                            className="d-flex justify-content-center"
                            style={{
                              alignItems: "center",
                              fontSize: "18px",
                            }}
                          >
                            Total Votes:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {" "}
                              {numberWithCommas(
                                totalShares *
                                  parseFloat(
                                    numOfNonIndependentDirector +
                                      numOfFemaleDirector +
                                      numOfIndependentDirector || "0"
                                  ) -
                                  parseFloat(
                                    assignedVotesFemale +
                                      assignedVotesIndependent +
                                      assignedVotesNonIndependent || "0"
                                  )
                              )}
                            </span>
                          </div>
                          <div
                            className="d-flex justify-content-center"
                            style={{ alignItems: "center", fontSize: "18px" }}
                          >
                            Votes Casted:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {numberWithCommas(preViewTotalVotes)}
                            </span>
                          </div>
                          {/* <div
                            style={{ alignItems: "center", fontSize: "18px" }}
                          >
                            Remaining Votes:{" "}
                            <span style={{ color: "#5B9BD5" }}>
                              {" "}
                              {numberWithCommas(
                                totalShares *
                                  parseFloat(
                                    getEvotingData?.number_of_directors || "0"
                                  ) -
                                  preViewTotalVotes
                              )}
                            </span>
                          </div> */}
                        </div>
                      </div>

                      <div>
                        <button
                          className="btn mt-5"
                          type="button"
                          disabled={loading}
                          style={{
                            width: "100%",
                            color: "#ffff",
                            background: "#5B9BD5",
                          }}
                          onClick={() => {
                            if (
                              new Date().getTime() >=
                              new Date(getEvotingData?.election_to).getTime()
                            ) {
                              toast.error("Voting Time Expired");
                              setTimeout(() => {
                                history.replace(
                                  `${process.env.PUBLIC_URL}/evoting/home`
                                );
                              }, 3000);
                            } else {
                              handlePreViewVoting();
                            }
                          }}
                        >
                          <span> {loading ? "Uploading..." : "CONFIRM"}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PaddingWrapper>
          </MainWrapper>
        </div>
      ) : (
        <div className="evotingfontfamily" style={{ padding: "20px" }}>
          <MainWrapper className="row mt-4">
            <PaddingWrapper className="col-sm-12 col-md-12 col-lg-12 responsivepage">
              <div
                className="card pb-5"
                style={{ border: "1px solid #70ace7" }}
              >
                <Wrapper
                  className="card-header "
                  style={{ borderBottom: "none" }}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <AiOutlineLeft
                      className="mt-3"
                      size={30}
                      style={{ color: "#677ecc", cursor: "pointer" }}
                      onClick={() => {
                        history.push(`${process.env.PUBLIC_URL}/home`);
                      }}
                    />
                    <Logout />
                  </div>

                  <div className="text-center mt-1">
                    <img
                      src={
                        getEvotingData.logo
                          ? getEvotingData.logo
                          : require("../../../assets/images/defaultImg.png")
                      }
                      alt=""
                      maxWidth="220"
                      height={"130px"}
                      style={{
                        maxWidth: "220px",
                        maxHeight: "130px",
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      color: "#447bc9",
                      fontSize: "23px",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    Election of Directors
                  </div>
                  <p
                    style={{
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      color: "rgb(152, 161, 181)",
                      textAlign: "center",
                    }}
                    class="card-text"
                  >
                    {moment(getEvotingData?.election_from).format(
                      "DD-MMM-YYYY h:mmA"
                    )}{" "}
                    <br /> TO <br />
                    {moment(getEvotingData?.election_to).format(
                      "DD-MMM-YYYY h:mmA"
                    )}
                  </p>

                  <div
                    className="mt-3"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "20px",
                    }}
                  >
                    Enter Your Votes
                  </div>
                </Wrapper>
                <div className="responsivepaddingpage">
                  <div
                    className="card-body"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {femaleCandidate.length > numOfFemaleDirector && (
                      <div
                        className="pt-3 pb-3 mt-2 b-t-primary b-b-primary"
                        style={{ borderRadius: "10px" }}
                      >
                        <>
                          <h6 className="mt-3 d-flex justify-content-center">
                            Female Candidates
                          </h6>
                          {femaleCandidate?.map((item, key) => {
                            totalVotesForFemaleDir =
                              totalVotesForFemaleDir +
                              (Number(item?.value) || 0);

                            return (
                              <div
                                key={`${item?.Name}-${key}`}
                                className="row no-gutters d-flex justify-content-center"
                              >
                                <>
                                  <div key={key} class="row no-gutters">
                                    <div class="col-md-12 mt-3">
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          fontSize: "16px",
                                          color: "rgb(152, 161, 181)",
                                          maxWidth: "260px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Votes For
                                        <span
                                          style={{
                                            color: "#5B9BD5",
                                            paddingLeft: "5px",
                                          }}
                                        >
                                          {item?.Name?.toUpperCase()}
                                        </span>
                                      </div>
                                      {/* <NumberFormat
                                    decimalScale={0}
                                    allowNegative={false} // Disallow negative numbers
                                    allowLeadingZeros={false} // Disallow leading zeros
                              
                                  style={{
                                        minWidth: "260px",
                                        maxWidth: "260px",
                                        textAlign: "center",
                                      }}
                                  className="form-control mt-1"
                                  value={ item.value ? item.value : " " }
                                  onChange={(event) =>{
                                    
                                    let text=event.target.value

                                    
                                    if ((text.length == 0)|| (Number(text) >= 0  &&  isNaN(text) == false && Number.isInteger(Number(text))))
                                     {
        
                                       handleInputChange(key, event)
                                      }
                                  }}
                                /> */}
                                      <input
                                        style={{
                                          minWidth: "260px",
                                          maxWidth: "260px",
                                          textAlign: "center",
                                        }}
                                        type="number"
                                        className={`form-control mt-1 ${
                                          flag == true || dummyArr.includes(key)
                                            ? "b-danger"
                                            : ""
                                        }`}
                                        value={item.value ? item.value : " "}
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                          if (e.key === ".") {
                                            e.preventDefault();
                                          }
                                          if (
                                            e.key.toLowerCase() === "backspace"
                                          ) {
                                            backspacePress = true;
                                          } else {
                                            backspacePress = false;
                                          }
                                        }}
                                        onChange={(event) => {
                                          if (
                                            event?.key?.toLowerCase() !==
                                              "backspace" &&
                                            event.target.value >
                                              totalShares *
                                                parseFloat(
                                                  numOfFemaleDirector || "0"
                                                )
                                          ) {
                                            toast.error(
                                              "You are trying to cast more votes then authorized!"
                                            );
                                            return;
                                          }
                                          if (
                                            !/^\d*$/.test(event.target.value)
                                          ) {
                                            return;
                                          }
                                          if (
                                            !backspacePress &&
                                            totalShares *
                                              parseFloat(
                                                numOfFemaleDirector || "0"
                                              ) <=
                                              totalVotesForFemaleDir
                                          ) {
                                            return;
                                          }
                                          setFlag(false);
                                          if (
                                            parseFloat(event.target.value) >
                                            totalShares *
                                              parseFloat(
                                                numOfFemaleDirector || "0"
                                              )
                                          ) {
                                            if (!dummyArr.includes(key)) {
                                              dummyArr.push(key);
                                            }
                                          } else {
                                            dummyArr = dummyArr.filter(
                                              (item) => item !== key
                                            );
                                            setDummyArr(dummyArr);
                                          }

                                          let text = event.target.value;
                                          if (
                                            text.length == 0 ||
                                            (Number(text) >= 0 &&
                                              isNaN(text) == false &&
                                              Number.isInteger(Number(text)))
                                          ) {
                                            handleInputFemaleDirectorChange(
                                              key,
                                              event
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              </div>
                            );
                          })}
                          <div
                            className="mt-3 d-flex justify-content-center"
                            style={{
                              display: "flex",
                              gap: "10px ",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                            }}
                          >
                            <span
                              className={`text-danger ${
                                totalShares *
                                  parseFloat(numOfFemaleDirector || "0") -
                                  totalVotesForFemaleDir <
                                  0 ||
                                totalShares *
                                  parseFloat(numOfFemaleDirector || "0") -
                                  totalVotesForFemaleDir >
                                  0
                                  ? ""
                                  : "d-none"
                              }`}
                            >
                              {" "}
                              Remaining Votes should be 0
                            </span>
                            <div
                              style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%",
                                marginBottom: "10px",
                              }}
                            >
                              <p className="p-0 m-0">Total Votes for Female:</p>
                              <p className="p-0 m-0">
                                {numberWithCommas(
                                  totalShares *
                                    parseFloat(numOfFemaleDirector || "0")
                                )}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <p className="p-0 m-0">
                              {" "}
                              Remaining Votes for Female:
                            </p>
                            <p className="p-0 m-0">
                              {numberWithCommas(
                                totalShares *
                                  parseFloat(numOfFemaleDirector || "0") -
                                  totalVotesForFemaleDir
                              )}
                            </p>
                          </div>
                        </>
                      </div>
                    )}
                    {femaleCandidate.length === numOfFemaleDirector &&
                      femaleCandidate.forEach((candidate) => {
                        totalVotesForFemaleDir =
                          totalShares * parseFloat(numOfFemaleDirector || "0");
                        assignedVotesFemale =
                          totalShares * parseFloat(numOfFemaleDirector || "0");
                        candidate.value = totalShares;
                      })}

                    {independentCandidate.length > numOfIndependentDirector && (
                      <div
                        className="pt-3 pb-3 mt-2 b-t-primary b-b-primary"
                        style={{ borderRadius: "10px" }}
                      >
                        <>
                          <h6
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                            }}
                          >
                            Independent Candidates
                          </h6>
                          {independentCandidate?.map((item, key) => {
                            totalVotesForIndDir =
                              totalVotesForIndDir + Number(item?.value) || 0;

                            return (
                              <div
                                key={`${item?.Name}-${key}`}
                                className="row no-gutters d-flex justify-content-center"
                              >
                                <>
                                  <div key={key} class="row no-gutters">
                                    <div class="col-md-12 mt-3">
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          fontSize: "16px",
                                          color: "rgb(152, 161, 181)",
                                          maxWidth: "260px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Votes For
                                        <span
                                          style={{
                                            color: "#5B9BD5",
                                            paddingLeft: "5px",
                                          }}
                                        >
                                          {item?.Name?.toUpperCase()}
                                        </span>
                                      </div>
                                      {/* <NumberFormat
                                    decimalScale={0}
                                    allowNegative={false} // Disallow negative numbers
                                    allowLeadingZeros={false} // Disallow leading zeros
                              
                                  style={{
                                        minWidth: "260px",
                                        maxWidth: "260px",
                                        textAlign: "center",
                                      }}
                                  className="form-control mt-1"
                                  value={ item.value ? item.value : " " }
                                  onChange={(event) =>{
                                    
                                    let text=event.target.value

                                    
                                    if ((text.length == 0)|| (Number(text) >= 0  &&  isNaN(text) == false && Number.isInteger(Number(text))))
                                     {
        
                                       handleInputChange(key, event)
                                      }
                                  }}
                                /> */}
                                      <input
                                        style={{
                                          minWidth: "260px",
                                          maxWidth: "260px",
                                          textAlign: "center",
                                        }}
                                        type="number"
                                        className={`form-control mt-1 ${
                                          flagIndependent == true ||
                                          independentDummyArr.includes(key)
                                            ? "b-danger"
                                            : ""
                                        }`}
                                        value={item.value ? item.value : " "}
                                        onWheel={(e) => e.target.blur()}
                                        onKeyDown={(e) => {
                                          if (e.key === ".") {
                                            e.preventDefault();
                                          }
                                          if (
                                            e.key.toLowerCase() !==
                                              "backspace" &&
                                            e.target.value >
                                              totalShares *
                                                parseFloat(
                                                  numOfIndependentDirector ||
                                                    "0"
                                                )
                                          ) {
                                            toast.error(
                                              "You are trying to cast more votes then authorized!"
                                            );
                                            return;
                                          }
                                          if (!/^\d*$/.test(e.target.value)) {
                                            return;
                                          }
                                          if (
                                            e.key.toLowerCase() === "backspace"
                                          ) {
                                            backspacePress = true;
                                          } else {
                                            backspacePress = false;
                                          }
                                        }}
                                        onChange={(event) => {
                                          setIndependentFlag(false);
                                          if (
                                            totalShares *
                                              parseFloat(
                                                numOfIndependentDirector || "0"
                                              ) -
                                              totalVotesForIndDir <=
                                              0 &&
                                            !backspacePress
                                          ) {
                                            return;
                                          }
                                          if (
                                            parseFloat(event.target.value) >
                                            totalShares *
                                              parseFloat(
                                                numOfIndependentDirector || "0"
                                              )
                                          ) {
                                            if (
                                              !independentDummyArr.includes(key)
                                            ) {
                                              independentDummyArr.push(key);
                                            }
                                          } else {
                                            independentDummyArr =
                                              independentDummyArr.filter(
                                                (item) => item !== key
                                              );
                                            setIndependentDummyArr(
                                              independentDummyArr
                                            );
                                          }

                                          let text = event.target.value;

                                          if (
                                            text.length == 0 ||
                                            (Number(text) >= 0 &&
                                              isNaN(text) == false &&
                                              Number.isInteger(Number(text)))
                                          ) {
                                            handleInputIndependentDirectorChange(
                                              key,
                                              event
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              </div>
                            );
                          })}
                          <div
                            className="mt-3 d-flex justify-content-center"
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <span
                              className={`text-danger  ${
                                totalShares *
                                  parseFloat(numOfIndependentDirector || "0") -
                                  totalVotesForIndDir <
                                  0 ||
                                totalShares *
                                  parseFloat(numOfIndependentDirector || "0") -
                                  totalVotesForIndDir >
                                  0
                                  ? ""
                                  : "d-none"
                              }`}
                            >
                              {" "}
                              Remaining Votes should be 0
                            </span>
                            <div
                              style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <p className="p-0 m-0">
                                {" "}
                                Total Votes for Independent:
                              </p>
                              <p>
                                {" "}
                                {numberWithCommas(
                                  totalShares *
                                    parseFloat(numOfIndependentDirector || "0")
                                )}{" "}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "100%",
                              marginTop: "5px",
                            }}
                          >
                            <p className="p-0 m-0">
                              {" "}
                              Remaining Votes for Independent:{" "}
                            </p>
                            <p>
                              {numberWithCommas(
                                totalShares *
                                  parseFloat(numOfIndependentDirector || "0") -
                                  totalVotesForIndDir
                              )}
                            </p>
                          </div>
                        </>
                      </div>
                    )}
                    {independentCandidate.length === numOfIndependentDirector &&
                      independentCandidate.forEach((candidate) => {
                        candidate.value = totalShares;
                        totalVotesForIndDir =
                          totalShares *
                          parseFloat(numOfIndependentDirector || "0");
                        assignedVotesIndependent =
                          totalShares *
                          parseFloat(numOfIndependentDirector || "0");
                      })}

                    {nonIndependentCandidate.length >
                      numOfNonIndependentDirector && (
                      <div
                        className="pt-3 pb-3 mt-2 b-t-primary b-b-primary"
                        style={{ borderRadius: "10px" }}
                      >
                        <>
                          <h6
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                            }}
                          >
                            {/* Non Independent Candidates */}
                            Other Candidates
                          </h6>
                          {nonIndependentCandidate?.map((item, key) => {
                            totalVotesForNonIndDir =
                              totalVotesForNonIndDir +
                              (Number(item?.value) || 0);
                            return (
                              <>
                                <div
                                  key={`${item?.Name}-${key}`}
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <div class="col-md-12 mt-3">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "16px",
                                        color: "rgb(152, 161, 181)",
                                        maxWidth: "260px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Votes For
                                      <span
                                        style={{
                                          color: "#5B9BD5",
                                          paddingLeft: "5px",
                                        }}
                                      >
                                        {item?.Name?.toUpperCase()}
                                      </span>
                                    </div>
                                    {/* <NumberFormat
                                    decimalScale={0}
                                    allowNegative={false} // Disallow negative numbers
                                    allowLeadingZeros={false} // Disallow leading zeros
                              
                                  style={{
                                        minWidth: "260px",
                                        maxWidth: "260px",
                                        textAlign: "center",
                                      }}
                                  className="form-control mt-1"
                                  value={ item.value ? item.value : " " }
                                  onChange={(event) =>{
                                    
                                    let text=event.target.value

                                    
                                    if ((text.length == 0)|| (Number(text) >= 0  &&  isNaN(text) == false && Number.isInteger(Number(text))))
                                     {
        
                                       handleInputChange(key, event)
                                      }
                                  }}
                                /> */}
                                    <input
                                      style={{
                                        minWidth: "260px",
                                        maxWidth: "260px",
                                        textAlign: "center",
                                      }}
                                      type="number"
                                      className={`form-control mt-1 ${
                                        flagNonIndependent === true ||
                                        nonIndependentDummyArr.includes(key)
                                          ? "b-danger"
                                          : ""
                                      }`}
                                      value={item.value ? item.value : " "}
                                      onWheel={(e) => e.target.blur()}
                                      onKeyDown={(e) => {
                                        if (e.key === ".") {
                                          e.preventDefault();
                                        }
                                        if (
                                          e.key.toLowerCase() !== "backspace" &&
                                          e.target.value >
                                            totalShares *
                                              parseFloat(
                                                numOfNonIndependentDirector ||
                                                  "0"
                                              )
                                        ) {
                                          toast.error(
                                            "You are trying to cast more votes then authorized!"
                                          );
                                          return;
                                        }
                                        if (!/^\d*$/.test(e.target.value)) {
                                          return;
                                        }
                                        if (
                                          e.key.toLowerCase() === "backspace"
                                        ) {
                                          backspacePress = true;
                                        } else {
                                          backspacePress = false;
                                        }
                                      }}
                                      onChange={(event) => {
                                        setNonIndependentflag(false);

                                        // if (
                                        //   event?.key?.toLowerCase() !==
                                        //     "backspace" &&
                                        //   event.target.value >
                                        //     totalShares *
                                        //       parseFloat(
                                        //         numOfNonIndependentDirector || "0"
                                        //       )
                                        // ) {
                                        //   toast.error(
                                        //     "You are trying to cast more votes then authorized!"
                                        //   );
                                        //   return;
                                        // }
                                        // if (!/^\d*$/.test(event.target.value)) {
                                        //   return;
                                        // }
                                        if (
                                          totalShares *
                                            parseFloat(
                                              numOfNonIndependentDirector || "0"
                                            ) -
                                            totalVotesForNonIndDir <=
                                            0 &&
                                          !backspacePress
                                        ) {
                                          return;
                                        }
                                        if (
                                          parseFloat(event.target.value) >
                                          totalShares *
                                            parseFloat(
                                              numOfNonIndependentDirector || "0"
                                            )
                                        ) {
                                          if (
                                            !nonIndependentDummyArr.includes(
                                              key
                                            )
                                          ) {
                                            nonIndependentDummyArr.push(key);
                                          }
                                        } else {
                                          nonIndependentDummyArr =
                                            nonIndependentDummyArr.filter(
                                              (item) => item !== key
                                            );
                                          setNonIndependentDummyArr(
                                            nonIndependentDummyArr
                                          );
                                        }

                                        let text = event.target.value;

                                        if (
                                          text.length == 0 ||
                                          (Number(text) >= 0 &&
                                            isNaN(text) == false &&
                                            Number.isInteger(Number(text)))
                                        ) {
                                          handleInputNonIndependentDirectorChange(
                                            key,
                                            event
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })}
                          <div
                            className="mt-3 d-flex justify-content-center"
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <span
                              className={`text-danger ${
                                totalShares *
                                  parseFloat(
                                    numOfNonIndependentDirector || "0"
                                  ) -
                                  totalVotesForNonIndDir <
                                  0 ||
                                totalShares *
                                  parseFloat(
                                    numOfNonIndependentDirector || "0"
                                  ) -
                                  totalVotesForNonIndDir >
                                  0
                                  ? ""
                                  : "d-none"
                              }`}
                            >
                              {" "}
                              Remaining Votes should be 0
                            </span>
                            <div
                              style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%",
                                marginBottom: "10px",
                              }}
                            >
                              <p className="m-0 p-0">
                                {" "}
                                {/* Total Votes for Non Independent: */}
                                Total Votes for Others:
                              </p>
                              <p className="m-0 p-0">
                                {" "}
                                {numberWithCommas(
                                  totalShares *
                                    parseFloat(
                                      numOfNonIndependentDirector || "0"
                                    )
                                )}{" "}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "100%",
                              marginBottom: "10px",
                            }}
                          >
                            <p className="m-0 p-0">
                              {" "}
                              {/* Remaining Votes for Non Independent: */}
                              Remaining Votes for Others:
                            </p>
                            <p className="m-0 p-0">
                              {numberWithCommas(
                                totalShares *
                                  parseFloat(
                                    numOfNonIndependentDirector || "0"
                                  ) -
                                  totalVotesForNonIndDir
                              )}
                            </p>
                          </div>
                        </>
                      </div>
                    )}
                    {nonIndependentCandidate.length ===
                      numOfNonIndependentDirector &&
                      nonIndependentCandidate.forEach((candidate) => {
                        totalVotesForNonIndDir =
                          totalShares *
                          parseFloat(numOfNonIndependentDirector || "0");
                        assignedVotesNonIndependent =
                          totalShares *
                          parseFloat(numOfNonIndependentDirector || "0");
                        candidate.value = totalShares;
                      })}
                    <div
                      className="pt-3 pb-3 mt-5 b-t-primary b-b-primary"
                      style={{ borderRadius: "10px" }}
                    >
                      <>
                        <h6 className="d-flex justify-content-center">
                          Votes For All Candidates
                        </h6>
                        <div
                          className="mt-3 d-flex justify-content-center"
                          style={{
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "100%",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            Total Votes:{" "}
                            {numberWithCommas(
                              totalShares *
                                parseFloat(
                                  numOfNonIndependentDirector +
                                    numOfFemaleDirector +
                                    numOfIndependentDirector || "0"
                                ) -
                                parseFloat(
                                  assignedVotesFemale +
                                    assignedVotesIndependent +
                                    assignedVotesNonIndependent || "0"
                                )
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "100%",
                              marginBottom: "10px",
                            }}
                          >
                            Remaining Votes:{" "}
                            {numberWithCommas(
                              totalShares *
                                parseFloat(
                                  numOfNonIndependentDirector +
                                    numOfFemaleDirector +
                                    numOfIndependentDirector || "0"
                                ) -
                                totalVotesForNonIndDir -
                                totalVotesForFemaleDir -
                                totalVotesForIndDir
                            )}
                          </div>
                        </div>
                      </>
                    </div>

                    <div>
                      <button
                        className="btn mt-5"
                        type="button"
                        disabled={loadingCandidate}
                        style={{
                          width: "100%",
                          color: "#ffff",
                          background: "#5B9BD5",
                        }}
                        onClick={() => {
                          handleVoting();
                        }}
                      >
                        <span>
                          {" "}
                          {loadingCandidate ? "Getting Data..." : "Cast Vote"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </PaddingWrapper>
          </MainWrapper>
        </div>
      )}
    </>
  );
};

const MainWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepage {
    }
  }
  @media (min-width: 500px) {
    .responsivepage {
      display: flex;
      justify-content: center;
    }
  }
`;
const PaddingWrapper = styled.div`
  @media (min-width: 300px) {
    .responsivepaddingpage {
      ${"" /* width: 100%; */}
    }
  }
  @media (min-width: 500px) {
    .responsivepaddingpage {
      ${
        "" /* display: flex;
      justify-content: center; */
      }
      padding: 0px 100px;
      min-width: 550px;
      max-width: 550px;s
      ${
        "" /* width: 50%;
      margin:auto; */
      }
    }
  }
`;
const Wrapper = styled.div`
  @media (min-width: 300px) {
    .responsive {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .responsive {
      width: 53%;
    }
  }
  @media (min-width: 660px) {
    .responsive {
      width: 45%;
    }
  }
  @media (min-width: 840px) {
    .responsive {
      width: 35%;
    }
  }
  @media (min-width: 1132px) {
    .responsive {
      width: 26%;
    }
  }

  @media (min-width: 1132px) {
    .responsive {
      width: 22%;
    }
  }

  @media (min-width: 1300px) {
    .responsive {
      width: 20%;
    }
  }
  @media (min-width: 1470px) {
    .responsive {
      width: 15%;
    }
  }
`;
