import axios from "axios";
import Config from "../../config";

const getInvestors = async (email) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/investors?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const addInvestor = async (
  email,
  category,
  occupation,
  salutation,
  investor_name,
  cnic,
  ntn,
  birth_date,
  gender,
  religion,
  father_name,
  spouse_name,
  cnic_expiry
) => {
  const url = `${Config.baseUrl}/investors/`;

  const result = await axios.post(
    url,
    {
      email,
      category,
      occupation,
      salutation,
      investor_name,
      cnic,
      ntn,
      birth_date,
      gender,
      religion,
      father_name,
      spouse_name,
      cnic_expiry,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const updateInvestor = async (
  email,
  investor_id,
  category,
  occupation,
  salutation,
  investor_name,
  cnic,
  ntn,
  birth_date,
  gender,
  religion,
  father_name,
  spouse_name,
  cnic_expiry,
  cdc_participant_id
) => {
  const url = `${Config.baseUrl}/investors/update`;

  const result = await axios.post(
    url,
    {
      email,
      investor_id,
      category,
      occupation,
      salutation,
      investor_name,
      cnic,
      ntn,
      birth_date,
      gender,
      religion,
      father_name,
      spouse_name,
      cnic_expiry,
      cdc_participant_id,
    },
    {
      headers: {
        Authorization: JSON.parse(sessionStorage.getItem("token")) || "",
      },
    }
  );
  return result;
};

const getInvestorRequest = async (email) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/investorrequests?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const addInvestorRequest = async (
  email,
  // request_id,
  request_date,
  folio_number,
  request_type,
  announcement_id,
  entitlement_id,
  symbol,
  company_code,
  quantity,
  price,
  amount,
  amount_payable,
  amount_paid,
  to_folio_number,
  to_investor_id,
  input_certificates,
  output_certificate
) => {
  const url = `${Config.baseUrl}/investorrequests/`;

  const result = await axios.post(
    url,
    {
      email,
      // request_id,
      request_date,
      folio_number,
      request_type,
      announcement_id,
      entitlement_id,
      symbol,
      company_code,
      quantity,
      price,
      amount,
      amount_payable,
      amount_paid,
      to_folio_number,
      to_investor_id,
      input_certificates,
      output_certificate,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};
const addInvestorRequestDUP = async (
  email,
  request_type,
  folio_number,
  company_code,
  symbol,
  amount,
  input_certificates,
  output_certificates,
  remarks,
  shareholder_id
) => {
  const url = `${Config.baseUrl}/investorrequests/by-shareholder/`;

  const result = await axios.post(
    url,
    {
      email,
      request_type,
      folio_number,
      company_code,
      symbol,
      amount,
      input_certificates,
      output_certificates,
      remarks,
      shareholder_id,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};
const addInvestorRequestTOS = async (
  email,
  request_type,
  folio_number,
  company_code,
  symbol,
  to_folio_number,
  quantity,
  request_date,
  input_certificates,
  remarks,
  transfer_no,
  shareholder_id
) => {
  const url = `${Config.baseUrl}/investorrequests/by-shareholder/`;

  const result = await axios.post(
    url,
    {
      email,
      request_type,
      folio_number,
      company_code,
      symbol,
      to_folio_number,
      quantity,
      request_date,
      input_certificates,
      remarks,
      transfer_no,
      shareholder_id,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const addInvestorRequestVTD = async (
  email,
  request_type,
  folio_number,
  company_code,
  symbol,
  quantity,
  request_date,
  attachments,
  input_certificates,
  remarks,
  shareholder_id
) => {
  const url = `${Config.baseUrl}/investorrequests/by-shareholder/`;

  const result = await axios.post(
    url,
    {
      email,
      request_type,
      folio_number,
      company_code,
      symbol,
      quantity,
      request_date,
      attachments,
      input_certificates,
      remarks,
      shareholder_id,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};
const addInvestorRequestTOR = async (
  email,
  request_type,
  folio_number,
  company_code,
  symbol,
  to_folio_number,
  quantity,
  remarks,
  shareholder_id
) => {
  const url = `${Config.baseUrl}/investorrequests/by-shareholder/`;

  const result = await axios.post(
    url,
    {
      email,
      request_type,
      folio_number,
      company_code,
      symbol,
      to_folio_number,
      quantity,
      input_certificates: [],
      output_certificates: [],
      remarks,
      shareholder_id,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const addInvestorRequestSPL = async (
  email,
  request_type,
  folio_number,
  company_code,
  symbol,
  quantity,
  amount,
  input_certificates,
  output_certificates,
  remarks,
  shareholder_id
) => {
  const url = `${Config.baseUrl}/investorrequests/by-shareholder/`;

  const result = await axios.post(
    url,
    {
      email,
      request_type,
      folio_number,
      company_code,
      symbol,
      quantity,
      amount,
      input_certificates,
      output_certificates,
      remarks,
      shareholder_id,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const addInvestorRequestTRS = async (
  email,
  request_type,
  folio_number,
  company_code,
  symbol,
  quantity,
  amount,
  input_certificates,
  output_certificates,
  remarks,
  shareholder_id
) => {
  const url = `${Config.baseUrl}/investorrequests/by-shareholder/`;

  const result = await axios.post(
    url,
    {
      email,
      request_type,
      folio_number,
      company_code,
      symbol,
      quantity,
      amount,
      input_certificates,
      output_certificates,
      remarks,
      shareholder_id,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const addInvestorRequestCON = async (
  email,
  request_type,
  folio_number,
  company_code,
  symbol,
  input_certificates,
  output_certificates,
  remarks,
  shareholder_id
) => {
  const url = `${Config.baseUrl}/investorrequests/by-shareholder/`;

  const result = await axios.post(
    url,
    {
      email,
      request_type,
      folio_number,
      company_code,
      symbol,
      input_certificates,
      output_certificates,
      remarks,
      shareholder_id,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};
const addInvestorRequestRSUB = async (
  email,
  request_type,
  folio_number,
  company_code,
  symbol,
  entitlement_id,
  announcement_id,
  amount,
  price,
  quantity,
  deposited_date,
  output_certificates,
  remarks,
  shareholder_id
) => {
  const url = `${Config.baseUrl}/investorrequests/by-shareholder/`;

  const result = await axios.post(
    url,
    {
      email,
      request_type,
      folio_number,
      company_code,
      symbol,
      entitlement_id,
      announcement_id,
      amount,
      price,
      quantity,
      deposited_date,
      output_certificates,
      remarks,
      shareholder_id,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const addInvestorRequestCEL = async (
  email,
  request_type,
  folio_number,
  to_folio_number,
  company_code,
  symbol,
  quantity,
  input_certificates,
  remarks,
  reference,
  shareholder_id
) => {
  const url = `${Config.baseUrl}/investorrequests/by-shareholder/`;

  const result = await axios.post(
    url,
    {
      email,
      request_type,
      folio_number,
      to_folio_number,
      company_code,
      symbol,
      quantity,
      input_certificates,
      remarks,
      reference,
      shareholder_id,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};
const addInvestorRequestCPH = async (
  email,
  request_type,
  folio_number,
  to_folio_number,
  company_code,
  symbol,
  quantity,
  output_certificates,
  remarks,
  reference,
  amount, // Used as No of Certificates
  shareholder_id
) => {
  const url = `${Config.baseUrl}/investorrequests/by-shareholder/`;

  const result = await axios.post(
    url,
    {
      email,
      request_type,
      folio_number,
      to_folio_number,
      company_code,
      symbol,
      quantity,
      output_certificates,
      remarks,
      reference,
      amount, // Used as No of Certificates
      shareholder_id,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const updateInvestorRequest = async (
  email,
  request_id,
  request_date,
  folio_number,
  request_type,
  announcement_id,
  entitlement_id,
  symbol,
  company_code,
  quantity,
  price,
  amount,
  amount_payable,
  amount_paid,
  to_folio_number,
  to_investor_id
) => {
  const url = `${Config.baseUrl}/investorrequests/update`;

  const result = await axios.post(
    url,
    {
      email,
      request_id,
      request_date,
      folio_number,
      request_type,
      announcement_id,
      entitlement_id,
      symbol,
      company_code,
      quantity,
      price,
      amount,
      amount_payable,
      amount_paid,
      to_folio_number,
      to_investor_id,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

export {
  getInvestors,
  addInvestor,
  addInvestorRequest,
  getInvestorRequest,
  updateInvestor,
  updateInvestorRequest,
  addInvestorRequestDUP,
  addInvestorRequestTOS,
  addInvestorRequestSPL,
  addInvestorRequestCEL,
  addInvestorRequestCPH,
  addInvestorRequestRSUB,
  addInvestorRequestCON,
  addInvestorRequestTOR,
  addInvestorRequestVTD,
  addInvestorRequestTRS,
};
