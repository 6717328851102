import React, { useState } from 'react'
import { AiOutlineLeft } from "react-icons/ai";
import styled from 'styled-components';
import { useHistory } from 'react-router';
import DCCLogo from "../../../assets/DCC-Logo.svg"
import { AiOutlineCheckSquare } from "react-icons/ai";
import { numberWithCommas } from '../../../utilities/utilityFunctions';
import { useLocation } from 'react-router';
import "./evotingStyle.scss";
import { Logout } from "./logout";
export const SpecialVotinResult = () => {
  const history = useHistory();
  return (
    <div  className="evotingfontfamily"   style={{ padding: '20px' }}>
      <MainWrapper className='row mt-4'>
        <div className="col-sm-12 col-md-12 col-lg-12 responsivepage" >
          <div className='card pb-5' style={{ border: '1px solid #70ace7' }}>
            <Wrapper className="card-header " style={{ borderBottom: 'none' }}>
            <div className='d-flex' style={{justifyContent:'space-between'}}>
            <AiOutlineLeft className='mt-3' size={30} style={{ color: '#677ecc', cursor: 'pointer' }} 
              onClick={() => {
                  history.push(`${process.env.PUBLIC_URL}/view-special-resolution-vote`)
                }}/>
              <Logout />
            </div>
              

              <div className="text-center mt-1">
                <img src={DCCLogo}
                  alt=""
                  width="220"
                  height={'130px'} />
              </div>
              <div className='mt-3'
                style={{
                  color: '#447bc9',
                  fontSize: '23px',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                Special Resolution
              </div>
              <p style={{
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'center',
                color: '#98a1b5'
              }}
                class="card-text">
                2023-03-02T14:19 to 2023-03-31T14:19
              </p>

              <div className='mt-3'
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  fontSize: '20px'
                }}>
                Special Voting Results
              </div>
              {/* <div className='mt-3' style={{ justifyContent: 'center', display: 'flex', fontSize: '16px', color: '#98a1b5', padding: '0px 15px' }}>
            Following candidates are selected as Governing Directors on Board
            </div> */}
            </Wrapper>
            <div className="card-body mt-4"
              style={{
                justifyContent: 'center',
                display: 'flex'
              }}>
              <div>
                <div style={{
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'rgb(152, 161, 181)'
                }}> Agenda Title </div>

                <div className='mt-1'
                  style={{
                    border: '1px solid #98a1b5',
                    width: '100%', display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                    borderRadius: '5px'
                  }}>
                  <div  className="evotingfontfamily"   style={{
                    padding: '0px 30px',
                    fontSize: '22px',
                 
                    maxWidth: '440px',
                    textAlign: 'center',
                    fontWeight: 'bold'
                  }}>
                    Title of the agenda item will be displayed here.
                  </div>
                </div>

                <div className='mt-5'
                  style={{
                    color: '#447bc9',
                    fontSize: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: '440px'
                  }}>
                  Votes in favour of the agenda
                </div>
                <div style={{
                  fontSize: '22px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {numberWithCommas(1235450)}</span>
                  <span style={{
                    fontSize: '14px',
                    paddingLeft: '8px',
                    color: 'rgb(152, 161, 181)'
                  }}>
                    votes</span></div>

                <div className='mt-5'
                  style={{
                    color: '#447bc9',
                    fontSize: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: '440px'
                  }}>
                  Votes in against the agenda
                </div>
                <div style={{
                  fontSize: '22px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {numberWithCommas(3140)} </span>
                  <span style={{
                    fontSize: '14px',
                    paddingLeft: '8px',
                    color: 'rgb(152, 161, 181)'
                  }}>votes</span></div>
                <div className='mt-5'
                  style={{
                    color: '#447bc9',
                    fontSize: '23px',
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  Overall Result
                </div>
                <div className='mt-3'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '25px',
                    alignItems: 'center'
                  }}>
                  <AiOutlineCheckSquare style={{ color: 'green' }} size={30} />
                  <div style={{ fontSize: '28px', color: 'green' }}>
                    Approved
                  </div>
                </div>
                {/* {
                getEvotingData?.map((item, key) => {
                  const startDate = new Date(item?.startdate);
                  const endDate = new Date(item?.endDate);
                  return <> <div key={key} class="row no-gutters" >
                    <div class="col-md-12 mt-5">
                      <div style={{ display: 'flex', justifyContent: 'center', fontSize: '18px', color: '#5B9BD5', }}> Candidate {key + 1} <span style={{ paddingLeft: '5px' }}> {item?.Name?.toUpperCase()}</span></div>
                      <div style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>   {item?.value || 0} <span style={{ fontSize: '14px', paddingLeft: '8px' }}>votes</span></div>

                    </div>
                  </div>
                  </>
                })

              } */}

                {/* <label className="col-form-label pt-0 justify-content-center d-flex mt-5" style={{ fontSize: '16px' }}>
                Enter Authentication OTP
              </label>
              <input style={{ minWidth: '260px', maxWidth: '260px' }} type='text' className="form-control mt-1"
                onChange={(event) => console.log('event')} />


              <button
                className="btn mt-3"
                type="button"
                style={{ width: '100%', color: '#ffff', background: '#5B9BD5' }}
                onClick={() => {
                //   handlePreViewVoting()
                }}
              >


                <span> CONFIRM</span>

              </button> */}
              </div>









            </div>

          </div>
        </div>
      </MainWrapper>



    </div>


  )
}

const MainWrapper = styled.div`
@media(min-width: 300px){
    .responsivepage{

    }
  }
  @media(min-width: 500px){
    .responsivepage{
display: flex;
justify-content: center
}
  }
`;

const Wrapper = styled.div`
@media(min-width: 300px){
    .responsive{
    width: 80%;
    }
  }
  @media(min-width: 500px){
    .responsive{
    width: 53%;
    }
  }
  @media(min-width: 660px){
    .responsive{
    width: 45%;
    }
  }
  @media(min-width: 840px){
    .responsive{
    width : 35%;
    }
  }
  @media(min-width: 1132px){
    .responsive{
    width : 26%;
    }
  }
  
  @media(min-width: 1132px){
    .responsive{
    width : 22%;
    }
  }


@media(min-width: 1300px){
    .responsive{
    width : 20%;
    }
  }
  @media(min-width: 1470px){
    .responsive{
    width : 15%;
    }
  }
`;