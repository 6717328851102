import axios from "axios";
import Config from "../../config";

const getCorporateAnnouncement = async (email) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/corporateannouncements?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const getCorporateAnnouncementByCompanyCode = async (email, company_code) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/corporateannouncements/by-company?email=${email}&company_code=${company_code}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const getCorporateAnnouncementById = async (email, id) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/corporateannouncements/by-id?email=${email}&announcement_id=${id}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
const getCorporateEntitlementById = async (email, id) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/corporateentitlements/by-id?email=${email}&entitlement_id=${id}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const sendBulkEntitlements = async (email, announcement_id, entitlements) => {
  const url = `${Config.baseUrl}/corporateentitlements/bulk`;

  const result = await axios.post(
    url,
    { email, announcement_id, entitlements },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};
const addCorporateAnnouncement = async (
  email,
  company_code,
  symbol,
  announcement_date,
  dividend_number,
  dividend_percent,
  bonus_number,
  bonus_percent,
  right_number,
  right_percent,
  period,
  period_ended,
  book_closure_from,
  book_closure_to,
  right_subs_from,
  right_subs_to,
  provisional_from,
  provisional_to,
  right_credit_from,
  right_credit_to,
  right_rate
) => {
  const url = `${Config.baseUrl}/corporateannouncements/`;

  const result = await axios.post(
    url,
    {
      email,
      company_code,
      symbol,
      announcement_date,
      dividend_number,
      dividend_percent,
      bonus_number,
      bonus_percent,
      right_number,
      right_percent,
      period,
      period_ended,
      book_closure_from,
      book_closure_to,
      right_subs_from,
      right_subs_to,
      provisional_from,
      provisional_to,
      right_credit_from,
      right_credit_to,
      right_rate,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const updateCorporateAnnouncement = async (
  email,
  announcement_id,
  company_code,
  symbol,
  announcement_date,
  dividend_number,
  dividend_percent,
  bonus_number,
  bonus_percent,
  right_number,
  right_percent,
  period,
  period_ended,
  book_closure_from,
  book_closure_to,
  right_subs_from,
  right_subs_to,
  provisional_from,
  provisional_to,
  right_credit_from,
  right_credit_to,
  right_rate
) => {
  const url = `${Config.baseUrl}/corporateannouncements/update`;

  const result = await axios.post(
    url,
    {
      email,
      announcement_id,
      company_code,
      symbol,
      announcement_date,
      dividend_number,
      dividend_percent,
      bonus_number,
      bonus_percent,
      right_number,
      right_percent,
      period,
      period_ended,
      book_closure_from,
      book_closure_to,
      right_subs_from,
      right_subs_to,
      provisional_from,
      provisional_to,
      right_credit_from,
      right_credit_to,
      right_rate,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const getCorporateEntitlement = async (email) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/corporateentitlements?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
const getCorporateEntitlementByAnnouncement = async (
  email,
  announcement_id
) => {
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/corporateentitlements/by-annoucement?email=${email}&announcement_id=${announcement_id}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};

const addCorporateEntitlement = async (
  email,

  announcement_id,
  folio_number,
  dividend_amount,
  bonus_shares,
  right_shares,
  dividend_credited,
  dividend_credit_date,
  bonus_credited,
  right_subscribed,
  right_subs_date,
  account_title,
  account_no,
  bank_code,
  branch,
  amount,
  gateway_code
) => {
  const url = `${Config.baseUrl}/corporateentitlements/`;

  const result = await axios.post(
    url,
    {
      email,

      announcement_id,
      folio_number,
      dividend_amount,
      bonus_shares,
      right_shares,
      dividend_credited,
      dividend_credit_date,
      bonus_credited,
      right_subscribed,
      right_subs_date,
      account_title,
      account_no,
      bank_code,
      branch,
      amount,
      gateway_code,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

const updateCorporateEntitlement = async (
  email,
  entitlement_id,
  announcement_id,
  folio_number,
  dividend_amount,
  bonus_shares,
  right_shares,
  dividend_credited,
  dividend_credit_date,
  bonus_credited,
  right_subscribed,
  right_subs_date,
  account_title,
  account_no,
  bank_code,
  branch,
  amount,
  gateway_code
) => {
  const url = `${Config.baseUrl}/corporateentitlements/update`;

  const result = await axios.post(
    url,
    {
      email,
      entitlement_id,
      announcement_id,
      folio_number,
      dividend_amount,
      bonus_shares,
      right_shares,
      dividend_credited,
      dividend_credit_date,
      bonus_credited,
      right_subscribed,
      right_subs_date,
      account_title,
      account_no,
      bank_code,
      branch,
      amount,
      gateway_code,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
};

export {
  getCorporateAnnouncementById,
  getCorporateAnnouncement,
  addCorporateAnnouncement,
  getCorporateEntitlement,
  addCorporateEntitlement,
  updateCorporateAnnouncement,
  updateCorporateEntitlement,
  sendBulkEntitlements,
  getCorporateAnnouncementByCompanyCode,
  getCorporateEntitlementByAnnouncement,
  getCorporateEntitlementById,
};
