import * as yup from "yup";
import { IsJsonString } from "../../utilities/utilityFunctions";

export const addTransferOfShareSchema = yup.object().shape({
  request_date: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  // execution_date: yup.string().test("test-date", "Enter Date", (value) => {
  //   return value;
  // }),
  company_code: yup.object().required("Select Company"),
  // Transfer Details
  transferee_folio_no: yup.object().required("Select Transfree"),
  transferor_folio_no: yup.object().required("Select Transferor"),
  // TRansfer Details

  no_of_certificates: yup
    .string()
    .test(
      "check-val",
      "Transfer At least One Certificate",
      (val) => parseInt(val) > 0
    )
    .required("Enter Number of Certificate"),
  remarks: yup.string(),

  // price: yup.string(),
  // amount: yup.string(),
});

export const updateTransferOfShareSchema = (form) =>
  yup.object().shape({
    request_date: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(form.request_date.replaceAll("/", "-")),
    company_code: yup
      .object()
      .required("Select Company")
      .default(form.company_code),
    // Transfer Details
    transferee_folio_no: yup
      .object()
      .required("Select Transfree")
      .default(form.to_folio_number),
    transferor_folio_no: yup
      .object()
      .required("Select Transferor")
      .default(form.folio_number),
    // Transfer Details
    no_of_certificates: yup
      .string()
      .required("Enter Number of Certificate")
      .default(
        IsJsonString(form.input_certificates)
          ? JSON.parse(form.input_certificates).length.toString()
          : 0
      ),
    remarks: yup.string().default(form?.remarks),
  });
