import { GET_SHAREHOLDER_INFO } from "../actionTypes";
const initial_state = { cnic: "", mobile: "" };
export default (state = initial_state, action) => {
  switch (action.type) {
    case GET_SHAREHOLDER_INFO:
      return {
        ...state,
        cnic: action.payload.cnic,
        mobile: action.payload.mobile,
      };
    default:
      return state;
  }
};
