import * as yup from "yup";

export const addAnnouncmentSchema = yup.object().shape({
  company_code: yup.object().required("Enter COMPANY_CODE "),
  symbol: yup.object().required("Enter SYMBOL "),
  announcement_date: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  dividend_number: yup.string(),
  dividend_percent: yup.string(),
  bonus_number: yup.string(),
  bonus_percent: yup.string(),
  right_number: yup.string(),
  right_percent: yup.string(),
  period: yup.object().required("Enter PERIOD "),
  period_ended: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  book_closure_from: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  book_closure_to: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  right_subs_from: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  right_subs_to: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  provisional_from: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  provisional_to: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  right_credit_from: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  right_credit_to: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  right_rate: yup.string().required("Enter Right Rate"),
});

export const editAnnouncmentSchema = (announcement) =>
  yup.object().shape({
    company_code: yup
      .object()
      .required("Enter COMPANY_CODE ")
      .default(announcement.company_code),
    symbol: yup.object().required("Enter SYMBOL ").default(announcement.symbol),
    announcement_date: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(announcement.announcement_date),
    dividend_number: yup.string().default(announcement.dividend_number),
    dividend_percent: yup.string().default(announcement.dividend_percent),
    bonus_number: yup.string().default(announcement.bonus_number),
    bonus_percent: yup.string().default(announcement.bonus_percent),
    right_number: yup.string().default(announcement.right_number),
    right_percent: yup
      .string()
      .default(announcement?.right_rercent || announcement?.right_percent),
    period: yup.object().required("Enter PERIOD ").default(announcement.period),
    period_ended: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(announcement.period_ended),
    book_closure_from: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(announcement.book_closure_from),
    book_closure_to: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(announcement.book_closure_to),
    right_subs_from: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(announcement.right_subs_from),
    right_subs_to: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(announcement.right_subs_to),
    provisional_from: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(announcement.provisional_from),
    provisional_to: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(announcement.provisional_to),
    right_credit_from: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(
        announcement?.right_credit_trom || announcement?.right_credit_from
      ),
    right_credit_to: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(announcement.right_credit_to),
    right_rate: yup
      .string()
      .required("Enter Right Rate")
      .default(announcement?.right_rate),
  });
