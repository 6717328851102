import * as yup from "yup";
import { IsJsonString } from "../../utilities/utilityFunctions";

export const addSplitShareSchema = yup.object().shape({
  request_date: yup.string().test("test-date", "Enter Date", (value) => {
    return value;
  }),
  execution_date: yup.string(),
  company_code: yup.object().nullable().required("Select Company"),
  name: yup.string(),
  folio_no: yup.object().nullable().required("Select Folio Number"),
  certificate_no: yup.object().nullable().required("Select Certificate"),
  split_parts: yup
    .string()
    .test("check-val", "Split into at least two", (val) => parseInt(val) >= 2)
    .required("Enter Split Parts"),
  remarks: yup.string(),
});

export const updateSplitShareSchema = (form) =>
  yup.object().shape({
    request_date: yup
      .string()
      .test("test-date", "Enter Date", (value) => {
        return value;
      })
      .default(form.request_date.replaceAll("/", "-")),
    company_code: yup
      .object()
      .required("Select Company")
      .default(form.company_code),
    name: yup.string(),
    folio_no: yup
      .object()
      .required("Select Folio Number")
      .default(form.folio_number),
    certificate_no: yup
      .object()
      .required("Select Certificate")
      .default({
        value: JSON.parse(form.input_certificates)[0].certificate_no,
        label: JSON.parse(form.input_certificates)[0].certificate_no,
      }),
    split_parts: yup
      .string()
      .test("check-val", "Split into at least two", (val) => parseInt(val) >= 2)
      .required("Enter Split Parts")
      .default(
        IsJsonString(form.output_certificates)
          ? JSON.parse(form.output_certificates).length.toString()
          : 0
      ),
    remarks: yup.string().default(form?.remarks),
  });
