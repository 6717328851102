import React, { useState, useEffect } from "react";
import logo from "../assets/images/shareholder-app.svg";
import macslogo from "../assets/images/macs-core-logo.png";
import man from "../assets/images/dashboard/user.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation, withRouter } from "react-router";
import {
  firebase_app,
  googleProvider,
  facebookProvider,
  twitterProvider,
  githubProvider,
  Jwt_token,
} from "../data/config";
import { handleResponse } from "../services/fack.backend";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Login,
  LOGIN,
  YourName,
  Password,
  RememberMe,
  LoginWithAuth0,
  LoginWithJWT,
} from "../constant";
import {
  activateAccount,
  getUserConfirmation,
  loginService,
  sendVerificationCode,
  verifyVerificationCode,
} from "../store/services/auth.service";
import { NavLink } from "react-router-dom";

const config = require("../data/app_config.json");
const PasswordConfirmation = ({ history, location }) => {
  const { loginWithRedirect } = useAuth0();
  const [cnic, setCNIC] = useState(location.state.cnic);
  const [phoneNumber, setPhoneNumber] = useState(location.state.phone);
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [verified, setVerified] = useState(false);
  const [userExist, setUserExist] = useState(false);

  const [Loading, setLoading] = useState(false);

  // const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [value, setValue] = useState(
    sessionStorage.getItem("profileURL" || man)
  );

  // errors
  const [cnicError, setcnicError] = useState(false);
  const [phonenumberError, setphonenumberError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirm_passworderror, setConfirm_passworderror] = useState(false);
  const [not_same_Error, setNot_same_Error] = useState(false);
  useEffect(() => {
    // if (value !== null) localStorage.setItem("profileURL", value);
    if (value !== null) sessionStorage.setItem("profileURL", value);
    // else localStorage.setItem("profileURL", man);
    else sessionStorage.setItem("profileURL", man);
  }, [value]);

  const getConfirmation = async (e) => {
    if (cnic == "") {
      setcnicError(true);
    } else {
      setcnicError(false);
    }
    if (password == "") {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    if (cnic == "") {
      setcnicError(true);
    } else {
      setcnicError(false);
    }
    if (confirm_password == "") {
      setConfirm_passworderror(true);
    } else {
      setConfirm_passworderror(false);
    }
    if (confirm_password !== password) {
      setNot_same_Error(true);
    } else {
      setNot_same_Error(false);
    }

    // if (phoneNumber == "") {
    //   setphonenumberError(true);
    // } else {
    //   setphonenumberError(false);
    // }
    if (
      cnic !== "" &&
      phoneNumber !== "" &&
      password !== "" &&
      confirm_password !== "" &&
      confirm_password === password
    ) {
      try {
        setLoading(true);
        const response = await activateAccount(phoneNumber, cnic, password);
        if (response.status === 200) {
          toast.success(response.data.message);
          history.push({
            pathname: `${process.env.PUBLIC_URL}/login`,
            state: {
              cnic: cnic,
            },
          });
        } else {
          toast.error("Oppss.. The cnic number is invalid .");
        }
        setLoading(false);
      } catch (error) {
        setTimeout(() => {
          toast.error("Oppss.. The cnic number is invalid.");
        }, 200);
        setLoading(false);
      }
    }
  };

  const loginWithJwt = (email, password) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { email, password },
    };

    return fetch("/users/authenticate", requestOptions)
      .then(handleResponse)
      .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        setValue(man);
        // localStorage.setItem("token", user);
        sessionStorage.setItem("token", user);
        window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`;
        return user;
      });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={macslogo} width="130" alt="" />
                      <div className="text-center"></div>
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <img src={logo} alt="" className="py-3" width="250" />
                          {/* <h4>Share Registrar</h4> */}
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label pt-0">
                              Enter CNIC/NTN
                            </label>
                            <input
                              className="form-control"
                              type="cnic"
                              name="cnic"
                              value={cnic}
                              placeholder="Enter CNIC/NTN"
                              readOnly
                            />
                            {cnicError && (
                              <p className="error-color">
                                * CNIC/NTN is required
                              </p>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">
                              Phone Number
                            </label>
                            <input
                              className="form-control"
                              type="phonenumber"
                              name="phonenumber"
                              placeholder="Phone number"
                              value={phoneNumber}
                              readOnly
                            />
                            {phonenumberError && (
                              <p className="error-color">
                                *Phone number is required
                              </p>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">Password</label>
                            <input
                              className="form-control"
                              type="password"
                              name="verificationcode"
                              placeholder="Enter Code"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {passwordError && (
                              <p className="error-color">
                                *Password is required
                              </p>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">
                              Confirm Password
                            </label>
                            <input
                              className="form-control"
                              type="password"
                              name="confirm_password"
                              placeholder="Enter Password Code"
                              value={confirm_password}
                              onChange={(e) =>
                                setConfirm_password(e.target.value)
                              }
                            />
                            {confirm_passworderror && (
                              <p className="error-color">
                                *Confirm Password is required
                              </p>
                            )}
                            {not_same_Error && (
                              <p className="error-color">
                                *Password is not equal to Confirm Password
                              </p>
                            )}
                          </div>

                          <div className="form-group form-row mt-3 mb-0">
                            {/* <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={() => loginAuth()}
                            >
                              {Login}
                            </button> */}
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={(e) => getConfirmation(e)}
                              disabled={Boolean(Loading)}
                            >
                              {Loading ? (
                                <>
                                  <span className="fa fa-spinner fa-spin"></span>
                                  <span> Loading...</span>
                                </>
                              ) : (
                                <span> Next</span>
                              )}
                            </button>
                          </div>

                          <hr style={{ width: "60%" }} />
                          {/* <p
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push(
                                `${process.env.PUBLIC_URL}/forget-password`
                              );
                            }}
                          >
                            Forget Password
                          </p> */}
                          {/* <div
                            className="text-center opacity-50"
                            style={{ color: "grey" }}
                          >
                            <h6>Version 0.3</h6>
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(PasswordConfirmation);
