import React, { useEffect, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import {
  DollarSign,
  Tag,
  ShoppingBag,
  MessageCircle,
  MinusCircle,
  ThumbsUp,
  MessageSquare,
  Briefcase,
  MoreHorizontal,
  Send,
  Activity,
  Anchor,
  Compass,
  Cpu,
  Slack,
  Umbrella,
  Box,
  Book,
} from "react-feather";
import {
  earningData,
  earningOptions,
  staticChartData,
  staticChartOptions,
  yearChartData,
  yearChartOptions,
  admissionChartData,
  admissionChartOptions,
  htmlChartData,
  htmlChartOptions,
  phpChartData,
  phpChartOptions,
  calcultionOptions,
  calcultionData,
} from "../../../data/default";
import CountUp from "react-countup";
import {
  Command,
  Navigation,
  HelpCircle,
  Mic,
  Zap,
  Home,
  CreditCard,
  Users,
  FilePlus,
  Database,
  FileText,
} from "react-feather";
// import { calcultionOptions, calcultionData } from "../../../data/default";
import ChartistGraph from "react-chartist";
import EventCharts from "./eventCharts";
import configDB from "../../../data/customizer/config";
import { useSelector } from "react-redux";
import { getvalidDateDMMMY } from "../../../utilities/utilityFunctions";
import {
  New,
  NewSale,
  NewMessage,
  NewVisits,
  TotalProfit,
  AllCustomIncome,
  All,
  TotalInvestment,
  TotalReview,
  CustomerReview,
  Change,
  Online,
  MarshiKisteen,
  Dashboard,
  Ui,
  Xi,
  Message,
  Portfolio,
  NewUser,
  Month,
  Today,
  NickStone,
  Follow,
  WiltorNoice,
  NewReport,
  TotalFeedback,
  MilanoEsco,
  AnnaStrong,
  RecentNotification,
  Order,
  Download,
  Trash,
  ByKan,
  ByKaint,
  ByTailer,
  ByWaiter,
  ByComman,
  Calculation,
  TotalIncome,
  TotalLoss,
  Conversations,
  View,
  Media,
  Search,
  SellingUpdate,
  Shipping,
  Purchase,
  TotalSell,
  Feedback,
  ByCall,
  Activitys,
} from "../../../constant";
import {
  WATCH_ANNOUNCEMENTS,
  WATCH_ANNOUNCEMENTS_DROPDOWN,
} from "../../../redux/actionTypes";

var Knob = require("knob"); // browserify require
var primary =
  localStorage.getItem("primary_color") || configDB.data.color.primary_color;

const Default = () => {
  const companies = useSelector((data) => data.Companies);
  const shareholders = useSelector((data) => data.Shareholders);
  const investors = useSelector((data) => data.Investors);
  const transactions = useSelector((data) => data.TransactionRequests);
  const announcements = useSelector((data) => data.Announcements);
  const dividend = useSelector((data) => data.Dividend);
  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Shares E-Registry" />
      <div className="container-fluid">
        {/* Test */}
        <div className="row">
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <div className="media feather-main">
                  <div className="feather-icon-block">
                    <Home />
                  </div>
                  <div className="media-body align-self-center">
                    <h6>Companies</h6>
                    {!companies.companies_data_loading &&
                      companies.companies_data.length && (
                        <CountUp end={companies.companies_data.length} />
                      )}
                    {companies.companies_data_loading &&
                      !companies.companies_data.length && <p>Loading...</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <div className="media feather-main">
                  <div className="feather-icon-block">
                    <Users />
                  </div>
                  <div className="media-body align-self-center">
                    <h6>Investors</h6>
                    {!investors.investors_data_loading &&
                      investors.investors_data.length && (
                        <CountUp end={investors.investors_data.length} />
                      )}
                    {investors.investors_data_loading &&
                      !investors.investors_data.length && <p>Loading...</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <div className="media feather-main">
                  <div className="feather-icon-block">
                    <Users />
                  </div>
                  <div className="media-body align-self-center">
                    <h6>Share Holders</h6>
                    {!shareholders.shareholders_data_loading &&
                      shareholders.shareholders_data.length && (
                        <CountUp end={shareholders.shareholders_data.length} />
                      )}
                    {shareholders.shareholders_data_loading &&
                      !shareholders.shareholders_data.length && (
                        <p>Loading...</p>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <div className="media feather-main">
                  <div className="feather-icon-block">
                    <CreditCard />
                  </div>
                  <div className="media-body align-self-center">
                    <h6>Transactions</h6>
                    {!transactions.transaction_request_data_loading &&
                      transactions.transaction_request_data.length && (
                        <CountUp
                          end={transactions.transaction_request_data.length}
                        />
                      )}
                    {transactions.transaction_request_data_loading &&
                      !transactions.transaction_request_data.length && (
                        <p>Loading...</p>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-3">
                  <div className="card">
                      <div className="card-body">
                          <div className="media feather-main">
                              <div className="feather-icon-block">
                                  <Command />
                              </div>
                              <div className="media-body align-self-center">
                                  <h6>Compliances</h6>
                                  <p>{"18"}</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>  */}
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <div className="media feather-main">
                  <div className="feather-icon-block">
                    <FilePlus />
                  </div>
                  <div className="media-body align-self-center">
                    <h6>Dividend Disbursement</h6>
                    {!dividend.dividend_data_loading &&
                      dividend.dividend_data.length && (
                        <CountUp end={dividend.dividend_data.length} />
                      )}
                    {dividend.dividend_data_loading &&
                      !dividend.dividend_data.length && <p>Loading...</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 xl-50">
            <div className="card height-equal">
              <div className="card-header">
                <h5>Cash Dividend</h5>
              </div>
              <div className="card-body">
                <div className="notifiaction-media">
                  {announcements.announcement_data_loading === true && (
                    <div
                      className="row d-flex justify-content-center"
                      style={{ zIndex: "10 !important" }}
                    >
                      <div className="col-md-6">
                        <center>
                          <h6 className="mb-0 text-nowrap">
                            <b>{"Please Wait"}</b>
                          </h6>
                          <div className="d-flex justify-content-center">
                            <div className="loader-box mx-auto">
                              <div className="loader">
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                              </div>
                            </div>
                          </div>
                        </center>
                      </div>
                    </div>
                  )}
                  {announcements.announcement_data_loading === false &&
                    announcements.announcement_data.length !== 0 &&
                    companies.companies_data.length !== 0 &&
                    announcements.announcement_data.slice(0, 5).map((ann) => {
                      const day = getvalidDateDMMMY(ann.created_at).split(
                        "-"
                      )[0];
                      const month = getvalidDateDMMMY(ann.created_at).split(
                        "-"
                      )[1];
                      const company = companies.companies_data.find(
                        (comp) => comp.code === ann.company_code
                      );
                      return (
                        <div className="media mb-08">
                          <div className="media-body">
                            <h6 className="d-flex justify-content-between align-items-center pl-0">
                              <span className="d-flex align-items-center">
                                <span className="f-20 text-center">
                                  <span> {day} </span>
                                  <small>
                                    {" "}
                                    <span className="text-muted font-size-70">
                                      {" "}
                                      {month}{" "}
                                    </span>{" "}
                                  </small>
                                </span>
                                <span className="d-inline-block ml-3">
                                  <span className="d-inline-block">
                                    {" "}
                                    {company.company_name}{" "}
                                  </span>
                                  <small>
                                    {" "}
                                    <span className="text-muted font-size-70">
                                      {" "}
                                      {company.symbol}{" "}
                                    </span>{" "}
                                  </small>
                                </span>
                              </span>
                              <span class="pull-right font-primary f-18">
                                {" "}
                                {ann.dividend_percent}%{" "}
                              </span>
                            </h6>
                          </div>
                        </div>
                      );
                    })}
                  {announcements.announcement_data_loading === false &&
                    announcements.announcement_data.length === 0 && (
                      <p className="text-center">
                        <b>Announcement Data not Available</b>
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 xl-50">
            <div className="card height-equal">
              <div className="card-header">
                <h5>Bonus Shares</h5>
              </div>
              <div className="card-body">
                <div className="notifiaction-media">
                  {announcements.announcement_data_loading === true && (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-6">
                        <center>
                          <h6 className="mb-0 text-nowrap">
                            <b>{"Please Wait"}</b>
                          </h6>
                          <div className="d-flex justify-content-center">
                            <div className="loader-box mx-auto">
                              <div className="loader">
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                              </div>
                            </div>
                          </div>
                        </center>
                      </div>
                    </div>
                  )}{" "}
                  {announcements.announcement_data_loading === false &&
                    announcements.announcement_data.length !== 0 &&
                    companies.companies_data.length !== 0 &&
                    announcements.announcement_data.slice(0, 5).map((ann) => {
                      const day = getvalidDateDMMMY(ann.created_at).split(
                        "-"
                      )[0];
                      const month = getvalidDateDMMMY(ann.created_at).split(
                        "-"
                      )[1];
                      const company = companies.companies_data.find(
                        (comp) => comp.code === ann.company_code
                      );
                      return (
                        <div className="media mb-08">
                          <div className="media-body">
                            <h6 className="d-flex justify-content-between align-items-center pl-0">
                              <span className="d-flex align-items-center">
                                <span className="f-20 text-center">
                                  <span> {day} </span>
                                  <small>
                                    {" "}
                                    <span className="text-muted font-size-70">
                                      {" "}
                                      {month}{" "}
                                    </span>{" "}
                                  </small>
                                </span>
                                <span className="d-inline-block ml-3">
                                  <span className="d-inline-block">
                                    {" "}
                                    {company.company_name}{" "}
                                  </span>
                                  <small>
                                    {" "}
                                    <span className="text-muted font-size-70">
                                      {" "}
                                      {company.symbol}{" "}
                                    </span>{" "}
                                  </small>
                                </span>
                              </span>
                              <span class="pull-right font-primary f-18">
                                {" "}
                                {ann.bonus_percent}%{" "}
                              </span>
                            </h6>
                          </div>
                        </div>
                      );
                    })}
                  {announcements.announcement_data_loading === false &&
                    announcements.announcement_data.length === 0 && (
                      <p className="text-center">
                        <b>Announcement Data not Available</b>
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 xl-50">
            <div className="card height-equal">
              <div className="card-header">
                <h5>Right Shares</h5>
              </div>
              <div className="card-body">
                <div className="notifiaction-media">
                  {announcements.announcement_data_loading === true && (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-6">
                        <center>
                          <h6 className="mb-0 text-nowrap">
                            <b>{"Please Wait"}</b>
                          </h6>
                          <div className="d-flex justify-content-center">
                            <div className="loader-box mx-auto">
                              <div className="loader">
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                                <div className="line bg-primary"></div>
                              </div>
                            </div>
                          </div>
                        </center>
                      </div>
                    </div>
                  )}
                  {announcements.announcement_data_loading === false &&
                    announcements.announcement_data.length !== 0 &&
                    companies.companies_data.length !== 0 &&
                    announcements.announcement_data.slice(0, 5).map((ann) => {
                      const day = getvalidDateDMMMY(ann.created_at).split(
                        "-"
                      )[0];
                      const month = getvalidDateDMMMY(ann.created_at).split(
                        "-"
                      )[1];
                      const company = companies.companies_data.find(
                        (comp) => comp.code === ann.company_code
                      );
                      return (
                        <div className="media mb-08">
                          <div className="media-body">
                            <h6 className="d-flex justify-content-between align-items-center pl-0">
                              <span className="d-flex align-items-center">
                                <span className="f-20 text-center">
                                  <span> {day} </span>
                                  <small>
                                    {" "}
                                    <span className="text-muted font-size-70">
                                      {" "}
                                      {month}{" "}
                                    </span>{" "}
                                  </small>
                                </span>
                                <span className="d-inline-block ml-3">
                                  <span className="d-inline-block">
                                    {" "}
                                    {company.company_name}{" "}
                                  </span>
                                  <small>
                                    {" "}
                                    <span className="text-muted font-size-70">
                                      {" "}
                                      {company.symbol}{" "}
                                    </span>{" "}
                                  </small>
                                </span>
                              </span>
                              <span class="pull-right font-primary f-18">
                                {" "}
                                {ann.right_percent}%{" "}
                              </span>
                            </h6>
                          </div>
                        </div>
                      );
                    })}
                  {announcements.announcement_data_loading === false &&
                    announcements.announcement_data.length === 0 && (
                      <p className="text-center">
                        <b>Announcement Data not Available</b>
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Default;
