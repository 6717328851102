import * as yup from "yup";

export const addRightSubscribtionValidation = yup.object().shape({
  request_date: yup.string().required("Enter Date"),
  execution_date: yup.string().required("Emter Date"),
  company_code: yup.object().nullable().required("Select Company"),
  announcement_no: yup.object().nullable().required("Select Announcement"),
  entitlements: yup.object().nullable().required("Select Entitlement"),

  sub_shares: yup.string().required("Enter Sub Shares"),
  instrument_type: yup.object().nullable().required("Select Instrument Type"),
  instrument_no: yup.string().required("Enter Instrument No"),
  remarks: yup.string(),
});

export const updateRightSubscribtionValidation = (form) =>
  yup.object().shape({
    request_date: yup
      .string()
      .required("Enter Date")
      .default(form.request_date),
    execution_date: yup
      .string()
      .required("Emter Date")
      .default(form.execution_date),
    company_code: yup
      .object()
      .required("Select Company")
      .default(form.company_code),
    announcement_no: yup
      .object()
      .required("Select Announcement")
      .default(form.announcement_id),
    entitlements: yup
      .object()
      .required("Select Entitlement")
      .default(form.entitlement_id),

    sub_shares: yup
      .string()
      .required("Enter Sub Shares")
      .default(form.quantity),
    instrument_type: yup.object().required("Select Instrument Type"),
    instrument_no: yup
      .string()
      .required("Enter Instrument No")
      .default(form.instrument_no),
    remarks: yup.string().default(form?.remarks),
  });
