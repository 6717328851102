import React, {useState} from 'react'
import { AiOutlineLeft } from "react-icons/ai";
import styled from 'styled-components';
import { useHistory } from 'react-router';
import DCCLogo from "../../../assets/DCC-Logo.svg"
import { FaRegFile } from "react-icons/fa";
import "./evotingStyle.scss";
import { Logout } from './logout';
export const SpecialResolution = () => {
    // const getEvotingData = JSON.parse(localStorage.getItem('evotingData'))
    const getEvotingData = JSON.parse(sessionStorage.getItem('evotingData'))
    // const getTempEmail = JSON.parse(localStorage.getItem("email"));
    const getTempEmail = JSON.parse(sessionStorage.getItem("email"));
    const [termCondition, setTermCondition] = useState(false)
    const history = useHistory()
    const handleCastVoting =()=>{
        history.push(`${process.env.PUBLIC_URL}/specialvoting`)
    }
  return (
    <div className="evotingfontfamily"  style={{ padding: '20px' }}>
    <MainWrapper className='row mt-4'>
      <div className="col-sm-12 col-md-12 col-lg-12 responsivepage" >
        <div className='card pb-5' style={{ border: '1px solid #70ace7' }}>
          <Wrapper className="card-header " style={{ borderBottom: 'none' }}>
          <div className='d-flex' style={{justifyContent:'space-between'}}>
            <AiOutlineLeft className='mt-3' size={30} style={{ color: '#677ecc', cursor: 'pointer' }} 
            onClick={() => {
             
             history.push(`${process.env.PUBLIC_URL}/evoting/home`)

            //   setReviewVoting(false)
            }}/>
              <Logout />
            </div>
            
            <div className="glyphicon glyphicon-chevron-left"></div>
            <div className="text-center mt-1">
              <img src={DCCLogo} alt="" width="220" height={'130px'} />
            </div>
            <div className='mt-3' style={{ justifyContent: 'center', display: 'flex' }}>
                                eVoting On
                            </div>
            <div className='mt-3' style={{ color: '#447bc9', fontSize: '23px', display: 'flex', justifyContent: 'center' }}>
              {getEvotingData?.title || ''}
            </div>
            <p style={{ fontSize: '16px', display: 'flex', justifyContent: 'center', color: 'rgb(152, 161, 181)' }} class="card-text">{getEvotingData?.startdate} to {getEvotingData?.endDate}</p>

            
          </Wrapper>
          <div className="card-body " style={{ justifyContent: 'center', display: 'flex' }}>
            <div>
            <div style={{ fontSize: '14px', display: 'flex', justifyContent: 'center', color: 'rgb(152, 161, 181)' }}> Agenda Title </div>

            <div className='mt-1' style={{border: '1px solid gray', width: '100%', display: 'flex', justifyContent: 'center', paddingTop :'7px', paddingBottom :'7px', borderRadius: '5px'}}>
                <div className="evotingfontfamily"  style={{padding: '0px 30px', fontSize: '16px'}}>
            Title of the agenda item will be displayed here.
            </div>
                  </div>
                  <div className='mt-2' style={{ fontSize: '14px', display: 'flex', justifyContent: 'center', color: 'rgb(152, 161, 181)' }}> Agenda Item </div>

<div className='mt-1' style={{border: '1px solid gray', width: '100%', display: 'flex', justifyContent: 'center', paddingTop :'7px', paddingBottom :'7px', borderRadius: '5px'}}>
    <div className="evotingfontfamily"  style={{padding: '0px 30px', fontSize: '16px'}}>
    A detailed description of the agenda item will be displayed here.
</div>
      </div>
      <div className='mt-2' style={{ fontSize: '14px', display: 'flex', justifyContent: 'center', color: 'rgb(152, 161, 181)' }}> Attachments </div>

<div className='mt-1' style={{border: '1px solid gray', width: '100%', display: 'flex', justifyContent: 'center', paddingTop :'7px', paddingBottom :'7px', borderRadius: '5px'}}>
   <div>
    <div style={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
<FaRegFile size={20} style={{color: '#da6767'}}/>
<div style={{fontSize: '16px'}}>Business Plan</div>

    </div>
    <div style={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
<FaRegFile size={20} style={{color: '#da6767'}}/>
<div style={{fontSize: '16px'}}> Feasibility</div>

    </div>
    <div style={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
<FaRegFile size={20} style={{color: '#da6767'}}/>
<div style={{fontSize: '16px'}}>Cost Revenue Analysis</div>
</div>
    </div>
      </div>
      <div className='mt-5' >
                  <div style={{ display: 'flex', gap: '12px', justifyContent: 'center', paddingLeft :'15px' }}>
                    <input type="checkbox" id="checkbox" name="checkbox" style={{ transform: 'scale(2)', marginTop: '-16px' }} onChange={(e) => {
                     setTermCondition(e?.target?.checked)
                   
                    }} />
                    <div style={{ fontSize: '18px' }}> I agree to the <span style={{color: '#447bc9'}}> Terms and Conditions  </span>of the eVoting.</div>
                  </div>

                  {/* <div style={{ display: 'flex', justifyContent: 'center', color: '#677ecc', fontSize: '20px' }}>Terms and Conditions</div> */}
                
                </div>



              <button
                className="btn mt-3"
                type="button"
                style={{ width: '100%', color: '#ffff', background: '#5B9BD5' }}
                onClick={() => {
                  handleCastVoting()
                }}
              >


                <span> Cast Vote</span>

              </button>
            </div>









          </div>

        </div>
      </div>
    </MainWrapper>



  </div> 
  )
}

const MainWrapper = styled.div`
@media(min-width: 300px){
    .responsivepage{

    }
  }
  @media(min-width: 500px){
    .responsivepage{
display: flex;
justify-content: center
}
  }
`;

const Wrapper = styled.div`
@media(min-width: 300px){
    .responsive{
    width: 80%;
    }
  }
  @media(min-width: 500px){
    .responsive{
    width: 53%;
    }
  }
  @media(min-width: 660px){
    .responsive{
    width: 45%;
    }
  }
  @media(min-width: 840px){
    .responsive{
    width : 35%;
    }
  }
  @media(min-width: 1132px){
    .responsive{
    width : 26%;
    }
  }
  
  @media(min-width: 1132px){
    .responsive{
    width : 22%;
    }
  }


@media(min-width: 1300px){
    .responsive{
    width : 20%;
    }
  }
  @media(min-width: 1470px){
    .responsive{
    width : 15%;
    }
  }
`;